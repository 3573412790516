<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Business Intelligence" name="Are You Making the Most of Your Business Data?"
                summary="Power BI - Business intelligence like never before.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
            <insight-pamphlet>
                <insight-sub-section class="subsection" name="What is Power BI?"
                    content="Power BI is a suite of business analytics tools that deliver insights throughout your organization. Connect to hundreds of data sources, simplify data prep, and drive ad hoc analysis.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Produce beautiful reports, then publish them for your organization to consume on the web and across mobile devices. Everyone can create personalized dashboards with a unique, 360-degree view of their business. Power BI is scalable across the enterprise, with governance and security built-in. Performing numerous permutations and combinations on spreadsheets is a time-consuming task. With visualizations, analysis becomes automatic.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="When data sources are connected in the right way, all you need to do is ask questions. Our visualization services, combined with Power BI’s natural language processing, make it easy to seek insightful information related to every aspect of your business. Data Concepts brings expertise and a proven track record in Power BI implementation consulting and development – including reporting from data marts and data warehouses, and integrations with disparate data sources. We can take you from having data to producing game-changing insights by using data, from any source, to provide a unified view of the business!">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="Power BI Services"
                    content="We partner with enterprises across the business intelligence lifecycle – - from BI envisioning, needs assessment and proof of concepts, to data model design (logical and physical), platform implementation, data integration development, reporting & visualization design and development, and ongoing data ops.">
                </insight-sub-section>
                <insight-sub-section class="subsection" content="- Data Platform Development"></insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Build a data platform to serve data for Power BI or configure Power BI to leverage existing sources such as data marts, operational data stores, and data warehouse.">
                </insight-sub-section>
                <insight-sub-section class="subsection" content="- Analysis & Planning"></insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Analyze your business intelligence requirements, data sources, and plan your Power BI deployment architecture, data integration & transformation and reporting & visualization deliverables.">
                </insight-sub-section>
                <insight-sub-section class="subsection" content="- Data Integration & Transformation"></insight-sub-section>
                <insight-sub-section class="subsection"
                    content="A key step in Power BI solutioning is the data integration and transformation involving data extraction, cleansing and data preparation using Power Query M language.">
                </insight-sub-section>
                <insight-sub-section class="subsection"content="- Data Modeling"></insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Leverage data modeling constructs such a dimensions, facts and snowflakes with advanced DAX to create robust data models that accurately reflect the BI reporting requirements.">
                </insight-sub-section>
                <insight-sub-section class="subsection" content="- Visualization & Reporting"></insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Enable data-driven decision making with rich and interactive scorecards, dashboards with drill downs to identify emerging trends, access to relevant information, and monitoring of goals and objectives.">
                </insight-sub-section>
                <insight-sub-section class="subsection" content="- Data Ops"></insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Provide development and support for ongoing data source integration, data quality and performance improvements.">
                </insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
