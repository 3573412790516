import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms'; 
import { HttpHeaders, HttpClient, HttpEventType } from '@angular/common/http';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-hire-us',
  templateUrl: './hire-us.component.html',
  styleUrls: ['./hire-us.component.sass']
})
export class HireUsComponent implements OnInit {
  result: string;
  loading: boolean = false;
  hireUsForm = this.fb.group({
    name: ["", Validators.required],
    address: [""],
    email: ["", [Validators.required, Validators.email]],
    phone: ["", Validators.pattern("^(?=.*[0-9])[- +()0-9]+$")]
  });

  constructor(private fb: FormBuilder, private http: HttpClient) { }

  ngOnInit(): void {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

  onSubmit(event: any): void {
    event.preventDefault();
    this.result = "";
      if (this.hireUsForm.valid) {
        return this.submitHireUs();
      }
  }

  submitHireUs(): void {
    this.loading = true;

    let headers = new HttpHeaders();
    headers.append("Content-Type", 'application/json');

    const formData = new FormData();
    formData.append("Name", this.hireUsForm.get('name').value);
    formData.append("Address", this.hireUsForm.get('address').value);
    formData.append("Email", this.hireUsForm.get('email').value);
    formData.append("Phone", this.hireUsForm.get('phone').value);

    this.http.post('/api/Email/HireUs', formData, { observe: 'events', headers: headers })
      .subscribe(event => {
        if (event.type === HttpEventType.Response) {
          this.loading = false;
          if (event.ok) {
            this.resetForm();
            this.result = "Your submission was successful. Thank you for your interest, we will reach out to you as soon as possible.";
          }
          else {
            this.result = "Sorry there was an error processing your form, please try again.";
          }
        }
      }
    );
  }

  resetForm(): void {
    this.hireUsForm.reset();
    this.result = "";
  }

  // validation helpers
  get name() { return this.hireUsForm.get('name'); }
  get email() { return this.hireUsForm.get('email'); }
  get phone() { return this.hireUsForm.get('phone'); }
  get address() { return this.hireUsForm.get('address'); }
}
