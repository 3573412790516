import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-criminal-history',
  templateUrl: './criminal-history.component.html',
  styleUrls: ['./criminal-history.component.sass']
})
export class CriminalHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }
}
