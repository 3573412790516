import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'promise',
  templateUrl: './promise.component.html',
  styleUrls: ['./promise.component.sass']
})
export class PromiseComponent implements OnInit {
  @Input() content: string;
  @Input() subtitle: string;
  
  constructor() { }

  ngOnInit() {
  }

}
