import { Component, OnInit, Input} from "@angular/core";

@Component({
  selector: "hero-arrow",
  templateUrl: "./hero-arrow.component.html",
  styleUrls: ["./hero-arrow.component.sass"]
})
export class HeroArrowComponent implements OnInit {
  @Input() fillColor: string;

  constructor() {}

  ngOnInit() {
    
  }

  scrollTo() {
    document.querySelector('.sections').children[0].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  }
}
