import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Mouse } from "../_models/mouse";
import { Particle } from "../_models/particle";
import Typewriter from 't-writer.js';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.sass"]
})
export class HomeComponent implements OnInit, AfterViewInit {
  config: any;
  fullpage_api: any;
  phase: number = 0;
  typewriterInterval: number = 0;
  mouse: Mouse = new Mouse();
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  colors = ["#F8F2F4", "#EAF2F4", "#FFF0FF", "#FFFEF2"];

  constructor() {

    // for more details on config options please visit fullPage.js docs
    this.config = {
      // fullpage options
      scrollBar: true,
      menu: "#menu",
      navigation: false,
      continuousHorizontal: false,
      slidesNavigation: false,
      scrollingSpeed: 200,
      controlArrows: true,
      licenseKey: "8E606C95-73914E64-9B22D3D2-EE4363C1",
      //scrollOverflow: false,
      //scrollOverflowReset: false,
      //fitToSection: false,
      // fullpage callbacks
      afterResize: () => {
        //console.log("After resize");
      },
      afterLoad: (origin, destination, direction) => {
        if (destination.index == 0) {
          document.querySelector(".__page-root").scrollIntoView(true);
        }
      },
      beforeDestroy: () => {
        // removeEventListener("mousemove", this.trackMouse);
        //fullpage_api.destroy();
        //document.getElementById("fp-nav").remove();
      }
    };
  }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);

    const fpNav = document.getElementById("fp-nav");
    if (fpNav != null || fpNav != undefined) {
      if (window.innerWidth < 900) {
        fpNav.style.right = "5px";
      }
      if (window.innerWidth < 540) {
        fpNav.style.display = "none";
      }
    }
    this.canvas = document.querySelector("canvas");
    this.ctx = this.canvas.getContext("2d");

    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    this.mouse.x = window.innerWidth / 2;
    this.mouse.y = window.innerHeight / 2;

    const trackResize = () => {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
    };

    // Event Listeners
    document.getElementById("canvas-wrapper").addEventListener("mousemove", this.trackMouse.bind(this));
    addEventListener("resize", trackResize.bind(this));

    this.init();
    this.typingEffect();
  }

  typingEffect() {
    const target = document.querySelector('.tw')
    const writer = new Typewriter(target, {
      loop: true,
      typeSpeed: 80,
      deleteSpeed: 80
    })

    writer
      .strings(
        400,
        "Hello and welcome",
        "Bonjour et bienvenue",
        "Hallo und willkommen",
        "Hola y Bienvenido",
        "您好，欢迎",
        "आपका स्वागत है",
        "مرحبا وأهلاً بك",
        "Привет и добро пожаловать",
        "Ciao e benvenuto",
        "Olá e bem-vindo"
      )
      .start()
  }

  ngAfterViewInit() {
    //override fullpage overflow styles
    document.querySelector('html').style.removeProperty('overflow');
    document.querySelector('body').style.removeProperty('overflow');
  }

  backTypeWriter(): void {
    let dynamicText = document.getElementById("typewriterText");
    if (dynamicText != null) {
      let innerText = dynamicText.textContent;
      if (innerText.length > 0) {
        innerText = innerText.substring(0, innerText.length - 1);
        dynamicText.textContent = innerText;
        window.setTimeout(this.backTypeWriter.bind(this), 40);
      } else {
        this.typewriterInterval = 0;
        this.typeWriter();
      }
    }
  }

  typeWriter(): void {
    let phrase = "";
    let dynamicText = document.getElementById("typewriterText");

    if (dynamicText != null) {
      if (this.phase === 0) {
        phrase = "integrating Cloud technology?";
        dynamicText.setAttribute("fill", "url(#grad1)");
      }
      else if (this.phase === 1) {
        dynamicText.setAttribute("fill", "url(#grad2)");
        phrase = "implementing Agile strategy?";
      }
      else {
        dynamicText.setAttribute("fill", "url(#grad3)");
        phrase = "developing Mobile solutions?";
      }

      if (this.typewriterInterval < phrase.length) {
        dynamicText.textContent += phrase.charAt(this.typewriterInterval);
        this.typewriterInterval++;
        window.setTimeout(this.typeWriter.bind(this), 60);
      }
      else if (this.typewriterInterval === phrase.length) {
        if (this.phase === 0) {
          this.phase = 1;
          window.setTimeout(this.backTypeWriter.bind(this), 2000);
        }
        else if (this.phase === 1) {
          this.phase = 2;
          window.setTimeout(this.backTypeWriter.bind(this), 2000);
        }
        else {
          this.phase = 0;
          window.setTimeout(this.backTypeWriter.bind(this), 2000);
        }
      }
    }
  }

  scrollTo() {
    this.fullpage_api.moveSectionDown();
  }

  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
  }

  trackMouse(event: any) {
    this.mouse.x = event.clientX;
    this.mouse.y = event.clientY;
  }

  randomColor(colors): string {
    return colors[Math.floor(Math.random() * colors.length)];
  }
  // Implementation
  particles;

  init() {
    this.particles = [];
    for (let i = 0; i < 500; i++) {
      this.particles.push(
        new Particle(
          this.canvas.width / 2,
          this.canvas.height / 2,
          this.randomColor(this.colors),
          this.canvas.width,
          this.ctx
        )
      );
    }
    this.animateParticles();

    this.typeWriter();
  }

  // Animation Loop
  animateParticles() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.particles.forEach(particle => {
      particle.update(this.mouse);
    });

    requestAnimationFrame(this.animateParticles.bind(this));
  }
}
