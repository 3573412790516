<light-layout>
  <div class="__page-root">
    <hero name="Virginia State Police" kicker="Vehicle Inspection Program.">
      <div class="cover-image hero-background tinted"></div>
      <back-to-button></back-to-button>

    </hero>
    <div class="sections">
      <app-summary color="#EF4C23"
                   content="The administration of the Commonwealth of Virginia’s statewide Motor Vehicle Safety Inspection Program [MVIP] is the responsibility of Virginia State Police.">
        <role [style.width.%]="100">
          <li>Java</li>
          <li>JEE</li>
          <li>Web Services</li>
          <li>JavaScript</li>
          <li>XML</li>
          <li>DB2</li>
          <li>IBM WebSphere</li>
          <li>Oracle</li>
        </role>
      </app-summary>

      <challenge color="#EF4C23"
                     content="As of December 31, 2007, there were 4,388 active inspection stations located throughout the Commonwealth of Virginia. There were over 14,000 licensed safety inspectors who performed approximately 8,000,000 inspections at appointed stations during 2007. Approximately 25% of all vehicles submitted for inspection were rejected for unsafe components. This program was a manual and laborious process which resulted in data discrepancy and slow processes."></challenge>

      <solution-header content="business process re-engineering and automation"></solution-header>

      <solution>
        <p>The new MVIP web application automated the entire Inspection Safety Program. Data Concepts built a new, state-of-the-art Web Based Application to automate and entirely re-engineer the previous labor intensive and error prone process.</p>
        <ul class="pseudo">
          <li>- Approximately 30-50 Views / Web pages.</li>
          <li>- View customization based on Roles and Group.</li>
          <li>- Web 2.0.</li>
          <li>- Section 508 Compliant.</li>
          <li>- Follows Virginia Information Technology Agency [VITA] Architecture Guidelines.</li>
          <li>- Designed to handle around one million plus transactions a month.</li>
          <li>- Designed to handle approximately 4000+ station users.</li>
        </ul>
      </solution>

      <outcome color="#EF4C23"
                   content="Data Concepts successfully managed, executed and delivered this system into production. The development and deployment of the new system was a huge endeavor and initiative by Virginia State Police to radically improve the efficiency and productivity of the Motor Vehicle Safety Inspections Program. It also involved major Business Process Re-engineering and an automation push. This was a deliverable based, fixed price project."></outcome>
    </div>
  </div>
</light-layout>
