import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.sass']
})
export class ServicesComponent implements OnInit {
  phase: number = 0;
  typewriterInterval: number = 0;

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);

    this.serviceTypeWriter();
  }

  serviceBackTypeWriter(): void {
    let dynamicText = document.getElementById("serviceTypewriterText");
    if (dynamicText != null) {
      let innerText = dynamicText.textContent;
      if (innerText.length > 0) {
        innerText = innerText.substring(0, innerText.length - 1);
        dynamicText.textContent = innerText;
        window.setTimeout(this.serviceBackTypeWriter.bind(this), 40);
      } else {
        this.typewriterInterval = 0;
        this.serviceTypeWriter();
      }
    }
  }

  serviceTypeWriter(): void {
    let phrase = "Our Inspiration!";
    let dynamicText = document.getElementById("serviceTypewriterText");

    if (dynamicText != null) {
      if (this.phase === 0) {
        dynamicText.setAttribute("fill", "url(#grad1)");
      }
      else if (this.phase === 1) {
        dynamicText.setAttribute("fill", "url(#grad2)");
      }
      else {
        dynamicText.setAttribute("fill", "url(#grad3)");
      }

      if (this.typewriterInterval < phrase.length) {
        dynamicText.textContent += phrase.charAt(this.typewriterInterval);
        this.typewriterInterval++;
        window.setTimeout(this.serviceTypeWriter.bind(this), 100);
      }
      else if (this.typewriterInterval === phrase.length) {
        if (this.phase === 0) {
          this.phase = 1;
          window.setTimeout(this.serviceBackTypeWriter.bind(this), 2000);
        }
        else if (this.phase === 1) {
          this.phase = 2;
          window.setTimeout(this.serviceBackTypeWriter.bind(this), 2000);
        }
        else {
          this.phase = 0;
          window.setTimeout(this.serviceBackTypeWriter.bind(this), 2000);
        }
      }
    }
  }
}
