import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'technology',
  templateUrl: './technology.component.html',
  styleUrls: ['./technology.component.sass']
})
export class TechnologyComponent implements OnInit {
  @Input() subtitle: string;
  
  constructor() { }

  ngOnInit() {
  }

}
