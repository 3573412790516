<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Business Intelligence" name="What can you do with SharePoint?"
                summary="Upgrade or Migration is a major challenge for organizations who have invested in SharePoint extensively. Each new edition of SharePoint provides new features which has potential to improve your business case.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
            <insight-pamphlet>
                <insight-sub-section class="subsection" name="How We Help With SharePoint"
                    content="Upgrade or migration is a major challenge for organizations who have invested in SharePoint extensively. Each new edition of SharePoint provides new features which have the potential to improve your business case. Having built several applications and customized SharePoint as per each business need, it becomes imperative to migrate these functionalities to the future platform, but there is effort, risk and cost involved. The complexity becomes even greater when you want to migrate from an existing solution platform to SharePoint. Data Concepts has helped several clients migrate their SharePoint environment from one version to the next and from an on-premise to a hosted model or Office 365. Our Upgrade and Migration consulting arm helps organizations build a systematic, time tested migration strategy and plan.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="Data Concepts can help with all aspects of your SharePoint implementation – version selection & licensing, infrastructure planning, document management, taxonomy, governance, workflows, system integration, dashboards, custom add-ins & web parts, training, and ongoing support. We can help you customize SharePoint’s look and feel to make it more inviting and boost user adoption.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="What You Can Do with SharePoint" content="- Improve Business Processes."></insight-sub-section>
                <insight-sub-section class="subsection" content="Use Workflow Automation and Online Forms to streamline business processes.">
                </insight-sub-section>
                <insight-sub-section class="subsection" content="- Find Documents Fast."></insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Search indexes every word in every document you store on your network drive or in SharePoint so you can find what you’re looking for easily. DMC's SharePoint OCR Solution indexes the words inside image-only PDF files so they can also be discovered via search.">
                </insight-sub-section>
                <insight-sub-section class="subsection" content="- Manage Critical Documents."></insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Document Management features like Version Control, Metadata, Approval Workflow, and Audit Trail allow teams to work together and avoid costly mistakes. This feature is ideal for ISO compliance and contract management.">
                </insight-sub-section>
                <insight-sub-section class="subsection" content="- Establish Best Practices."></insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="Use Wikis to easily create, maintain, and locate best practices and standard operating procedures.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="SharePoint Upgrade or Migration"
                    content="Having successfully delivered 100+ projects, Data Concepts brings deep experience and tested best practices to ensure a smooth and successful transition for your Enterprise.  We work in three phases to prepare our clients for a SharePoint Upgrade or Migration.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Pre-Upgrade Assessment"
                    content="This phase aims at understanding your existing SharePoint environment including the applications, documents, workflows, reports, data, etc. to be migrated. The pre-upgrade analysis will help us identify the risks that need to be handled for successful execution of the project. During the assessment phase, if any changes are needed in the implemented Information Architecture, the same is evaluated.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Pre Migration Assessment"
                    content="The primary focus is to identify the functionality available in the existing applications, classify them based on usage, and design the same functionality in SharePoint. During this phase, the existing application is thoroughly reviewed for possible enhancements that add value to the respective business solution and is considered in the new SharePoint design. The migration project can range from simple migration of content from a file system, or can be as complex as recreating the functionality of an existing ageing solution.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="Approach & Roadmap"
                    content="Based on findings during the assessment phase, we will create a detailed Upgrade/Migration plan. We will also provide a recommendation if certain applications need to be rebuilt or enhanced to best utilize the capabilities of the future platform.">
                </insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
