import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-data-leverage',
  templateUrl: './big-data-leverage.component.html',
  styleUrls: ['./big-data-leverage.component.sass']
})
export class BigDataLeverageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
