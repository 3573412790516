import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-predictive-modeling',
  templateUrl: './predictive-modeling.component.html',
  styleUrls: ['./predictive-modeling.component.sass']
})
export class PredictiveModelingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
