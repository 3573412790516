import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WorkComponent } from "./work.component";
import { SabraComponent } from './sabra/sabra.component';
import { CapitalOneComponent } from './capital-one/capital-one.component';
import { CapitalOneTwoComponent } from './capital-one-two/capital-one-two.component';
import { CircuitCourtsComponent } from './circuit-courts/circuit-courts.component';
import { CourtComponent } from './court/court.component';
import { CriminalHistoryComponent } from './criminal-history/criminal-history.component';
import { DataLakeComponent } from './data-lake/data-lake.component';
import { DevopsTransformationComponent } from './devops-transformation/devops-transformation.component';
import { EnergyComponent } from './energy/energy.component';
import { EnergyTwoComponent } from './energy-two/energy-two.component';
import { EstesQuoteComponent } from './estes-quote/estes-quote.component';
import { EstesImprovementComponent } from './estes-improvement/estes-improvement.component';
import { FinancePortalComponent } from './finance-portal/finance-portal.component';
import { FirearmsApplicationComponent } from './firearms-application/firearms-application.component';
import { FirstUnionComponent } from './first-union/first-union.component';
import { FreightVolumeComponent } from './freight-volume/freight-volume.component';
import { FreightMovementComponent } from './freight-movement/freight-movement.component';
import { JudicialEnforcementComponent } from './judicial-enforcement/judicial-enforcement.component';
import { JuryManagementComponent } from './jury-management/jury-management.component';
import { MagellenHealthComponent } from './magellen-health/magellen-health.component';
import { PredictiveModelingComponent } from './predictive-modeling/predictive-modeling.component';
import { ScalaArchitectureComponent } from './scala-architecture/scala-architecture.component';
import { ServerMigrationComponent } from './server-migration/server-migration.component';
import { SexRegistryComponent } from './sex-registry/sex-registry.component';
import { UnifiedDataComponent } from './unified-data/unified-data.component';
import { VehicleInspectionComponent } from './vehicle-inspection/vehicle-inspection.component';
import { WebPortalComponent } from './web-portal/web-portal.component';
import { VdssComponent } from './vdss/vdss.component';

const workRoutes: Routes = [
  { path: 'work', component: WorkComponent },
  { path: 'work/criminal-history', component: CriminalHistoryComponent },
  { path: 'work/court', component: CourtComponent },
  { path: 'work/circuit-courts', component: CircuitCourtsComponent },
  { path: 'work/vehicle-inspection', component: VehicleInspectionComponent },
  { path: 'work/magellan-health', component: MagellenHealthComponent },
  { path: 'work/web-portal', component: WebPortalComponent },
  { path: 'work/sex-registry', component: SexRegistryComponent },
  { path: 'work/first-union', component: FirstUnionComponent },
  { path: 'work/finance-portal', component: FinancePortalComponent },
  { path: 'work/server-migration', component: ServerMigrationComponent },
  { path: 'work/estes-improvement', component: EstesImprovementComponent },
  { path: 'work/freight-volume', component: FreightVolumeComponent },
  { path: 'work/freight-movement', component: FreightMovementComponent },
  { path: 'work/estes-quote', component: EstesQuoteComponent },
  { path: 'work/capital-one', component: CapitalOneComponent },
  { path: 'work/capital-one-2', component: CapitalOneTwoComponent },
  { path: 'work/predictive-modeling', component: PredictiveModelingComponent },
  { path: 'work/judicial-enforcement', component: JudicialEnforcementComponent },
  { path: 'work/jury-management', component: JuryManagementComponent },
  { path: 'work/data-lake', component: DataLakeComponent },
  { path: 'work/scala-architecture', component: ScalaArchitectureComponent },
  { path: 'work/devops-transformation', component: DevopsTransformationComponent },
  { path: 'work/unified-data', component: UnifiedDataComponent },
  { path: 'work/energy', component: EnergyComponent },
  { path: 'work/firearms-application', component: FirearmsApplicationComponent },
  { path: 'work/energy-2', component: EnergyTwoComponent },
  { path: 'work/sabra', component: SabraComponent },
  { path: 'work/vdss', component: VdssComponent }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(workRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class WorkRoutingModule { }
