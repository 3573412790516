<section class="full-page">
    <a class="insight-highlight-link" [routerLink]="link">
      <ng-content></ng-content>
      <div class="container insight-highlight-container">
        <div class="row">
          <div class="column col-12 center-content">
            <div class="content-wrapper insight-highlight-wrapper">
              <h6 class="subtitle white">
                <span>insight</span>
              </h6>
              <h1 class="insight-highlight-title">{{name}}</h1>
            </div>
          </div>
        </div>
      </div>
    </a>
  </section>
