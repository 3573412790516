<section class="full-page video" style="overflow: hidden;">
    <video class="fullscreen-bg__video" controls>
        <ng-content></ng-content>
    </video>
    <div class="container">
        <div class="row">
            <div class="column col-12 center-content">
                <div class="arrowbox text-white">
                    <span class="diamond"></span>
                    <div class="box-left"></div>
                    <div class="box-right"></div>
                    <div class="video-table">
                        <div class="video-table-cell">
                            <h1 class="arrowbox-title">{{name}}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
