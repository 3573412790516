<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Microsoft Azure" name="What is Your Azure Migration Strategy?"
                summary="Most Enterprises are moving to the Cloud as a cost-effective means to develop, deploy and manage their infrastructure.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
            <insight-pamphlet>
                <insight-sub-section class="subsection subsection-padding" name="Advantages of Azure"
                    content="A Cloud platform provides competitive advantages such as higher efficiency, greater flexibility, lower costs and better performance of applications to name a few; Microsoft Azure is one such Cloud platform that offers security, reliability and scalability to an Enterprise.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Azure Capabilities"
                    content="Microsoft Azure’s IaaS, PaaS and SaaS capabilities provide on-demand computing, storage resources, networking, web and mobile app services to accelerate time-to-value. It is ideal for hosting collaboration systems, running big data applications, and migrating on-premise infrastructure. The Azure platform can be effortlessly assimilated with an existing IT environment, allowing businesses to take full advantage of their infrastructure on cloud. It offers an ability to use latest technologies in a way that adds greatest value to a business. Whether you are pushing an existing infrastructure to cloud, migrating legacy applications, or deploying business-critical applications on Azure, one needs to carefully plan a migration strategy that best fits their specific requirements. Let’s walk through the simple steps to successfully plan the best-fit Azure Migration Strategy for your Enterprise.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Ascertain"
                    content="As the first step towards Azure Migration, you need to catalog your software and workloads. Documentation of all inventory, assessment of applications and the workflow related to each data entity should be completed. If you’re only migrating infrastructure, then catalog the workloads.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Assess"
                    content="The second step is to start with a custom, simple assessment to categorize the applications and workloads to understand the amount of work required. There are few tools like Microsoft’s Virtual Machine Readiness Assessment Tool and Microsoft Assessment and Planning (MAP) toolkit that can help in the assessment. This will lead to effort and time savings, avoiding needless frustration as well as costly resource consumption.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Target"
                    content="Identifying the destination of workloads will ease and minimize efforts. Factors like speed, ease of migration, cost and desired functionality, virtual machines and operating system are to be considered in this step.">
                </insight-sub-section>
                <insight-sub-section class="subsection " name="Migrate"
                    content="Finally, the last and most important step is to make the actual move. Microsoft Azure handles infrastructure such as servers, storage, networking and virtual machines, and manages their operations after migrating as well. An Enterprise can start with a simple application which can be divided into components to migrate to Azure environment. After testing a single component thoroughly, the next part can be moved. This can be continued until all the components or workloads are migrated to Azure. This helps in ensuring minimal to zero application/user disturbance and 100% availability of migrated application. Microsoft promises 99.99% system uptime SLA once an application/system is hosted on Azure platform.">
                </insight-sub-section>
                <insight-sub-section class="subsection "
                    content="These broad phases simplify the complexity involved in an Azure migration. There are many automated tools that will help you to screen, calculate and model possible cloud configurations. A strategy involving a value-based approach to application rationalization is highly recommended for Azure migration. Cloud adoption must have full buy-in and executive sponsorship from the leadership team, Azure consultants and the CEO.">
                </insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
