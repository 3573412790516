import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-governance-process',
  templateUrl: './governance-process.component.html',
  styleUrls: ['./governance-process.component.sass']
})
export class GovernanceProcessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
