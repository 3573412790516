import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { InsightContainerComponent } from '../common/insight/insight-container/insight-container.component';
import { InsightHeaderComponent } from '../common/insight/insight-header/insight-header.component';
import { InsightPamphletComponent } from '../common/insight/insight-pamphlet/insight-pamphlet.component';
import { InsightSubSectionComponent } from '../common/insight/insight-sub-section/insight-sub-section.component';

@NgModule({
  declarations: [
    InsightContainerComponent,
    InsightHeaderComponent,
    InsightPamphletComponent,
    InsightSubSectionComponent,
  ],
  exports: [
    InsightHeaderComponent,
    InsightPamphletComponent,
    InsightSubSectionComponent,
    InsightContainerComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class InsightComponentsModule { }
