import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.sass']
})
export class BackToTopComponent implements OnInit, OnDestroy {
  
  scrolled: boolean = false;

  constructor() { }

  ngOnInit() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll(event) {
    this.scrolled = window.scrollY > 0;
  }

  scrollTo() {
    document.querySelector(".__page-root").scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }
}
