import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'additional-services',
  templateUrl: './additional-services.component.html',
  styleUrls: ['./additional-services.component.sass']
})
export class AdditionalServicesComponent implements OnInit {
  @Input() name: string;
  @Input() subtitle: string;
  @Input() paragraphOne: string;
  @Input() paragraphTwo: string;
  
  constructor() { }

  ngOnInit() {
  }

}
