import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-six-cloud-migration-strategies',
  templateUrl: './six-cloud-migration-strategies.component.html',
  styleUrls: ['./six-cloud-migration-strategies.component.sass']
})
export class SixCloudMigrationStrategiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
