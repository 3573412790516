import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insight-data-governance',
  templateUrl: './data-governance.component.html',
  styleUrls: ['./data-governance.component.sass']
})
export class InsightDataGovernanceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
