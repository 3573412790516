import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'insight-header',
  templateUrl: './insight-header.component.html',
  styleUrls: ['./insight-header.component.sass']
})
export class InsightHeaderComponent implements OnInit {
  @Input() name: string;
  @Input() category: string;
  
  constructor() { }

  ngOnInit() {
  }

}
