<section data-aos="fade-up" class="padded-xlarge" [style.backgroundColor]="color">
    <div class="container">
        <div class="row">
            <div class="column col-5 responsive">
                <div class="content-wrapper summary-wrapper text-white">
                    <h6 class="subtitle white">
                        <span>client summary</span>
                    </h6>
                </div>
            </div>
            <div class="column col-7 responsive-8">
                <div class="content-wrapper text-white">
                    <p class="promise-text">{{content}}</p>
                </div>
            </div>
        </div>
        <ng-content></ng-content>
    </div>
</section>