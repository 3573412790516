<div class="__page-root">
  <div class="admin-wrapper">
    <h1>Careers</h1>
      <label style="display:block;" for="location">Location</label>
      <select class="form-control" style="display: inline-block; width: 300px;" id="location" (change)="onLocationChange($event.target.selectedIndex)" [(ngModel)]="currentLocation">
        <option *ngFor='let location of jobLocations' [ngValue]="location">{{location.city}}, {{location.state}}</option>
      </select>
      <a class="btn btn-light" style="margin-left: 10px;" routerLink="/admin/careers/location">Manage Locations</a>
    <div>
      <a *ngIf="currentLocation" class="btn btn-light" routerLink="/admin/careers/add/{{currentLocation.id}}">Add New Position</a>
    </div>
      <div *ngIf="jobs == null || jobs.length < 1">No results found!</div>
      <ul *ngIf="jobs" class="pseudo">
        <li class="admin-list" *ngFor='let job of jobs'>
          <button class="btn btn-danger" (click)="deleteJob(job.id)" title="Remove">X</button>&nbsp;&nbsp;&nbsp;<a routerLink="/admin/careers/job/{{job.id}}">{{job.title}}</a>
        </li>
      </ul>
    </div>
</div>
