<light-layout>
  <div class="__page-root">
    <hero name="Energy" kicker="Mobile business solution.">
      <div class="cover-image hero-background tinted"></div>
      <back-to-button></back-to-button>
    </hero>
    <div class="sections">
      <app-summary color="#FF2828"
                   content="Data Concepts’ client is one of the nation’s largest producers and transporters of
            energy, with a portfolio of approximately 26,000 megawatts of electric generation. It also
            operates one of the largest natural gas storage systems in the U.S. with 1 trillion cubic
            feet of capacity, and serve more than 6 million utility and retail energy customers.">
        <role [style.width.%]="100">
          <li>Microsoft Web Stack (Asp.Net MVC, WebAPI)</li>
          <li>jQuery/Bootstrap</li>
          <li>WPF</li>
        </role>
      </app-summary>

      <challenge color="#FF2828"
                     content="There was a number of tool and disconnected/old systems that enabled more than 200
                      servicers with more than 100 service trucks to run down the areas serviced by the client
                      with work orders. There were problems is streamlining the process and maintaining
                      timely information flow between so many small systems."></challenge>

      <solution-header content="a re-engineered solution"></solution-header>

      <solution>
        <p>
          Data Concepts started working on a solution by starting to understand the data flows
          from and to each of the systems involved. Once data is identified, it was transformed,
          normalized and loaded into a single data store. A single responsive web application was
          developed for management team to assign and manage work orders in the office. A
          windows application was developed for the services to use inside the truck on the field
          to collect and manipulate data from the field. And an intelligent sync process was
          developed to merge data from both ends understanding precedence based on complex
          business logic.
        </p>
        <ul class="pseudo">
          <li>- Deployable .Net assemblies to IIS web servers.</li>
          <li>- Deployable WPF application pushed through to all servicers tough-books.</li>
          <li>- Database setup in SQL Server 2016.</li>
          <li>- Reports in COGNOS.</li>
          <li>- Project source code and documentation.</li>
        </ul>
      </solution>

      <outcome color="#FF2828"
                   content="The solution deployed by Data Concepts enabled clients easily manage and service
                      consumer work orders using a single streamlined system that aesthetically provides
                      similar experience irrespective of whether it is used inside business premises or on the
                      field; also cutting down cost of managing several different systems and complete data
                      flow mechanisms."></outcome>
    </div>
  </div>
</light-layout>
