import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quick-peek',
  templateUrl: './quick-peek.component.html',
  styleUrls: ['./quick-peek.component.sass']
})
export class QuickPeekComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
