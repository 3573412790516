<section class="black padded-large">
    <div class="container">
      <div class="row">
        <div class="column col-4">
          <div class="content-wrapper text-white">
            <h6 class="subtitle white">
              <span>{{subtitle}}</span>
            </h6>
          </div>
        </div>
      </div>
      <div class="row marg">
        <ng-content></ng-content>
      </div>
    </div>
  </section>