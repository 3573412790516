<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;800&display=swap" rel="stylesheet">
<style>.practice-sec { }
.practice-block { float:left; width:100%; background:#eee; padding:40px 25px; color:#fff; border-right:1px solid #fff;}
.practice-block h4 {/* float:left; */width:100%;font-family:'Helvetica Neue';font-size:22px;font-weight:500;line-height:22px;letter-spacing:0.1px;margin:0 0 50px 0;min-height: 40px;}
.practice-block .practice-count { float:left; width:100%; font-family:'Helvetica Neue'; font-size:180px; font-weight:bold; line-height:180px; letter-spacing:-3px; margin:0 0 20px 0; padding:0 20px;}
.practice-block p { float:left; width:95%; font-size:13px; line-height:28px; padding:0 20px; min-height: 20vh;}
.practice-block a {margin:auto;display:table;/* display: flex; *//* text-align: center; *//* justify-content: center; *//* display: -webkit-box; */display: -ms-flexbox;/* display: flex; */-ms-flex-wrap: wrap;/* flex-wrap: wrap; */}
.bg-orange { background:#ee4b00 !important;}
.bg-black { background:#000000 !important;}
.bg-grey { background:#a09f9b !important;}
.bg-blue { background:#2d6f7f !important;}
.bg-green { background:#669141 !important;}
.practice-sec .practice-block {
     height: 100%;
     padding-bottom: 40px;
     position: relative;
     text-align: center;
    }
	.btn-outline-white { color:#fff; border:2px solid #fff;}
.btn-outline-white:hover { color:#333; border:2px solid #fff; background:#fff;}</style>
<home-layout>
  <div fullpage
       id="fullpage"
       class="__page-root"
       [options]="config"
       (ref)="getRef($event)">
    <div class="section" id="section1">
      <div id="canvas-wrapper" class="full-page">
        <canvas></canvas>
        <div class="container hero-content">
          <div class="row">
            <div class="column center-content col-12">
              <div class="copy-wrapper text-white">
                <h1 class="hero-title">Welcome to <br /> Data Concepts</h1>
                <h2 class="hero-kicker" data-aos-delay="100">
                  Driving Digital Transformation
                </h2>
              </div>
            </div>
            <div (click)="scrollTo()" class="arrow-wrapper">
              <svg data-aos="fade-down"
                   data-aos-duration="1000"
                   version="1.1"
                   id="Ebene_2_1_"
                   xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink"
                   x="0px"
                   y="0px"
                   width="53px"
                   height="20px"
                   viewBox="0 0 53 20"
                   enable-background="new 0 0 53 20"
                   xml:space="preserve"
                   fill="white">
              <g>
              <polygon points="26.5,17.688 9.114,3.779 10.303,2.312 26.5,15.269 42.697,2.313 43.886,3.779 "></polygon>
                </g>
            </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!------------------new section - 2 start ------------------------>
    <div class="section sections" id="section2_1">
      <div class="container">
        <div class="column col-6 col-md-6 col-sm-12 col-xs-12">
          <h3 class="galaxy_sub_title tw"></h3>
          <h1 class="galaxy_title" id="">
            We are<br>Data Concepts
          </h1>
        </div>
        <div class="column col-6 col-md-6 col-sm-12 col-xs-12">
          <p class="galaxy_text">
            At Data Concepts we aim to make a meaningful difference to our clients and the lives of the people we work with.
            <br /><br />
            We are Richmond’s most integrated technology consulting business. Through our model, we build seamless teams around the complex needs of each of our clients.
            <br /><br />
            Our ambition is to be a home for fabulously talented people and a partner to great companies. But most of all, we want to be a rewarding place to work at, and a great partner to work with.
            <br /><br />
            If you’d like to know a little more, we’d love to hear from you.
          </p>

        </div>
      </div>
    </div>
    <!------------------new section - 2 end ------------------------>
    <div class="section black" id="section12">
      <div class="technology padded-small text-white">
        <div class="container" data-aos="fade-up">
          <div class="column col-12">
            <div class="content-wrapper">
              <h6 class="technology subtitle white">
                <span>what we do</span>
              </h6>
            </div>
          </div>
          <div class="column col-12 responsive" data-aos="fade-up" data-aos-duration="600">
            <div class="content-wrapper special-title-wrapper">
              <h1 class="special-title">
                A Technical Consultancy, Born Digital.
              </h1>
              <div class="copy">
                <p>
                  Data Concepts transforms companies by designing best-in-class
                  systems, driven by strong creative and rooted in the
                  technology, data, and prudent analytics strategies required
                  for operational excellence. Data Concepts solves our clients'
                  toughest challenges by providing unmatched consulting services
                  in data, analytics, and digital. We are a full-service,
                  digitally-savvy collection of technical specialists.
                </p>
              </div>
            </div>
          </div>
          <div class="column col-12" data-aos="fade-up" data-aos-duration="600">
            <div class="content-wrapper technology-wrapper">
              <div class="technology outer">
                <h3 class="technology-title">Technology.</h3>
                <div class="tech">
                  <a class="technology-link"
                     routerLink="/services/application-development">Application Services</a>
                </div>
                <div class="tech">
                  <a class="technology-link"
                     routerLink="/services/cloud">Cloud</a>
                </div>
                <div class="tech">
                  <a class="technology-link"
                     routerLink="/services/big-data">Big Data</a>
                </div>
                <div class="tech">
                  <a class="technology-link"
                     routerLink="/services/analytics">Analytics</a>
                </div>
                <div class="tech">
                  <a class="technology-link"
                     routerLink="/services/ui-ux">UI/UX</a>
                </div>
              </div>
              <div class="technology outer">
                <h3 class="technology-title">Digital.</h3>
                <div class="tech">
                  <a class="technology-link"
                     routerLink="/services/digital">Digital</a>
                </div>
                <div class="tech">
                  <a class="technology-link"
                     routerLink="/services/artificial-intelligence">AI</a>
                </div>
                <div class="tech">
                  <a class="technology-link"
                     routerLink="/services/machine-learning">ML</a>
                </div>
                <div class="tech">
                  <a class="technology-link"
                     routerLink="/services/cyber-security">Cyber Security</a>
                </div>

                <div class="tech">
                  <a class="technology-link" routerLink="/services/iot">IoT</a>
                </div>
              </div>
              <div class="technology outer">
                <h3 class="technology-title">Data.</h3>
                <div class="tech">
                  <a class="technology-link"
                     routerLink="/services/data-strategy">Data Engineering</a>
                </div>

                <div class="tech">
                  <a class="technology-link"
                     routerLink="/services/data-governance">Data Governance</a>
                </div>

                <div class="tech">
                  <a class="technology-link"
                     routerLink="/services/data-visualization'">Data Visualization</a>
                </div>

                <div class="tech">
                  <a class="technology-link"
                     routerLink="/services/data-science">Data Science</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section" id="section2">
      <div class="container">
        <h6 class="technology subtitle">
          <span>who we are</span>
        </h6>
      </div>
      <div class="container" style="height:80vh">
        <div class="column col-12">
          <div class="copy-wrapper" style="margin-top:18vh">
            <h1 class="section-title" id="special-section-title">
              Technology is our craft.
            </h1>
            <p class="section-kicker storyteller-text">
              We're a team of storytellers, strategists, architects, designers,
              and engineers working together with key alliances to bring a full
              slate of offerings and capabilities to our clients.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section" id="section3">
      <div class="fp-bg estes-product"></div>
      <div class="section-wrapper">
        <div class="section-details">
          <div class="product-line-wrapper">
            <span>01.</span><span class="product-line"></span>
          </div>
          <a routerLink="/work" class="product-subtitle">Work.</a>
          <div data-aos="fade-up" data-aos-duration="600">
            <h2 class="product-title">Route optimization for LTL trucking.</h2>
            <p class="product-text">Estes Trucking.</p>
            <a routerLink="/work/freight-volume" class="product-link">
              <svg class="arrow-thin-right__svg"
                   version="1.1"
                   id="Layer_1"
                   xmlns="http://www.w3.org/2000/svg"
                   x="0px"
                   y="0px"
                   width="39.002px"
                   height="26.005px"
                   viewBox="286.5 382.498 39.002 26.005"
                   enable-background="new 286.5 382.498 39.002 26.005"
                   title="Next">
                <g>
                  <polygon fill="#000"
                           points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section" id="section4">
      <div class="fp-bg data-lake-product"></div>
      <div class="section-wrapper">
        <div class="section-details">
          <div class="product-line-wrapper">
            <span>02.</span><span class="product-line"></span>
          </div>
          <a routerLink="/work" class="product-subtitle">Work.</a>
          <div data-aos="fade-up" data-aos-duration="600">
            <h2 class="product-title">
              Data lake & rearchitecture, leveraging Kafka.
            </h2>
            <p class="product-text">Supreme Court of Virginia.</p>
            <a routerLink="/work/court" class="product-link">
              <svg class="arrow-thin-right__svg"
                   version="1.1"
                   id="Layer_1"
                   xmlns="http://www.w3.org/2000/svg"
                   x="0px"
                   y="0px"
                   width="39.002px"
                   height="26.005px"
                   viewBox="286.5 382.498 39.002 26.005"
                   enable-background="new 286.5 382.498 39.002 26.005"
                   title="Next">
                <g>
                  <polygon fill="#000"
                           points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section" id="section5">
      <div class="fp-bg sabra-product"></div>
      <div class="section-wrapper">
        <div class="section-details">
          <div class="product-line-wrapper">
            <span>03.</span><span class="product-line"></span>
          </div>
          <a routerLink="/work" class="product-subtitle">Work.</a>
          <div data-aos="fade-up" data-aos-duration="600">
            <h2 class="product-title">Application Modernization.</h2>
            <p class="product-text">Sabra.</p>
            <a routerLink="/work/sabra" class="product-link">
              <svg class="arrow-thin-right__svg"
                   version="1.1"
                   id="Layer_1"
                   xmlns="http://www.w3.org/2000/svg"
                   x="0px"
                   y="0px"
                   width="39.002px"
                   height="26.005px"
                   viewBox="286.5 382.498 39.002 26.005"
                   enable-background="new 286.5 382.498 39.002 26.005"
                   title="Next">
                <g>
                  <polygon fill="#000"
                           points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section" id="section6">
      <div class="fp-bg firearms-product"></div>
      <div class="section-wrapper">
        <div class="section-details">
          <div class="product-line-wrapper">
            <span>04.</span><span class="product-line"></span>
          </div>
          <a routerLink="/work" class="product-subtitle">Work.</a>
          <div data-aos="fade-up" data-aos-duration="600">
            <h2 class="product-title">Background check application system.</h2>
            <p class="product-text">Virginia State Police.</p>
            <a routerLink="/work/firearms-application"
               class="product-link">
              <svg class="arrow-thin-right__svg"
                   version="1.1"
                   id="Layer_1"
                   xmlns="http://www.w3.org/2000/svg"
                   x="0px"
                   y="0px"
                   width="39.002px"
                   height="26.005px"
                   viewBox="286.5 382.498 39.002 26.005"
                   enable-background="new 286.5 382.498 39.002 26.005"
                   title="Next">
                <g>
                  <polygon fill="#000"
                           points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="section" id="section7">
      <div class="fp-bg quick-peek-product"></div>
      <div class="section-wrapper">
        <div class="section-details">
          <div class="product-line-wrapper">
            <span>05.</span><span class="product-line"></span>
          </div>
          <a routerLink="/insights" class="product-subtitle">Insight.</a>
          <div data-aos="fade-up" data-aos-duration="600">
            <h2 class="product-title">A Quick Peek at Our World.</h2>
            <p class="product-text">Helping Clients with Data Strategy.</p>
            <a routerLink="/insights/quick-peek" class="product-link">
              <svg class="arrow-thin-right__svg"
                   version="1.1"
                   id="Layer_1"
                   xmlns="http://www.w3.org/2000/svg"
                   x="0px"
                   y="0px"
                   width="39.002px"
                   height="26.005px"
                   viewBox="286.5 382.498 39.002 26.005"
                   enable-background="new 286.5 382.498 39.002 26.005"
                   title="Next">
                <g>
                  <polygon fill="#000"
                           points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section" id="section8">
      <div class="agile-understanding-product"></div>
      <div class="section-wrapper">
        <div class="section-details text-white">

          <div class="product-line-wrapper">
            <span>06.</span><span class="product-line white"></span>
          </div>
          <a routerLink="/insights" class="product-subtitle text-white">Insight.</a>
          <div data-aos="fade-up" data-aos-duration="600">
            <h2 class="product-title">
              Understanding what it means to be agile.
            </h2>
            <p class="product-text">Agile Transformation.</p>
            <a routerLink="/insights/agile-understanding"
               class="product-link">
              <svg class="arrow-thin-right__svg"
                   version="1.1"
                   id="Layer_1"
                   xmlns="http://www.w3.org/2000/svg"
                   x="0px"
                   y="0px"
                   width="39.002px"
                   height="26.005px"
                   viewBox="286.5 382.498 39.002 26.005"
                   enable-background="new 286.5 382.498 39.002 26.005"
                   title="Next">
                <g>
                  <polygon fill="#fff"
                           points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="section" id="section9">
      <div class="fp-bg data-business-product"></div>
      <div class="section-wrapper">
        <div class="section-details text-white">
          <div class="product-line-wrapper">
            <span>07.</span><span class="product-line white"></span>
          </div>
          <a routerLink="/insights" class="product-subtitle text-white">Insight.</a>
          <div data-aos="fade-up" data-aos-duration="600">
            <h2 class="product-title">
              Roadmap for Driving Business with Data.
            </h2>
            <p class="product-text">Big Data.</p>
            <a routerLink="/insights/big-data-business" class="product-link">
              <svg class="arrow-thin-right__svg"
                   version="1.1"
                   id="Layer_1"
                   xmlns="http://www.w3.org/2000/svg"
                   x="0px"
                   y="0px"
                   width="39.002px"
                   height="26.005px"
                   viewBox="286.5 382.498 39.002 26.005"
                   enable-background="new 286.5 382.498 39.002 26.005"
                   title="Next">
                <g>
                  <polygon fill="#fff"
                           points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="section" id="section10">
      <div class="fp-bg research-1"></div>
      <div class="section-wrapper">
        <div class="section-details text-white">
          <div class="product-line-wrapper">
            <span>08.</span><span class="product-line white"></span>
          </div>
          <a routerLink="/insights" class="product-subtitle text-white">Research.</a>
          <div data-aos="fade-up" data-aos-duration="600">
            <h2 class="product-title">
              Untrap &amp; Harness Your Data.
            </h2>
            <p class="product-text">Data Governance: Processes &amp; Metrics.</p>
            <a routerLink="/insights/governance-process" class="product-link">
              <svg class="arrow-thin-right__svg"
                   version="1.1"
                   id="Layer_1"
                   xmlns="http://www.w3.org/2000/svg"
                   x="0px"
                   y="0px"
                   width="39.002px"
                   height="26.005px"
                   viewBox="286.5 382.498 39.002 26.005"
                   enable-background="new 286.5 382.498 39.002 26.005"
                   title="Next">
                <g>
                  <polygon fill="#fff"
                           points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>


    <div class="section" id="section11">
      <div class="fp-bg research-3"></div>
      <div class="section-wrapper">
        <div class="section-details">
          <div class="product-line-wrapper">
            <span>09.</span><span class="product-line"></span>
          </div>
          <a routerLink="/insights" class="product-subtitle">Research.</a>
          <div data-aos="fade-up" data-aos-duration="600">
            <h2 class="product-title">
              Data Strategy is About Doing the Right Things.
            </h2>
            <p class="product-text">Data Strategy.</p>
            <a routerLink="/insights/data-strategy-right" class="product-link">
              <svg class="arrow-thin-right__svg"
                   version="1.1"
                   id="Layer_1"
                   xmlns="http://www.w3.org/2000/svg"
                   x="0px"
                   y="0px"
                   width="39.002px"
                   height="26.005px"
                   viewBox="286.5 382.498 39.002 26.005"
                   enable-background="new 286.5 382.498 39.002 26.005"
                   title="Next">
                <g>
                  <polygon fill="#000"
                           points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
	<!---------------------------------------------------------------------------------------------------------------->
	<div class="section" id="section12">
		<div class="fp-bg product-2"></div>
		<div class="section-wrapper">
        <div class="section-details text-white" style="width:85%">
		<div class="product-line-wrapper">
            <span>10.</span><span class="product-line white"></span>
          </div>
          <a routerLink="/insights" class="product-subtitle text-white">Research.</a>
          <div data-aos="fade-up" data-aos-duration="600">
            <h2 class="product-title">Choreographing a<br/>Data Architecture.</h2>
            <p class="product-text">Big Data.</p>
            <a routerLink="/insights/modern-data-architecture" class="product-link">
			<svg class="arrow-thin-right__svg"
                   version="1.1"
                   id="Layer_1"
                   xmlns="http://www.w3.org/2000/svg"
                   x="0px"
                   y="0px"
                   width="39.002px"
                   height="26.005px"
                   viewBox="286.5 382.498 39.002 26.005"
                   enable-background="new 286.5 382.498 39.002 26.005"
                   title="Next">
                <g>
                  <polygon fill="#fff"
                           points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
	  </div>
	<div class="section" id="section13">
      <div class="fp-bg order_videoContainer">
	   <video muted autoplay loop>
		<source src="../../assets/video/our_people.mp4" id="menu_video" type="video/mp4">
		</video>
	  
	  </div>
      <div class="section-wrapper">
        <div class="section-details">
          <div class="product-line-wrapper">
            <span class="text-white">11.</span><span class="product-line white"></span>
          </div>
          <a routerLink="/about" class="product-subtitle text-white">About.</a>
          <div class="" data-aos="fade-up" data-aos-duration="600">
            <h2 class="product-title text-white" style="text-shadow: 2px 2px 2px #222;">
              Our People
            </h2>
            <p class="product-text our-people-text text-white" style="text-shadow: 2px 2px 2px #222;">Our difference depends on the diverse, engaged, passionate, and experienced people who create our ideas and serve our clients.</p>
            <a routerLink="/about" class="product-link">
              <svg class="arrow-thin-right__svg"
                   version="1.1"
                   id="Layer_1"
                   xmlns="http://www.w3.org/2000/svg"
                   x="0px"
                   y="0px"
                   width="39.002px"
                   height="26.005px"
                   viewBox="286.5 382.498 39.002 26.005"
                   enable-background="new 286.5 382.498 39.002 26.005"
                   title="Next">
                <g>
                  <polygon fill="#fff"
                           points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
	<div class="practice-sec section">
		<div class="container">
			<div class="row">
				<div class="product-line-wrapper">
			<span>12.</span><span class="product-line black"></span>
			</div>
			<a routerLink="/" class="product-subtitle text-black">Practice At A Glance.</a>
			</div>
			<div class="row">
			
            <div class="col-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
				<div class="practice-block bg-orange">
                	<h4>Years<br> of service</h4>
                    <div class="practice-count">22</div>
                    <p>Over two decades of varied projects, people, and perspectives have ingrained in our team valuable learnings from which we've developed a smooth-running process.</p>
                 <div class="flexbb">    <a href="" class="btn btn-outline-white">Our History</a></div>
                </div>
            </div>
            <div class="col-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
				<div class="practice-block bg-black">
                	<h4>Inspired<br> Professionals</h4>
                    <div class="practice-count">260</div>
                    <p>People are our greatest asset and the engine behind our innovative realization of your vision.</p>
                   <div class="flexbb">  <a href="" class="btn btn-outline-white">The Team</a></div>
                </div>
            </div>
            <div class="col-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="600">
				<div class="practice-block bg-grey">
                	<h4>Completed<br> Projects</h4>
                    <div class="practice-count">667</div>
                    <p>If our work doesn't tell the full story, we're always happy to regale you with the details.</p>
                    <div class="flexbb"> <a href="" class="btn btn-outline-white">The Works</a></div>
                </div>
            </div>
            <div class="col-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="800">
				<div class="practice-block bg-blue">
                	<h4>Awards</h4>
                    <div class="practice-count">11</div>
                    <p>Icing on the cake—we're humbled by the recognition we've received over the years.</p>
                    <div class="flexbb"><a href="" class="btn btn-outline-white">View Awards</a></div>
                </div>
            </div>
			</div>
        </div>
	</div>


    <div class="section" id="section14">
      <div class="container">
        <div class="row">
          <div class="column col-12 center-content">
            <div class="dynamic-text-wrapper" data-aos="fade-up" data-aos-duration="600">
              <h3>Need help</h3>
              <svg id="typewriterSvg">
                <defs>
                  <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="10%" style="stop-color:#ff48a6;stop-opacity:1" />
                    <stop offset="90%" style="stop-color:#520881;stop-opacity:1" />
                  </linearGradient>
                  <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="10%" style="stop-color:#29d5ff;stop-opacity:1" />
                    <stop offset="90%" style="stop-color:#6c03a8;stop-opacity:1" />
                  </linearGradient>
                  <linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="10%" style="stop-color:#93d522;stop-opacity:1" />
                    <stop offset="90%" style="stop-color:#033e8e;stop-opacity:1" />
                  </linearGradient>
                </defs>
                <text id="typewriterText" fill="url(#grad1)" x="0" y="50"></text>
              </svg>
              <h3>We're Here For You.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section" id="section15">
      <div class="fp-bg giving-matters"></div>
      <div class="section-wrapper">
        <div class="section-details">
          <div data-aos="fade-up" data-aos-duration="600">
            <h2 class="product-title text-white">
              Giving Matters
            </h2>
            <p class="product-text text-white">Explore Our Community Service &amp; Corporate Citizenship.</p>
            <a routerLink="/community-service" class="product-link">
              <svg class="arrow-thin-right__svg"
                   version="1.1"
                   id="Layer_1"
                   xmlns="http://www.w3.org/2000/svg"
                   x="0px"
                   y="0px"
                   width="39.002px"
                   height="26.005px"
                   viewBox="286.5 382.498 39.002 26.005"
                   enable-background="new 286.5 382.498 39.002 26.005"
                   title="Next">
                <g>
                  <polygon fill="#fff"
                           points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section" id="section16">
      <div class="fp-bg culture-product" style="z-index:1;">
        <div class="culture-overlay"></div>
      </div>
      <div class="container" data-aos="fade-up" data-aos-duration="600">
        <div class="row">
          <div class="column col-7 responsive center-content">
            <div class="content-wrapper join-us-wrapper">
              <a class="join-us-link" routerLink="/culture">
                <h1 class="join-us-header">Culture: Fiercely Individual</h1>
                <p class="product-text text-white">We believe the alchemy of people &amp; technology drives expeiences.</p>
                <div class="content-wrapper">
                  <div class="product-link">
                    <svg class="arrow-thin-right__svg"
                         version="1.1"
                         id="Layer_1"
                         xmlns="http://www.w3.org/2000/svg"
                         x="0px"
                         y="0px"
                         width="39.002px"
                         height="26.005px"
                         viewBox="286.5 382.498 39.002 26.005"
                         enable-background="new 286.5 382.498 39.002 26.005"
                         title="Next">
                      <g>
                        <polygon fill="#fff"
                                 points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                      </g>
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
	
    <div class="video_bg section" id="section17">
		<div class="container">
			<div class="col-6">
				<app-video videoID="about2Video" videoSrc="dc3-2b.mp4" posterSrc="/assets/video/TitleFileName-2.svg"></app-video>
			</div>
		</div>
    </div>
    <div class="section" id="section18">
      <subscribe></subscribe>
      <hire-us-footer></hire-us-footer>
    </div>
    <!-------------------------------------------------------------------------->
    <div class="section" id="section20">
      <div class="home-footer" data-aos="fade-up" data-aos-duration="600">
        <div class="container">
          <div class="col-12">
            <h1 class="agency_header_1 text-black">AN AGENCY BUILT TO</h1>
          </div>
          <div class="col-12">
            <h1 class="agency_header_2 text-black">CREATE REAL VALUE</h1>
          </div>
          <div class="col-6">
            <p class="agency_text text-black">Data Concepts is a growth partner. We’re a nimble, hungry, results-driven firm. We execute with purpose and focus on measurable, actionable results.</p>
          </div>
          <div class="footer-kicker-text">CHECK OUT OUR WORK</div>
          <div class="footer-kicker-arrow">
            <a routerLink="/work" class="product-link">
              <svg class="arrow-thin-right__svg"
                   version="1.1"
                   id="Layer_1"
                   xmlns="http://www.w3.org/2000/svg"
                   x="0px"
                   y="0px"
                   width="39.002px"
                   height="26.005px"
                   viewBox="286.5 382.498 39.002 26.005"
                   enable-background="new 286.5 382.498 39.002 26.005"
                   title="Next">
                <g>
                  <polygon fill="#000"
                           points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 "></polygon>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
	<!--------------------------------------------------------------------------------------------->
	  <div class="section">
          <div class="fp-bg home_sustainability_2"></div>
          <div class="section-wrapper">
            <div class="section-details">
              <p class="section-box-subtitle">MAKE AN IMPACT. YOUR OWN WAY</p><br>
                <h1 class="section-box-title">Immediate Impact, <BR>Continuous Challenge</h1><br>
				<p class='text-white'>As part of our entrepreneurial team, you’ll do meaningful work from day one. We’re looking for individuals who challenge the norm, and constantly strive to build something new for the firm and the world around us.</p>
                <a class="section-box-link" routerLink="/careers">
                  <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    x="0px" y="0px" width="39.002px" height="26.005px" viewBox="286.5 382.498 39.002 26.005"
                    enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                    <g>
                      <polygon fill="#F0F0F0"
                        points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                      </polygon>
                    </g>
                  </svg>
                </a>
            </div>
          </div>
        </div>
      
	<!--------------------------------------------------------------------------------------------->
    <div class="section" id="section20">
      <div class="home-footer absolute" data-aos="fade-up" data-aos-duration="600">
        <div class="home-footer" data-aos="fade-up" data-aos-duration="600">
          <div class="container">
            <div class="col-12">
              <h1 class="honest_text square_icon">Let’s have a conversation,<br>we’d be happy to provide<br>some honest guidance <img src="../../assets/image/square.jpg" width=""></h1>
            </div>
            <div class="footer-kicker-text2">CONTACT US</div>
            <div class="footer-kicker-text2">
              <a routerLink="/contact" class="product-link">
                <svg class="arrow-thin-right__svg"
                     version="1.1"
                     id="Layer_1"
                     xmlns="http://www.w3.org/2000/svg"
                     x="0px"
                     y="0px"
                     width="3.002px"
                     height="26.005px"
                     viewBox="286.5 382.498 39.002 26.005"
                     enable-background="new 286.5 382.498 39.002 26.005"
                     title="Next">
                  <g>
                    <polygon fill="#000"
                             points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 "></polygon>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>

        <main-footer></main-footer>
        <sub-footer></sub-footer>
      </div>
    </div>
  </div>
</home-layout>
