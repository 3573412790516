import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { JobLocation } from '../../../../_models/job-location';
import { CareerService } from '../../../../_services/career.service';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.sass']
})
export class AddLocationComponent implements OnInit {
  jobLocation: JobLocation;
  jobLocationForm = this.fb.group({
    city: ["", Validators.required],
    state: ["", Validators.required]
  });
  error: string;

  constructor(
    private router: Router,
    private location: Location,
    private careerService: CareerService,
    private fb: FormBuilder) { }

  ngOnInit() {
  }

  onSubmit(event: any) {
    event.preventDefault();
    if (this.jobLocationForm.valid) {
      this.addJobLocation();
    }
  }

  addJobLocation() {
    this.jobLocation = new JobLocation();
    this.jobLocation.city = this.jobLocationForm.get("city").value;
    this.jobLocation.state = this.jobLocationForm.get("state").value;
    debugger;
    this.careerService.addJobLocation(this.jobLocation)
      .subscribe(() => {
        this.router.navigate(['admin', 'careers', 'location']);
      },
        () => {
          this.error = "Failed to add location, please try again later.";
        });
  }

  cancelAction() {
    this.location.back();
  }
}
