import { Component, OnInit } from '@angular/core';
import { CareerService } from '../_services/career.service';
import { JobLocation } from '../_models/job-location';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.sass']
})
export class CareersComponent implements OnInit {
  jobLocations: JobLocation[];
  perks: any = [
    {
      title: "Flexible Hours",
      copy:
        "As a consultancy built on creativity and technology, we want you at your best. That's why we work remotely on select days and even adjust our hours depending on life's impromptu moments."
    },
    {
      title: "Paid Time Off",
      copy:
        "It’s important to relax and recharge. We offer flexible, paid vacation so you can work on that tan. Not to mention team-wide ‘Happy DC Days' where food and fun are on us."
    },
    {
      title: "Food & Drink",
      copy:
        "The way to happy employees is often through their stomach. We keep our kitchen stocked with drink and snack selections as well as craft coffee to keep you going."
    },
    {
      title: "Irresistible Culture",
      copy:
        "At the risk of sounding cliche, we fight for culture at every turn. Come and enjoy our BAGEL WEDNESDAY tradition. Or, join us for our DC-DrinkUP."
    },
    {
      title: "Growth",
      copy:
        "We want you to develop and learn. Every employee has the opportunity to grow in their role(s) at Data Concepts through hands-on experience and workshops."
    },
    {
      title: "Retirement Planning",
      copy:
        "Getting old sucks. We try to make it suck less by offering a 2% retirement match for employees who take advantage of our company’s 401K investment plan."
    },
    {
      title: "Company Benefits",
      copy:
        "Our team’s health and wellness is a top priority. We offer fully paid health benefits for all employees. We also pay half for your spouse."
    },
    {
      title: "Compensation",
      copy:
        "We value the members of our team, and pay competitive wages based on position and experience."
    }
  ];

  constructor(private careerService: CareerService) { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
    this.getJobLocations();
  }

  getJobLocations() {
    this.careerService.getJobLocations()
      .subscribe(locations => {
        this.jobLocations = locations;
        this.getJobsByLocation();
      });
  }

  getJobsByLocation() {
    for (let i = 0; i < this.jobLocations.length; i++) {
      this.careerService.getJobsByLocationID(this.jobLocations[i].id)
        .subscribe(jobs => {
          this.jobLocations[i].jobs = jobs;
        });
    }
  }
}
