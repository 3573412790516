<default-layout>
    <div class="__page-root">
        <root-hero name="Together."></root-hero>
        <div class="sections">
            <section data-aos="fade-up" class="padded-large black section-correction">
                <div class="container">
                    <div class="row">
                        <div class="column col-12">
                            <div class="copy-wrapper text-wrapper text-white">
                                <h1 class="insights-title text-white center-text">We are always looking for awesome people.
                                </h1>
                                <p class="insights-text">
                                    Do you build amazing software? We’re looking for Product Designers, Cloud Architects,
                                    Customer Experience Architects, Digital Transformation Managers, Developers and more.
                                    <br>Join us and help disrupt the business world.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-aos="fade-up" class="padded-large">
            <app-video videoID="careerVideo" videoSrc="dc.mp4" posterSrc="/assets/video/TitleFileName-2.svg"></app-video>
            </section>
            <section data-aos="fade-up" class="padded-large black">
                <div class="container">
                    <!-- <div class="row">
    
                <div class="column col-12">
                  <div class="copy-wrapper text-wrapper text-white text-border-white1">
                    <h1 class="insights-title text-white">We are always looking for awesome people.</h1>
                    <p class="insights-text">
                      Do you build amazing software? We’re looking for Product Designers, Cloud Architects, Customer Experience Architects, Digital Transformation Managers, Developers and more.
                      <br>Join us and help disrupt the business world.
                    </p>
                  </div>
                </div>
              </div>-->
                    <!-- </div> -->
                    <!-- <div class="container"> -->
    
                    <div class="row">
                        <div class="column col-4">
                            <div class="content-wrapper text-white">
                                <h6 class="subtitle white">
                                    <span>perks</span>
                                </h6>
                            </div>
                        </div>
                        <div class="column col-12">
                            <div class="copy-wrapper perks-wrapper text-white">
                                <div *ngFor="let perk of perks" class="perk">
                                    <h1 class="perk-title">{{perk.title}}</h1>
                                    <p class="perk-copy">{{perk.copy}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-aos="fade-up" class="padded-large">
                <div class="container">
                    <div class="row">
                        <div class="column col-3 responsive">
                            <div class="content-wrapper">
                                <h6 class="subtitle">
                                    <span>current openings</span>
                                </h6>
                            </div>
                        </div>
                        <div class="column col-9 responsive-8">
                            <div class="content-wrapper jobs-wrapper">
                                <div class="outer" *ngFor="let jobLocation of jobLocations">
                                    <div *ngIf="jobLocation.jobs != null && jobLocation.jobs.length>0">
                                        <h3 class="jobs-location-title">{{jobLocation.city}}, {{jobLocation.state}}</h3>
                                        <div class="job-wrapper">
                                            <div class="job" *ngFor="let job of jobLocation.jobs">
                                                <a class="jobs-link" routerLink="/careers/{{job.id}}">{{job.title}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</default-layout>
