<section class="padded-xxlarge gradient-3">
    <div class="container">
      <div class="row">
        <div class="column col-8 responsive-8">
          <div data-aos="fade-up" class="content-wrapper aside-left-wrapper text-white">
            <h1 class="aside-left-header section-title left text-white medium">{{name}}</h1>
            <p class="aside-left-text">{{content}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>