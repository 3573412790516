
    <a class="insight-link grey" [routerLink]="uri">
      <div class="content-wrapper text-white">
        <h3 class="insight-title">{{name}}</h3>
        <p class="insight-summary">{{summary}}</p>
      </div>
      <ng-content></ng-content>
      <span class="insight-category">{{category}}</span>
      <span class="read-more">Read More</span>
    </a>
