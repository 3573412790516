import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-server-migration',
  templateUrl: './server-migration.component.html',
  styleUrls: ['./server-migration.component.sass']
})
export class ServerMigrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
