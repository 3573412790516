import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { JobLocation } from '../../../_models/job-location';
import { CareerService } from '../../../_services/career.service';

@Component({
  selector: 'app-job-location',
  templateUrl: './job-location.component.html',
  styleUrls: ['./job-location.component.sass']
})
export class JobLocationComponent implements OnInit {
  jobLocation: JobLocation;
  jobLocationForm = this.fb.group({
    city: ["", Validators.required],
    state: ["", [Validators.required, Validators.maxLength(2)]]
  });
  error: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private careerService: CareerService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.getJobLocation();
  }

  onSubmit(event: any) {
    event.preventDefault();
    if (this.jobLocationForm.valid) {
      this.updateJobLocation();
    }
  }

  getJobLocation() {
    const id = + this.route.snapshot.paramMap.get('id');
    this.careerService.getJobLocationByID(id)
      .subscribe(jobLocation => {
        this.jobLocation = jobLocation;
        this.updateForm();
      });
  }

  updateForm() {
    this.jobLocationForm.get("city").setValue(this.jobLocation.city);
    this.jobLocationForm.get("state").setValue(this.jobLocation.state);
  }

  updateJobLocation() {
    this.jobLocation.city = this.jobLocationForm.get("city").value;
    this.jobLocation.state = this.jobLocationForm.get("state").value;

    this.careerService.updateJobLocation(this.jobLocation)
      .subscribe(() => {
        this.router.navigate(['admin', 'careers', 'location']);
      },
        () => {
          this.error = "Failed to update location, please try again later.";
        });
  }

  cancelAction() {
    this.location.back();
  }
}
