<section data-aos="fade-up" class="padded-xlarge light-grey">
    <div class="container">
        <div class="row padded">
            <div class="column col-12">
                <div class="copy-wrapper outcome-wrapper">
                    <h1 class="section-title medium" [style.color]="color">OUTCOME</h1>
                    <p>{{content}}</p>
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </div>
</section>