import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'capabilities',
  templateUrl: './capabilities.component.html',
  styleUrls: ['./capabilities.component.sass']
})
export class CapabilitiesComponent implements OnInit {
  @Input() subtitle: string;
  
  constructor() { }

  ngOnInit() {
  }

}
