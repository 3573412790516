import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-master-data-management',
  templateUrl: './master-data-management.component.html',
  styleUrls: ['./master-data-management.component.sass']
})
export class MasterDataManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
