import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ServicesComponent } from "../services/services.component";
import { AnalyticsComponent } from '../services/analytics/analytics.component';
import { ApplicationDevelopmentComponent } from '../services/application-development/application-development.component';
import { ArtificialIntelligenceComponent } from '../services/artificial-intelligence/artificial-intelligence.component';
import { BigDataComponent } from '../services/big-data/big-data.component';
import { BlockchainComponent } from '../services/blockchain/blockchain.component';
import { BusinessIntelligenceComponent } from '../services/business-intelligence/business-intelligence.component';
import { CloudComponent } from '../services/cloud/cloud.component';
import { CyberSecurityComponent } from '../services/cyber-security/cyber-security.component';
import { DataGovernanceComponent } from '../services/data-governance/data-governance.component';
import { DataIntegrationComponent } from '../services/data-integration/data-integration.component';
import { DataScienceComponent } from '../services/data-science/data-science.component';
import { DataStrategyComponent } from '../services/data-strategy/data-strategy.component';
import { DataVisualizationComponent } from '../services/data-visualization/data-visualization.component';
import { DataWarehouseComponent } from '../services/data-warehouse/data-warehouse.component';
import { DevopsComponent } from '../services/devops/devops.component';
import { DigitalComponent } from '../services/digital/digital.component';
import { IotComponent } from '../services/iot/iot.component';
import { MachineLearningComponent } from '../services/machine-learning/machine-learning.component';
import { MasterDataManagementComponent } from '../services/master-data-management/master-data-management.component';
import { MicrosoftAzureComponent } from '../services/microsoft-azure/microsoft-azure.component';
import { MobilityComponent } from '../services/mobility/mobility.component';
import { ProgramManagementComponent } from '../services/program-management/program-management.component';
import { UiUxComponent } from '../services/ui-ux/ui-ux.component';

const serviceRoutes: Routes = [
  { path: "services", component: ServicesComponent },
  { path: "services/analytics", component: AnalyticsComponent },
  { path: "services/big-data", component: BigDataComponent },
  { path: "services/iot", component: IotComponent },
  { path: "services/cloud", component: CloudComponent },
  { path: "services/business-intelligence", component: BusinessIntelligenceComponent },
  { path: "services/artificial-intelligence", component: ArtificialIntelligenceComponent },
  { path: "services/blockchain", component: BlockchainComponent },
  { path: "services/application-development", component: ApplicationDevelopmentComponent },
  { path: "services/mobility", component: MobilityComponent },
  { path: "services/machine-learning", component: MachineLearningComponent },
  { path: "services/program-management", component: ProgramManagementComponent },
  { path: "services/devops", component: DevopsComponent },
  { path: "services/digital", component: DigitalComponent },
  { path: "services/cyber-security", component: CyberSecurityComponent },
  { path: "services/ui-ux", component: UiUxComponent },
  { path: "services/data-strategy", component: DataStrategyComponent },
  { path: "services/data-warehouse", component: DataWarehouseComponent },
  { path: "services/data-governance", component: DataGovernanceComponent },
  { path: "services/master-data-management", component: MasterDataManagementComponent },
  { path: "services/data-visualization", component: DataVisualizationComponent },
  { path: "services/data-science", component: DataScienceComponent },
  { path: "services/data-integration", component: DataIntegrationComponent },
  { path: "services/microsoft-azure", component: MicrosoftAzureComponent },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(serviceRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ServicesRoutingModule { }
