<light-layout>
    <div class="__page-root">
        <hero name="Sabra" kicker="Excel to .Net Conversion">
            <div class="cover-image hero-background tinted"></div>
            <back-to-button></back-to-button>
        </hero>
        <div class="sections">
          <app-summary color="#426EFF" content="Data Concepts client is a leading snacks manufacturer most famous for their hummus and ethical business model.">
              <role [style.width.%]="100">
                  <li>Asp.Net & C#</li>
                  <li>Visual Studio 2015</li>
                  <li>SQL Server 2008 R2</li>
              </role>
          </app-summary>
          <challenge color="#426EFF" content="Our client needed to convert their Physical Chem Excel application written in a VBA code into a .Net application with a SQL Server database."></challenge>
          <solution-header content="legacy modernization and re-architecture"></solution-header>
          <solution>
              <p>Data Concepts delivered a fully-tested, redesigned architecture blueprint and application system to host historical and future data.</p>
              <ul class="pseudo">
                <li>- Requirements Elicitation.</li>
                <li>- Requirements Documentation.</li>
                <li>- Analysis & Design.</li>
                <li>- Code Architecture Setup.</li>
                <li>- Database Design.</li>
                <li>- Security Implementation & Network Authentication.</li>
                <li>- Development of Admin Screens.</li>
                <li>- Development of User Screens.</li>
                <li>- Deployment to QA and Prod.</li>
              </ul>
          </solution>
          <outcome color="#426EFF" content="Our client was able to leave their less-functional and cumbersome Spreadsheet behind and harness the power of a SQL server for their Physical Chem Data Capture, increasing the usefulness of their data."></outcome>
        </div>
    </div>
</light-layout>
