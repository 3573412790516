import { Mouse } from './mouse';
export class Particle {
  x: number;
  y: number;
  radius: number;
  color: string;
  radians: number;
  velocity: number;
  distanceFromCenter: number;
  lastMouse: Mouse = new Mouse();
  ctx: CanvasRenderingContext2D;
  constructor(x: number, y: number, color: string, canvasWidth: number, ctx: CanvasRenderingContext2D) {
    this.ctx = ctx;
    this.x = x;
    this.y = y;
    this.radius = this.randomIntFromRange(1, 2);
    this.color = color;
    this.radians = Math.random() * Math.PI * 2;
    this.velocity = this.randomIntFromRange(2, 3) / 1000;
    this.distanceFromCenter = this.randomIntFromRange(canvasWidth / 8 + 800, canvasWidth / 8 - 100);
    this.lastMouse = { x: x, y: y };
  }
  randomIntFromRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  draw() {
    this.ctx.beginPath();
    this.ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
    this.ctx.fillStyle = this.color;
    this.ctx.fill();
    // removed the following because these settings cause firefox and IE to lose performace
    //this.ctx.shadowColor = "#A07A2E";
    //this.ctx.shadowBlur = 5;
    //this.ctx.closePath();
  }
  update(mouse: Mouse) {
    this.lastMouse.x += (mouse.x - this.lastMouse.x) * 0.03;
    this.lastMouse.y += (mouse.y - this.lastMouse.y) * 0.03;
    this.radians += this.velocity;
    this.x = this.lastMouse.x + Math.cos(this.radians) * this.distanceFromCenter * 2;
    this.y = this.lastMouse.y + Math.sin(this.radians) * this.distanceFromCenter;
    this.draw();
  }
}
