import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit, OnDestroy {
  
  myInterval: any;

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
    this.myInterval = setInterval(this.showTime.bind(this), 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.myInterval);
  }

  showTime() {
    /*
     *  Main function to set the clock times
     */
    const d = new Date();

    let sfoTime, atlTime, dnvTime;
    let seconds;
    let sfoH, sfoM;
    let atlH, atlM;
    let dnvH, dnvM;

    sfoTime = this.calcTime(-8);
    dnvTime = this.calcTime(-7);
    atlTime = this.calcTime(-5);

    //If DST
    if (this.isDstObserved(d)) {
      sfoTime = this.calcTime(-7);
      dnvTime = this.calcTime(-6);
      atlTime = this.calcTime(-4);
    }

    seconds = sfoTime.getSeconds();
    sfoH = sfoTime.getHours();
    sfoM = sfoTime.getMinutes();
    atlH = atlTime.getHours();
    atlM = atlTime.getMinutes();
    dnvH = dnvTime.getHours();
    dnvM = dnvTime.getMinutes();

    //Convert to UI

    //Universal Seconds
    const secondsDeg = (seconds / 60) * 360;
    const sfoSeconds = document.querySelector("#sfo-clock .seconds");
    const atlSeconds = document.querySelectorAll("#atl-clock .seconds");
    const dnvSeconds = document.querySelector("#dnv-clock .seconds");
    const sfoHours = document.querySelector("#sfo-clock .hours");
    const atlHours = document.querySelectorAll("#atl-clock .hours");
    const dnvHours = document.querySelector("#dnv-clock .hours");
    const sfoMinutes = document.querySelector("#sfo-clock .minutes");
    const atlMinutes = document.querySelectorAll("#atl-clock .minutes");
    const dnvMinutes = document.querySelector("#dnv-clock .minutes");

    sfoSeconds.setAttribute(
      "style",
      "transform: rotate(" + secondsDeg + "deg)"
    );

    atlSeconds.forEach(el => {
      el.setAttribute("style", "transform: rotate(" + secondsDeg + "deg)");
    });

    dnvSeconds.setAttribute(
      "style",
      "transform: rotate(" + secondsDeg + "deg)"
    );

    //SFO
    if (sfoH > 12) {
      sfoH = sfoH - 12;
    }
    const sfoHDeg = (sfoH / 12) * 360;
    const sfoHIncrement = (sfoM / 60) * 30;
    const sfoFinalDeg = sfoHDeg + sfoHIncrement;

    sfoHours.setAttribute(
      "style",
      "transform: rotate(" + sfoFinalDeg + "deg)"
    );

    const sfoMDeg = (sfoM / 60) * 360;

    sfoMinutes.setAttribute("style", "transform: rotate(" + sfoMDeg + "deg)");

    //ATL & IND
    if (atlH > 12) {
      atlH = atlH - 12;
    }
    const atlHDeg = (atlH / 12) * 360;
    const atlHIncrement = (atlM / 60) * 30;
    const atlFinalDeg = atlHDeg + atlHIncrement;

    atlHours.forEach(el => {
      el.setAttribute("style", "transform: rotate(" + atlFinalDeg + "deg)");
    });

    const atlMDeg = (atlM / 60) * 360;

    atlMinutes.forEach(el => {
      el.setAttribute("style", "transform: rotate(" + atlMDeg + "deg)");
    });

    //dnv
    if (dnvH > 12) {
      dnvH = dnvH - 12;
    }
    const dnvHDeg = (dnvH / 12) * 360;
    const dnvHIncrement = (dnvM / 60) * 30;
    const dnvFinalDeg = dnvHDeg + dnvHIncrement;

    dnvHours.setAttribute(
      "style",
      "transform: rotate(" + dnvFinalDeg + "deg)"
    );

    const dnvMDeg = (dnvM / 60) * 360;

    dnvMinutes.setAttribute("style", "transform: rotate(" + dnvMDeg + "deg)");
  }

  isDstObserved(d: Date): boolean{
    return d.getTimezoneOffset() < this.stdTimezoneOffset(d);
  }

  stdTimezoneOffset(d: Date): number {
    const jan = new Date(d.getFullYear(), 0, 1);
    const jul = new Date(d.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  };

  calcTime(offset): Date {
    const d = new Date();
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    const nd = new Date(utc + 3600000 * offset);
    return nd;
  }

}
