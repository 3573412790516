<light-layout>
    <div class="__page-root">
        <hero name="Data Governance" kicker="We help you Harness the Power of Data.">
            <back-to-services></back-to-services>
            <div class="cover-image hero-background tinted"></div>
        </hero>
        <div class="sections">
            <promise subtitle="the promise"
                content="Data governance is the ability of an organization to manage its information knowledge and to answer questions such as What do we know about our information? Where did this data come from?, and Does this data adhere to company policies and rules? Data governance practices provide a holistic approach to managing, improving and leveraging information to help you gain insight and build confidence in business decisions and operations. Our data governance solutions let you locate and retrieve information about data objects, their meaning, physical location, characteristics, and usage. This helps improve IT productivity while meeting regulatory requirements.">
            </promise>
            <services-ctrl subtitle="services" black="true">
                <sub-service class="subservice" name="Flexible Data Governance Strategy"
                    content="With multiple entry points, adopt a data governance strategy to suit your organizational objectives while shaping business information in unique ways as per your needs.">
                </sub-service>
                <sub-service class="subservice" name="Archive and Streamline Applications"
                    content="By consolidating applications, retiring outdated databases and modernizing existing systems, focus on important projects, thus reducing risk and enhancing application quality.">
                </sub-service>
                <sub-service class="subservice" name="Confidence in Big Data Analytics"
                    content="Obtain clean, consolidated, consistent and current information. Use this information to drive big data projects and applications while aligning with governance objectives.">
                </sub-service>
                <sub-service class="subservice" name="Privacy and Protection"
                    content="Identify data with meaning, assess data value and risk, secure PII, PCI, and PHI, and get ready for GDPR.">
                </sub-service>
            </services-ctrl>
            <srv-pamphlet isLightGreyBG="true">
                <verso>
                    <h1>Implementing a Data Governance Initiative</h1>
                    <p>Data governance is not a big bang initiative. Instead, global initiatives are highly complex and
                        long-term
                        projects. They therefore run the risk that participants might lose trust and interest over time.
                        <br>
                        <br>It is therefore recommended to start with a manageable or application-specific prototype
                        project and to
                        continue iteratively. In this way, the project remains manageable and experience can be used for
                        more
                        complex projects or to expand the data governance programs in the company. Here’s how we can
                        help:
                    </p>
                </verso>
                <recto>
                    <p>Define goals and understand benefits
                        <br>Analyze current state and delta analysis
                        <br>Derive a roadmap
                        <br>Convince stakeholders and budget project
                        <br>Develop and plan the data governance program
                        <br>Implement the data governance program
                        <br>Monitor and control
                    </p>
                </recto>
            </srv-pamphlet>
            <insight-highlight name="What is Data Governance?" link="/insights/data-governance">
                <div class="insight-highlight-background abstract-7-highlight"></div>
            </insight-highlight>
            <related>
                <insight-container class="insight" data-aos="fade-up" uri="/insights/data-governance-journey"
                    category="Data Governance" name="Data Governance is a Journey, not a Destination"
                    summary="Governance is the ongoing process of creating and managing processes, policies, and information. This includes strategies, processes, activities, skills, organizations, and technologies for the purpose of accelerating business outcomes.">
                    <span class="insight-background lights-2"></span>
                </insight-container>
                <insight-container class="insight" data-aos="fade-up" uri="/insights/governance-path"
                    category="Data Governance" name="Let's Put You on the Path to Data Governance Excellence"
                    summary="In the rush to get exciting, advanced analytics projects off the ground, don't overlook the importance of data governance. Data governance practices can sink any analytics initiative before it really has a chance to take shape.">
                    <span class="insight-background capital-one"></span>
                </insight-container>
                <insight-container class="insight" data-aos="fade-up" uri="/insights/data-analytics-change"
                    category="Data & Analytics" name="Data & Analytics Governance: Change is Happening, are You Ready?"
                    summary="Governance is the ongoing process of creating and managing processes, policies, and information. This includes strategies, processes, activities, skills, organizations, and technologies for the purpose of accelerating business outcomes.">
                    <span class="insight-background light-bulbs"></span>
                </insight-container>
            </related>
            <subscribe></subscribe>
            <hire-us-footer></hire-us-footer>
        </div>
    </div>
</light-layout>
