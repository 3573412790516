import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'back-to-services',
  templateUrl: './back-to-services.component.html',
  styleUrls: ['./back-to-services.component.sass']
})
export class BackToServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
