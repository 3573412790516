<section [ngClass]="[isBlackBG ? 'padded-large black text-white' : 'padded-large']">
    <div class="container">
        <div class="row">
            <div class="column col-4 responsive">
                <div class="content-wrapper">
                    <h6 class="{{'subtitle ' + white}}">
                        <span>{{subtitle}}</span>
                    </h6>
                </div>
            </div>
      </div>
      <ng-content></ng-content>
    </div>
</section>