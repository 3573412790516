import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'services-ctrl',
  templateUrl: './services-ctrl.component.html',
  styleUrls: ['./services-ctrl.component.sass']
})
export class ServicesCtrlComponent implements OnInit {
  @Input() subtitle: string;
  @Input() black: boolean;
  @Input() small: boolean;
  
  constructor() { }

  ngOnInit() {
  }

}
