<section [ngClass]="[black && small ? 'black padded-small' : black ? 'black padded-large' : small ? 'padded-small' : 'padded-large']">
    <div class="container">
      <div class="row">
        <div class="column col-4 responsive">
          <div [ngClass]="[black ? 'content-wrapper text-white' : 'content-wrapper']">
            <h6 [ngClass]="[black ? 'subtitle white' : 'subtitle']">
              <span>{{subtitle}}</span>
            </h6>
          </div>
        </div>
        <div class="column col-12">
          <div data-aos="fade-up" [ngClass]="[black ? 'content-wrapper services-wrapper text-white' : 'content-wrapper services-wrapper']">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </section>
