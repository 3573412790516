<section class="light-grey padded-large">
    <div class="container">
      <div class="row">
        <div class="column col-4">
          <div class="content-wrapper">
            <h6 class="subtitle">
              <span>{{subtitle}}</span>
            </h6>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column special col-12">
          <div class="special">
            <h1 class="additional-header">{{name}}</h1>
            <div class="content-wrapper additional-wrapper">
              <div class="text-wrapper">
                <p class="additional-text">{{paragraphOne}}</p>
              </div>
              <div *ngIf="paragraphTwo" class="text-wrapper">
                <p class="additional-text">{{paragraphTwo}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="column col-12">
          <div class="content-wrapper services-wrapper text-black">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </section>