import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-azure-machine-learning',
  templateUrl: './azure-machine-learning.component.html',
  styleUrls: ['./azure-machine-learning.component.sass']
})
export class AzureMachineLearningComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
