import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  private sessionUserKey: string = 'sessionUser';
  loginForm = this.fb.group({
    username: ["", Validators.required],
    password: ["", Validators.required]
  });
  returnUrl: string;
  loading = false;
  submitted = false;
  error = '';

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService) {

    // redirect to home if already logged in
    if (sessionStorage.getItem("token")) {
      this.router.navigate(['admin', 'careers']);
    }
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmit(event: any): void {
    event.preventDefault();
    this.error = "";
    if (this.loginForm.valid) {
      this.submitLogin();
    }
  }

  submitLogin() {
    this.loading = true;
    this.authService.login(this.loginForm.get('username').value, this.loginForm.get('password').value)
      .pipe(first())
      .subscribe(res => {
        const user = res.body;
        if (user && user.token) {
          sessionStorage.setItem(this.sessionUserKey, JSON.stringify(user));
          this.router.navigateByUrl(this.returnUrl).catch(reason => { console.log('Error: ' + reason) });
        }
      },
        error => {
          sessionStorage.removeItem(this.sessionUserKey);
          this.loading = false;
          this.error = "Login failed!";
        }
    );
  }
}
