import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.sass']
})
export class SolutionComponent implements OnInit {
  @Input() content: string;
  constructor() { }

  ngOnInit() {
  }

}
