import { Component, OnInit } from '@angular/core';
import { Particle } from '../../../_models/particle';
import { Mouse } from '../../../_models/mouse';

@Component({
  selector: 'home-hero',
  templateUrl: './home-hero.component.html',
  styleUrls: ['./home-hero.component.sass']
})
export class HomeHeroComponent implements OnInit {
  particles: Particle[];
  mouse: Mouse = new Mouse();
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  colors = ["#F8F2F4", "#EAF2F4", "#FFF0FF", "#FFFEF2"];
  constructor() { }

  ngOnInit() {
    this.canvas = document.querySelector("canvas");
    this.ctx = this.canvas.getContext("2d");

    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    this.mouse.x = window.innerWidth / 2;
    this.mouse.y = window.innerHeight / 2;

    const trackResize = () => {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
    };

    // Event Listeners
    document.getElementById("canvas-wrapper").addEventListener("mousemove", this.trackMouse.bind(this));
    addEventListener("resize", trackResize.bind(this));

    this.init();
  }

  trackMouse(event: any) {
    this.mouse.x = event.clientX;
    this.mouse.y = event.clientY;
  }

  randomColor(colors): string {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  init() {
    this.particles = [];
    for (let i = 0; i < 500; i++) {
      this.particles.push(
        new Particle(
          this.canvas.width / 2,
          this.canvas.height / 2,
          this.randomColor(this.colors),
          this.canvas.width,
          this.ctx
        )
      );
    }
    this.animateParticles();
  }

  // Animation Loop
  animateParticles() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.particles.forEach(particle => {
      particle.update(this.mouse);
    });

    requestAnimationFrame(this.animateParticles.bind(this));
  }
}
