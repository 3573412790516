import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import { CareerService } from '../../../../_services/career.service';
import { JobLocation } from '../../../../_models/job-location';
import { Job } from '../../../../_models/job';

@Component({
  selector: 'app-add-job',
  templateUrl: './add-job.component.html',
  styleUrls: ['./add-job.component.sass']
})
export class AddJobComponent implements OnInit {
  jobForm = this.fb.group({
    title: ["", Validators.required],
    htmlContent: ["", Validators.required]
  });
  jobLocation: JobLocation;
  job: Job;
  ckConfig = {
    height: 500,
    toolbar: [
      ['Format', '-', 'Bold', '-', 'BulletedList', 'NumberedList', '-', 'Indent', 'Outdent', '-', 'Undo', 'Redo', '-', 'Source']
    ],
    format_tags: 'p;h4;div',
    format_div: {
      element: 'div',
      attributes: {
        'class': 'jobs-spacer'
      }
    }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private careerService: CareerService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.getJobLocation();
  }

  onSubmit(event: any) {
    event.preventDefault();
    if (this.jobForm.valid) {
      this.addJob();
    }
  }

  updateForm() {
    this.jobForm.get("title").setValue(this.job.title);
    this.jobForm.get("htmlContent").setValue(this.job.htmlContent);
  }

  addJob() {
    this.job = new Job();
    this.job.title = this.jobForm.get("title").value;
    this.job.htmlContent = this.jobForm.get("htmlContent").value;
    this.job.jobLocationId = this.jobLocation.id;

    this.careerService.addJob(this.job)
      .subscribe(job => {
        this.job = job;
        this.router.navigate(['admin', 'careers', this.jobLocation.id.toString()]);
      });
  }

  getJobLocation() {
    const id = + this.route.snapshot.paramMap.get('id');

    this.careerService.getJobLocationByID(id)
      .subscribe(jobLocation => this.jobLocation = jobLocation);
  }

  cancelAction() {
    this.router.navigate(['admin', 'careers', this.jobLocation.id.toString()]);
  }
}
