<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Master Data Management" name="Inconsistent Duplicated Data - Calling MDM!!"
                summary="In the enterprise there are several systems managing the same data; the role of MDM is to centralize the data management to only one master copy of the data item which is then synchronized to all applications using the data.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
    
            <insight-pamphlet>
                <insight-sub-section class="subsection subsection-padding" name="What is master data management?"
                    content="Master data management (MDM) is a comprehensive method to define and manage an organization’s critical data. It provides a single, trusted view of data across the enterprise, agile self-service access, analytical graph-based exploration, governance and a user-friendly dashboard.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Master Data Management"
                    content="Master Data Management (MDM) represents a set of tools and processes used by an enterprise to consistently manage their non-transactional data. MDM is usually applied to entities like Customer, Client, Product, Account and internal reference data. MDM also enables easy maintenance of data lineage and history for audit purposes.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Issues"
                    content="In the enterprise, there are several systems managing the same data; the role of MDM is to centralize the data management to one master copy of the data item, which is then synchronized to all applications using the data. Using this approach, when referring to (for example) a customer within the enterprise, all systems are referring to the same customer. Here are three reasons that might explain the presence of duplicated, likely inconsistent data within an enterprise:">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="1."
                    content="The production systems within an enterprise, when implemented, have not been designed to be a part of larger set of production systems with which they should cooperate. Therefore, each system manages data on its own.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="2."
                    content="The branches or departments of the company exist on their own without close cooperation with other departments. For example, the mortgage department deals with customers and manages the mortgage contracts, while the marketing department plans a promotion on mortgages. If the two departments do not cooperate (share the data), the marketing department may offer a mortgage to a customer who already has a mortgage. This is both a waste of money on the promotion and annoying to the customer.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="3."
                    content="Company acquisitions or mergers can result in several parallel systems managing similar and sometimes overlapping data.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Solutions"
                    content="To handle the issues mentioned above, the common roadmap for Master Data Management solutions comprises the following processes:">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="1."
                    content="The 'system of record' needs to be identified first. If the same record is stored in multiple systems, the system which holds the most relevant copy (most valid, actual, or complete) of that record is referred to as a 'system of record'.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="2."
                    content="Data collection - the data needs to be collected from various sources, as some sources may attach a new piece of information, while dropping pieces which they are not interested in.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="3."
                    content="Transformation - the transformation step takes place both during the input, while data are converted into a format for MDM processing, as well as on the output, when distributing the master records back to the particular systems and applications.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="4."
                    content="Data consolidation - the records from various systems which represent the same physical entity are consolidated into one record - a master record. The record is assigned a version number to enable a mechanism to check which version of record is being used in particular systems.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="5."
                    content="Data deduplication - often there are separate records in the company's systems which identify the same customer. For example, the bank may have a record identifying a customer, while the bank's insurance subsidiary or department maintains a separate database of customers and has a different record for the same customer. It is vital that these two records are deduplicated and maintained as one master record.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="6."
                    content="Error detection - based on the rules and metrics, the incomplete records, or records containing inconsistent data, should be identified and sent to their respective owners before publishing them to all the other applications. Providing erroneous data may compromise credibility of the company's MDM.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="7."
                    content="Data correction - related to error detection, this step notifies the owner of the data record that there is a need to review the record manually.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="8."
                    content="Data distribution/synchronization - the master records are distributed to the systems in the enterprise. The goal is that all the systems are using the same version of the record as soon as possible after the publication of the new record.">
                </insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
