<link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap" rel="stylesheet">
<section class="modal-menu light-black videoContainer">
<video muted autoplay loop>
<source src="../../assets/video/menu_bg_no_audio.webm" id="menu_video" type="video/webm">
</video>
  <div class="content">
    <div class="">
      <div class="column col-md-12">
        <div class="copy-wrapper nav-wrapper">
          <ul class="mobile-nav-menu text-white">
            <li>
              <a class="page-link" routerLink="/work">Work</a>
            </li>
            <li class="margined">
              <a class="page-link" routerLink="/services">Services </a>
            </li>
            <li class="margined">
              <a class="page-link" routerLink="/insights">Tech Hub</a>
            </li>
            <li class="margined">
              <a class="page-link" routerLink="/about">About</a>
            </li>
            <li class="margined">
              <a class="page-link" routerLink="/culture">Culture</a>
            </li>
            <li class="margined">
              <a class="page-link" routerLink="/community-service">Giving </a>
            </li>
            <li class="margined">
              <a class="page-link" routerLink="/careers">Jobs </a>
            </li>
            <li class="margined">
              <a class="page-link" routerLink="/contact">Hello </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
