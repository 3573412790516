import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'insight-highlight',
  templateUrl: './insight-highlight.component.html',
  styleUrls: ['./insight-highlight.component.sass']
})
export class InsightHighlightComponent implements OnInit {
  @Input() name: string;
  @Input() link: string;
  
  constructor() { }

  ngOnInit() {
  }

}
