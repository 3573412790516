import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { CareerService } from '../../../_services/career.service';
import { JobLocation } from '../../../_models/job-location';

@Component({
  selector: 'app-manage-locations',
  templateUrl: './manage-locations.component.html',
  styleUrls: ['./manage-locations.component.sass']
})
export class ManageLocationsComponent implements OnInit {
  jobLocations: JobLocation[];
  error: string;

  constructor(private careerService: CareerService, private location: Location) { }

  ngOnInit() {
    this.getJobLocations();
  }

  getJobLocations() {
    this.careerService.getJobLocations()
      .subscribe(locations => {
        this.jobLocations = locations;
      });
  }

  deleteLocation(id: number) {
    let jobLocation = this.jobLocations.filter(location => location.id == id)[0];
    if (confirm(`You are about to delete ${jobLocation.city}, ${jobLocation.state}`)) {
      this.careerService.deleteJobLocation(id)
        .subscribe(() => {
          this.getJobLocations();
        });
    }
  }

  goBack() {
    this.location.back();
  }
}

