<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Agile" name="Agile Transformation: Understanding What it Means to be Agile"
                summary="Agile, as it relates to project management for software development, is sometimes used as synonym for Scrum, Kanban or other type of framework.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
            <insight-pamphlet>
                <insight-sub-section class="subsection subsection-padding" name="Agile Definition"
                    content="Agile, loosely defined, is the “ability to move and think quickly and easily” or “to move in a quick, coordinated fashion.  This is an incorrect and misleading way to describe what it means to be Agile in terms of technology. Agile is a set of principles and values listed formally in the Agile Manifesto, established in 2001. In the world of software development, one of the main goals of adopting an Agile approach is to produce quality, working software in short, fast increments – this is also known as continuous delivery. This often requires that teams are able to accept and implement fast, changing requirements.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="The four main values are:"
                    content="">
                    <p>- Individuals and interactions over processes and tools.</p>
                    <p>- Working software over comprehensive documentation.</p>
                    <p>- Customer collaboration over contract negotiation.</p>
                    <p>- Responding to change over following a plan.</p>
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="In order to provide additional guidance, twelve principles were created to support the four main values and are also listed as part of the Agile Manifesto.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="The authors of the Agile Manifesto did not provide a prescription or a formula on how to become Agile. The Manifesto does not mention Scrum or any other variety of processes or methodologies. Instead, it describes a set of values written as simple statements, but which are challenging for most organizations to understand and embrace.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Many people have the misperception that an organization can’t be Agile if they exercise a Waterfall methodology. In fact, only one of the Agile Manifesto value statements mentions software and none mention a methodology. It is also incorrectly assumed that Agile is a methodology.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="Agile is best described as an umbrella of values and principles, and under it are many different sets of frameworks that help an organization achieve agility. Agility is the goal, while Scrum, Lean, Kanban or other type of framework is the “how”. Agile transformation does not mean subscribing to a defined methodology.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="Agile Transformation Definition"
                    content="The Agile transformation definition is as an act of gradually transforming an organization’s form or nature to one that is able to embrace and thrive in a flexible, collaborative, self-organizing, fast changing environment. The Agile Manifesto values and principles can be taught and exercised throughout any type of organization – they are not limited to development teams!">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="The entire organization needs to understand the definition of an Agile transformation and the value of it in order to benefit from the rewards of achieving true, healthy agility. The complete cultural and organizational mind set must change to one that embraces a culture of self-organization and collaboration.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Agile Adoption vs Agile Transformation"
                    content="Agile adoption and Agile transformation terms are often incorrectly used interchangeably. However, there are some major differentiation between adoption and transformation.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="Agile Adoption"
                    content="Agile adoption is “doing” agile. “Adoption” is the act of taking up or to putting something into effect. A common method is to adopt Scrum, a popular framework, because it prescribes a specific set of meetings (ceremonies) and example agendas of what is to take places in those ceremonies. It is often easier to implement an organizational change to something that resembles a “process” because “processes” are what feel most comfortable and familiar.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="Organizations often fail at Agile transformation due to the assumption that because they are “doing” they have “become”. It is premature to “adopt” a practice before understanding “why” the practice was created. For example, the first Agile value states “Individuals and interactions are valued more than processes and tools”. The simple act of having a face-to-face discussion versus using sending an email or placing a call is an example of adopting an action or practice that reflects an Agile value. This is a very simply policy that can be adhered to, but in order for an organization to continue towards becoming a successful Agile organization, they need to understand the “why” in order to help create the “how”.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="Agile Transformation"
                    content="Agile transformation is “being, becoming or changing character or condition to one of agility”. This is much more difficult to achieve. It involves a mind-set change across all people in an organization, which can be uncomfortable for them.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="Most organizations recognize the benefits of Agile, but aren’t sure how to create or encourage Agile “behavior” or when to introduce frameworks and practices. Introducing frameworks and practices too early can be counter-productive when it shifts the focus away from “becoming” to learning new processes.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="What is Agile Lean Transformation?"
                    content="Agile Lean transformation is transforming to agility while implementing and recognizing Lean practices and values. Lean, originally intended for manufacturing, was later recognized as applicable and appropriate for software development. The main goal of Lean is to maximize customer value while minimizing waste.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    name="Lean has 7 key principles (sometimes referred to as “practices”) that apply to software development:">
                    <p>- Waste Elimination.</p>
                    <p>- Amplify Learning.</p>
                    <p>- Late Decision Making.</p>
                    <p>- Fast Delivery.</p>
                    <p>- Team Empowerment.</p>
                    <p>- Built-In Integrity.</p>
                    <p>- View Applications as a Whole.</p>
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Agile values and principles support rapid creation of working-quality software. In order to achieve the best benefit, the software should have some value to the end user. Eliminating waste (activities that are not providing value, such as defects, non-productive meetings, waiting, inventory, etc.) naturally allows time to be spent in areas of value. The result is the ability to rapidly release working-quality software that a customer will value. By using a Lean practice, you achieve a goal of Agile hence, Agile Lean transformation should be considered a healthy marriage of Lean and Agile principles and values. Combining Lean and Agile helps to guide an organization into Agility and Lean, thinking faster without prescribing a specific set of actions (or processes).">
                </insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
