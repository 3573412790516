import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circuit-courts',
  templateUrl: './circuit-courts.component.html',
  styleUrls: ['./circuit-courts.component.sass']
})
export class CircuitCourtsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
