<div class="__layout-root">
  <admin-modal-menu></admin-modal-menu>
  <header>
    <div class="container">
      <nav data-aos="fade-down" data-aos-duration="500">
        <a class="logo" [ngClass]="{ 'active': showMobileMenu }" [routerLink]="['/admin']">dataconcepts admin</a>
        <div class="menu-wrap">
          <ul class="menu pseudo">
            <li class="menu-item">
              <a class="nav-link" routerLink="/admin/careers">Careers</a>
            </li>
            <li class="menu-item">
              <a class="nav-link" href="#" (click)="logout($event)">Logout</a>
            </li>
          </ul>
          <a class="target-burger"
             (click)="(showMobileMenu ? hideMenu() : showMenu())">
            <ul class="buns pseudo">
              <li class="bun" [ngClass]="{ 'active': showMobileMenu }"></li>
              <li class="bun" [ngClass]="{ 'active': showMobileMenu }"></li>
            </ul>
          </a>
        </div>
      </nav>
    </div>
  </header>
  <div>
    <ng-content></ng-content>
  </div>
</div>
