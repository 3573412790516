import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { CareerService } from '../../../_services/career.service';
import { Job } from '../../../_models/job';
import { JobLocation } from '../../../_models/job-location';


@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.sass']
})
export class JobComponent implements OnInit {
  jobForm = this.fb.group({
    title: ["", Validators.required],
    htmlContent: ["", Validators.required]
  });
  job: Job;
  jobLocation: JobLocation;
  ckConfig = {
    height: 500,
    toolbar: [
      [ 'Format', '-', 'Bold', '-', 'BulletedList', 'NumberedList', '-', 'Indent', 'Outdent', '-', 'Undo', 'Redo', '-', 'Source']
    ],
    format_tags: 'p;h4;div',
    format_div: {
      element: 'div',
      attributes: {
        'class': 'jobs-spacer'
      }
    }
  };

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private careerService: CareerService,
    private fb: FormBuilder) {
  }

  ngOnInit() {
    this.getJob();
  }

  onSubmit(event: any) {
    event.preventDefault();
    if (this.job.title != "" && this.job.htmlContent != "") {
      this.updateJob();
    }
    else {

    }
  }

  updateForm() {
    this.jobForm.get("title").setValue(this.job.title);
    this.jobForm.get("htmlContent").setValue(this.job.htmlContent);
  }

  updateJob() {

    this.job.title = this.jobForm.get("title").value;
    this.job.htmlContent = this.jobForm.get("htmlContent").value;

    this.careerService.updateJob(this.job)
      .subscribe(job => this.job = job);
  }

  getJob() {
    const id = + this.route.snapshot.paramMap.get('id');
    this.careerService.getJobByID(id)
      .subscribe(job => {
        this.job = job;
        this.getJobLocation(this.job.jobLocationId);
        this.updateForm();
      });
  }

  getJobLocation(id: number) {
    this.careerService.getJobLocationByID(id)
      .subscribe(jobLocation => this.jobLocation = jobLocation);
  }

  cancelAction() {
    this.router.navigate(['admin', 'careers', this.jobLocation.id.toString()]);
  }
}
