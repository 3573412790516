<section data-aos="fade-up" class="padded-large black">
    <div class="container">
        <div class="row padded">
            <div class="column col-12">
                <div class="content-wrapper">
                    <h1 class="challenge-header" [(style.color)]="color">CHALLENGE</h1>
                    <p class="text-white">{{content}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="connecting-line"></div>
</section>