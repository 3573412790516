<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Data Strategy" name="A Quick Peek at Our World: Helping Clients with Data Strategy"
                summary="Every business and organisation has its own unique set of goals, KPIs and targets, and we believe that everything that we do should be devised, planned and executed with these objectives in mind.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
            <insight-pamphlet>
                <insight-sub-section class="subsection" name="Data Architecture"
                    content="Every business and organization has its own unique set of goals, KPIs, and targets.  We believe that everything that we do should be devised, planned and executed with these objectives in mind. As such, we help our collaborators to formulate their own unique Data Strategy, so that all data-related processes, policies and decisions are focused on the main goals and direction of the business. These activities are spread across two solutions: Data Architecture and Data Modelling & Design, each of which can be applied depending on the unique needs of any potential collaborators.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="Our Data Architecture activities are just that: systems that allow for a data landscape to be defined and understood so that a well-designed and engineered strategy can be implemented across the business. A well-formulated data architecture helps organizations to quickly respond to market conditions. By evolving products and services in a dynamic, responsive way, firms can benefit both end-users and the bottom line.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Consultation"
                    content="Our consulting services help an organization to identify its data needs, which then allows us to create a comprehensive and joined-up architecture blueprint for the governance, quality and integration of data assets. Armed with a full understanding of your business, we bring any data investments into line with your wider business strategy, allowing for cohesion across your operation. Our approach is as follows:">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Assess"
                    content="We seek to understand your operations. Our team assesses the state and structure of current process, systems, models, policies and principles related to business data.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Analyze"
                    content="We look at what’s working and what isn’t. We identify and understand the gaps in processes, systems, models, policies and principles that are hindering or preventing the business from achieving its objectives.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Plan"
                    content="We share how things could be running better by producing a comprehensive, methodical blueprint and roadmap of how your people, process and technology can and should work.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Govern"
                    content="We communicate the defined architecture to teams that will implement the data architecture, and ensure information systems align with the new roadmap. We set up governance bodies to ensure changes to the architecture are managed, assured and communicated.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Data Modelling & Design"
                    content="Data is of no use if it can’t be understood, analyzed and have its insights acted upon. Our team has the expertise and understanding to model enterprise data assets at different levels of abstraction, from conceptual to physical data models. Modelling the structure of data assets – how it enters and flows through the organization, how it is accessed and managed, and the rules that govern its quality -  provides organizations with a common language to discuss data concerns and derive value from it.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Consultation"
                    content="We help our collaborators define the policies, standards and approach to model their data assets and associated metadata. We support businesses by helping them select and best utilize the ideal data modelling tools for their particular set of needs, wants and desired outcomes.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="Implementation"
                    content="We can help you create extensive enterprise data models (both conceptual and logical). Our team specializes in the analysis and modelling of data lineage. We’re able to document your metadata and ensure consistency and cohesion throughout.">
                </insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
