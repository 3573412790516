<light-layout>
    <div class="__page-root">
        <div class="sections">
            <section class="black">
                <div class="fullpage">
                    <div class="content-wrapper form-wrapper">
                        <form class="text-white" id="hire-us-form" [formGroup]="hireUsForm" (ngSubmit)="onSubmit($event)">
                            <label for="name">Hello, my name is</label>
                            <span class="input-wrapper">
                                <input formControlName="name" type="text" placeholder="first & last">
                            </span>
                            <span class="period">.</span>
                            <label for="address">I'm from</label>
                            <span class="input-wrapper">
                                <input formControlName="address" type="text" name="address" placeholder="city, st">
                            </span>
                            <span class="period">.</span>
                            <label for="phone">You can reach me on my phone at</label>
                            <span class="input-wrapper">
                                <input formControlName="phone" type="tel" placeholder="xxx xxx xxxx">
                            </span>
                            <label for="email">or via email at</label>
                            <span class="input-wrapper">
                                <input formControlName="email" type="text" placeholder="your@email.com">
                            </span>
                            <span class="period">.</span>
                            <div class="errors">
                                <div data-aos="fade-in" *ngIf="name.invalid && name.dirty" class="alert alert-danger">
                                    <div *ngIf="name.errors.required">
                                        Please enter your name.
                                    </div>
                                </div>
                                <div data-aos="fade-in" *ngIf="phone.invalid && phone.dirty" class="alert alert-danger">
                                    <div *ngIf="phone.errors.pattern">
                                        Please enter a valid phone number.
                                    </div>
                                </div>
                                <div data-aos="fade-in" *ngIf="email.invalid && email.dirty" class="alert alert-danger">
                                    <div *ngIf="email.errors.required">
                                        Please enter an email address.
                                    </div>
                                    <div *ngIf="email.errors.email">
                                        Please enter a valid email address.
                                    </div>
                                </div>
                            </div>
                            <div data-aos="fade-in" *ngIf="result" class="show-result">{{result}}</div>
                            <button class="box-button" type="submit">
                                <svg *ngIf="loading" class="lds-spinner" width="30px" height="30px" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"
                                    style="background: none;">
                                    <g transform="rotate(0 50 50)">
                                        <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9s"
                                                repeatCount="indefinite"></animate>
                                        </rect>
                                    </g>
                                    <g transform="rotate(36 50 50)">
                                        <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8s"
                                                repeatCount="indefinite"></animate>
                                        </rect>
                                    </g>
                                    <g transform="rotate(72 50 50)">
                                        <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.7s"
                                                repeatCount="indefinite"></animate>
                                        </rect>
                                    </g>
                                    <g transform="rotate(108 50 50)">
                                        <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6s"
                                                repeatCount="indefinite"></animate>
                                        </rect>
                                    </g>
                                    <g transform="rotate(144 50 50)">
                                        <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s"
                                                repeatCount="indefinite"></animate>
                                        </rect>
                                    </g>
                                    <g transform="rotate(180 50 50)">
                                        <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4s"
                                                repeatCount="indefinite"></animate>
                                        </rect>
                                    </g>
                                    <g transform="rotate(216 50 50)">
                                        <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3s"
                                                repeatCount="indefinite"></animate>
                                        </rect>
                                    </g>
                                    <g transform="rotate(252 50 50)">
                                        <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.2s"
                                                repeatCount="indefinite"></animate>
                                        </rect>
                                    </g>
                                    <g transform="rotate(288 50 50)">
                                        <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.1s"
                                                repeatCount="indefinite"></animate>
                                        </rect>
                                    </g>
                                    <g transform="rotate(324 50 50)">
                                        <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s"
                                                repeatCount="indefinite"></animate>
                                        </rect>
                                    </g>
                                </svg>
                                <svg *ngIf="!loading" class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    x="0px" y="0px" width="25px" height="26.005px" viewBox="286.5 382.498 39.002 26.005"
                                    enable-background="new 286.5 382.498 39.002 26.005" title="Next" style="position: absolute; top: 40%; left: 43%;">
                                    <g>
                                        <polygon fill="#000"
                                            points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                                        </polygon>
                                    </g>
                                </svg>
                            </button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </div>
</light-layout>
