<section class="padded-large">
    <div class="container">
      <div class="row">
        <div class="column col-4">
          <div class="content-wrapper">
            <h6 class="subtitle">
              <span>related</span>
            </h6>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column col-12">
          <div class="related-wrapper">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </section>