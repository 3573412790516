import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mdm-vs-dw',
  templateUrl: './mdm-vs-dw.component.html',
  styleUrls: ['./mdm-vs-dw.component.sass']
})
export class MdmVsDwComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
