import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.sass']
})
export class VideoComponent implements OnInit, AfterViewInit {
  @Input() videoID: string;
  @Input() videoSrc: string;
  @Input() posterSrc: string;
  @Input() bgColor: string;

  constructor() { }

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    const vid = document.getElementById(this.videoID) as HTMLVideoElement;
    vid.src = "../../../assets/video/" + this.videoSrc;
    vid.poster = this.posterSrc;
    if (this.bgColor != null && this.bgColor != '') {
      vid.style.backgroundColor = this.bgColor;
    }
  }
}
