<section class="padded-bottom">
    <div class="container">
        <div class="row">
            <div class="column col-4 responsive">
              <div class="content-wrapper">
                <h6 class="subtitle">
                  <span>apply</span>
                </h6>
                <ul *ngIf="jobAppForm.errors" class="pseudo errors-list">
                  <li data-aos="fade-in"
                      class="errors-list-item"
                      *ngFor="let error of jobAppForm.errors">{{ error }}</li>
                </ul>
              </div>
            </div>
            <div class="column col-8 responsive-8">
              <div class="content-wrapper">
                <form id="apply-form" [formGroup]="jobAppForm" (ngSubmit)="onSubmit($event)" enctype="multipart/form-data">
                  <input class="apply-input" formControlName="firstName" type="text" placeholder="First Name (required)" />
                  <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="alert alert-danger">
                    <div *ngIf="firstName.errors.required">
                      Please enter your first name.
                    </div>
                  </div>
                  <input class="apply-input" formControlName="lastName" type="text" placeholder="Last Name (required)" />
                  <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="alert alert-danger">
                    <div *ngIf="lastName.errors.required">
                      Please enter your last name.
                    </div>
                  </div>
                  <input class="apply-input" formControlName="emailAddress" type="email" placeholder="Email (required)" />
                  <div *ngIf="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched)" class="alert alert-danger">
                    <div *ngIf="emailAddress.errors.required">
                      Please enter your email address.
                    </div>
                    <div *ngIf="emailAddress.errors.email">
                      Please enter a valid email address.
                    </div>
                  </div>
                  <input class="apply-input" formControlName="phone" type="tel" placeholder="Phone" />
                  <div *ngIf="phone.invalid && phone.dirty" class="alert alert-danger">
                    <div *ngIf="phone.errors.pattern">
                      Please enter a valid phone number.
                    </div>
                  </div>
                  <span id="remove-file-button" *ngIf="uploadedFile" (click)="removeFile()">Remove File</span>
                  <label *ngIf="!uploadedFile" id="file-label" for="file">Add Resume/CV</label>
                  <span id="file-name" *ngIf="uploadedFile">{{uploadedFile.name}}</span>
                  <span id="required" *ngIf="!uploadedFile">(required)</span>
                  <input type="file" formControlName="file" id="file" name="file" accept=".pdf, .doc, .docx, .txt, .odf, .rtf" (change)="processFile($event)" />
                  <div *ngIf="file.invalid && file.dirty" class="alert alert-danger">
                    <div *ngIf="file.errors.required">
                      Please add your resume or CV.
                    </div>
                  </div>
                  <button type="submit" [disabled]="!jobAppForm.valid" class="box-button">
                    <span class="arrow-wrapper">
                      <svg *ngIf="loading" class="lds-spinner" width="30px" height="30px" xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"
                           style="background: none;">
                        <g transform="rotate(0 50 50)">
                          <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9s"
                                     repeatCount="indefinite"></animate>
                          </rect>
                        </g>
                        <g transform="rotate(36 50 50)">
                          <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8s"
                                     repeatCount="indefinite"></animate>
                          </rect>
                        </g>
                        <g transform="rotate(72 50 50)">
                          <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.7s"
                                     repeatCount="indefinite"></animate>
                          </rect>
                        </g>
                        <g transform="rotate(108 50 50)">
                          <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6s"
                                     repeatCount="indefinite"></animate>
                          </rect>
                        </g>
                        <g transform="rotate(144 50 50)">
                          <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s"
                                     repeatCount="indefinite"></animate>
                          </rect>
                        </g>
                        <g transform="rotate(180 50 50)">
                          <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4s"
                                     repeatCount="indefinite"></animate>
                          </rect>
                        </g>
                        <g transform="rotate(216 50 50)">
                          <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3s"
                                     repeatCount="indefinite"></animate>
                          </rect>
                        </g>
                        <g transform="rotate(252 50 50)">
                          <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.2s"
                                     repeatCount="indefinite"></animate>
                          </rect>
                        </g>
                        <g transform="rotate(288 50 50)">
                          <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.1s"
                                     repeatCount="indefinite"></animate>
                          </rect>
                        </g>
                        <g transform="rotate(324 50 50)">
                          <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s"
                                     repeatCount="indefinite"></animate>
                          </rect>
                        </g>
                      </svg>
                      <svg *ngIf="!loading" class="arrow-thin-right__svg" version="1.1" id="Layer_1"
                           xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25px" height="25px"
                           viewBox="286.5 382.498 39.002 26.005" enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#fff"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </span>
                  </button>
                </form>
                <div data-aos="fade-in" *ngIf="result" class="show-result">{{result}}</div>
              </div>
            </div>
        </div>
    </div>
</section>
