<div class="__page-root">
  <div class="admin-wrapper">
    <h1>Job Locations</h1>
    <button class="btn btn-light" type="button" (click)="goBack()">&lt;&lt; Go back</button>&nbsp;&nbsp;&nbsp;
    <a class="btn btn-light" routerLink="/admin/careers/location/add">Add New Location</a>
    <ul class="pseudo">
      <li class="admin-list" *ngFor='let jobLocation of jobLocations'>
        <button class="btn btn-danger" (click)="deleteLocation(jobLocation.id)" title="Remove">X</button>&nbsp;&nbsp;&nbsp;<a routerLink="/admin/careers/location/{{jobLocation.id}}">{{jobLocation.city}}, {{jobLocation.state}}</a>
      </li>
    </ul>
  </div>
</div>
