import { Component, OnInit } from "@angular/core";

import * as AOS from "aos";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.sass"]
})
export class AppComponent implements OnInit {
  title = "Data Concepts";

  constructor() {
 
  }

  ngOnInit() {
    AOS.init({
      mirror: false,
      duration: 400,
      once: false,
      easing: 'ease-in-out',
      anchorPlacement: 'top-bottom'
    });
  }
}
