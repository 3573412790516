import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-modal-menu',
  templateUrl: './admin-modal-menu.component.html',
  styleUrls: ['./admin-modal-menu.component.sass']
})
export class AdminModalMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
