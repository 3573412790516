<section class="subfooter">
        <div class="container">
          <div class="row">
            <div class="column col-12">
              <div class="content-wrapper subfooter-wrapper text-white">
                <small class="_3-sesR8aMq">© {{copy_right_year}} dataconcepts, Inc. All rights reserved.</small>
    
                <div class="social-link-wrapper">
                  <a class="social-link" href="#">
                    <svg
                      height="20px"
                      width="20px"
                      version="1.1"
                      viewBox="0 0 11 20"
                      class="_1F11R9wdcv"
                    >
                      <defs>
                        <polygon id="path-1" points="0 0.0001 10.998 0.0001 10.998 19.999 0 19.999"></polygon>
                      </defs>
                      <g id="Symbols" fill="none" stroke="none" stroke-width="1">
                        <g id="Footer" transform="translate(-1335.000000, -794.000000)">
                          <g id="social" transform="translate(1335.000000, 794.000000)">
                            <g id="facebook">
                              <mask id="mask-2" fill="white">
                                <polygon points="0 0.0001 10.998 0.0001 10.998 19.999 0 19.999"></polygon>
                              </mask>
                              <g id="Clip-2"></g>
                              <path
                                class="fill-1"
                                d="M9.0038,3.559 L10.9988,3.559 L10.9988,0.151 C10.6528,0.102 9.4688,-3.55271368e-15 8.0938,-3.55271368e-15 C5.2188,-3.55271368e-15 3.2518,1.776 3.2518,5.04 L3.2518,7.851 L-0.0002,7.851 L-0.0002,11.664 L3.2518,11.664 L3.2518,19.999 L7.1388,19.999 L7.1388,11.664 L10.3808,11.664 L10.8658,7.851 L7.1388,7.851 L7.1388,5.416 C7.1388,4.312 7.4418,3.559 9.0038,3.559"
                                fill="#676E73"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                  <a class="social-link" href="#">
                    <svg
                      height="22px"
                      width="22px"
                      version="1.1"
                      viewBox="0 0 21 16"
                      class="_1F11R9wdcv"
                    >
                      <defs></defs>
                      <g id="Symbols" fill="none" stroke="none" stroke-width="1">
                        <g id="Footer" fill="#676E73" transform="translate(-1380.000000, -796.000000)">
                          <g id="social" transform="translate(1335.000000, 794.000000)">
                            <g id="twitter" transform="translate(45.333333, 2.000000)">
                              <path
                                class="fill-1"
                                d="M14.151,-0.0002 C11.835,-0.0002 9.958,1.7948 9.958,4.0138 C9.958,4.3268 9.995,4.6348 10.067,4.9268 C6.581,4.7578 3.491,3.1628 1.423,0.7298 C1.062,1.3248 0.856,2.0148 0.856,2.7508 C0.856,4.1438 1.595,5.3728 2.721,6.0898 C2.033,6.0718 1.387,5.8898 0.821,5.5888 C0.821,5.6058 0.821,5.6228 0.821,5.6408 C0.821,7.5848 2.266,9.2068 4.185,9.5758 C3.833,9.6668 3.463,9.7168 3.08,9.7168 C2.81,9.7168 2.547,9.6908 2.291,9.6468 C2.825,11.2368 4.374,12.4028 6.209,12.4338 C4.773,13.5058 2.965,14.1528 1.001,14.1528 C0.662,14.1528 0.328,14.1308 0,14.0958 C1.856,15.2338 4.06,15.8958 6.428,15.8958 C14.141,15.8958 18.359,9.7798 18.359,4.4768 C18.359,4.3058 18.355,4.1298 18.347,3.9578 C19.167,3.3918 19.878,2.6878 20.44,1.8828 C19.688,2.1988 18.88,2.4148 18.032,2.5128 C18.897,2.0148 19.562,1.2298 19.875,0.2928 C19.065,0.7518 18.167,1.0858 17.213,1.2648 C16.447,0.4868 15.358,-0.0002 14.151,-0.0002"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                  <a class="social-link" href="#">
                    <svg
                      height="20px"
                      width="20px"
                      id="Layer_1"
                      style="enable-background:new 0 0 18 18"
                      version="1.1"
                      viewBox="0 0 18 18"
                      x="0px"
                      y="0px"
                      xml:space="preserve"
                      class="_1F11R9wdcv"
                    >
                      <path
                        class="fill-1"
                        d="M16,0c1.1,0,2,0.9,2,2v14c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2H16 M15.5,15.5v-5.3 c0-1.8-1.5-3.3-3.3-3.3c0,0,0,0,0,0c-0.8,0-1.8,0.5-2.3,1.3V7.1H7.1v8.4h2.8v-4.9c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4v4.9 H15.5 M3.9,5.6c0.9,0,1.7-0.8,1.7-1.7v0c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7l0,0C2.2,4.8,2.9,5.6,3.9,5.6 M5.3,15.5V7.1 H2.5v8.4H5.3z"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>