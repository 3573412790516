<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Data Strategy" name="Data Strategy is About Doing the Right Things"
                summary="Let’s discuss the process to guide your organization to lay out your organization-specific Data Strategy.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
            <insight-pamphlet>
                <insight-sub-section class="subsection subsection-padding" name="Data Strategy: The Process"
                    content="Developing a Data Strategy is a 4-step process; Data Concepts’ high-level framework for Data Strategy addresses the key elements of People, Processes, Technology, and Data.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Step 1: Planning and Discovery"
                    content="This step encompasses identifying business objectives and needs, enlisting sponsors and stakeholders, defining scope and schedule, and discovering technology and data assets that have a role in the Data Strategy. I’ll dig little more into business objective and stakeholder points, which are very crucial.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    name="Identify Business Objectives and Problems That Need to Be Solved with Data"
                    content="Data Strategy should align with business objectives and address key business problems / needs, as the primary purpose of a Data Strategy is to unlock valuable data businesses can leverage. One way to accomplish this is to align with the corporate strategic planning process, since most organizations have a strategic planning process anyway. Some of the examples of business objectives / business needs are: drive customer insights, improve efficiency of products and services, lower business risks, drive revenue growth and/or profitability, and regulatory compliance.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="In Developing a Data Strategy, there are Three Types of Stakeholders to Consider:"
                    content="Executive sponsor(s): The importance of finding and aligning with executive sponsor(s) that will support the development through the ups and downs of formulating and implementing the Data Strategy.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Talent: Working to influence and evangelize the people with right skills / talent to join your team will bolster success. Explore both internal talent as well as external consultants.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="Potential trouble makers: Every project / initiative will have some ‘stakeholders’ who either deliberately or unintentionally are opposed to change. Knowing who they are and their motivations upfront will help you later in the process.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="Step 2: Current State Assessment"
                    content="In this step, focus primarily on current business processes, data sources, data assets, technology assets, capabilities, and policies. The purpose of this exercise is to help with gap analysis of existing state and the desired future state. As an example, if the scope of the data strategy is to get a 360 view of customers and potential customers, the current state assessment would include any business process, data assets including architecture, capabilities (business & IT), and departmental policies that touch customers. Current state assessment is typically conducted with a series of interviews with employees involved in customer acquisition, retention, and processing.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="One important observation is that you’ll come across people in the organization who are natural data evangelists. These people truly believe in the power of data in making decisions and may already be using the data and analytics in a powerful way. Make note of these people, and be sure to ask for their help in later phases to drive a ‘data-driven’ culture in the organization.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="Step 3: Analysis, Prioritization, & Roadmap"
                    content="This phase is the most intense and contentious phase, and without a doubt will account for majority of the time spent in formulating a data strategy. With Big Data and Cloud computing, analysis has become increasingly complicated. With the desired future state in mind, analysis should focus on identifying gaps in data architecture, technology / tools, processes, and people (skills, training etc.). Big Data brings new data sources into the mix and Cloud computing enables new options for data integration and data storage.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="The gap analysis will present multiple strategic options for initiatives and the next task is to prioritize these options with business objectives / needs as the primary criteria. The sponsors and stakeholders will have a key role to play in prioritizing these initiatives. The end result of this phase is a roadmap to roll out the prioritized data initiatives. Without going into too many details, some of these data initiatives could be Data Governance, Data Quality, and Master Data Management (MDM).">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Step 4: Change Management"
                    content="Some people would argue that Change Management is not a distinct step, but some of the best Data Strategies have faced an untimely death precisely because of a lack of focus on change management. Change management should encompass organizational change, cultural change, technology change, and changes in business processes. Data Governance, which deals with overall management of availability, usability, integrity, and security of data becomes a crucial component of change management. Appropriate incentives and ongoing metrics should be a key part of any change management program.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="Data Strategy Components"
                    content="As a bonus, I’ve decided to include a section on various components in the final Data Strategy output. I believe that Data Strategy document should include all, or at least some, of these components:">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Background / Context: This section should articulate the background that necessitated the Data Strategy in the first place. Examples could be: Corporate strategic direction, Digital Transformation initiative, or mergers & acquisition related context etc.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Business Case: The sole purpose of Data Strategy is to unlock business value.  This section should articulate the value being unlocked both quantitatively and qualitatively. The business case is probably the toughest element to develop, but a necessary one.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Goals: This section identifies specific Data Strategy related goals and ideally in a SMART fashion (Specific, Measurable, Agreed upon, Realistic, Time-based).">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Implementation Roadmap: This section connects the tactical strategy to a roadmap on how the strategy will be implemented over a period of time.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Risks and Success Factors: Strategy should directly address various risk factors and success enablers (or accelerators). Time and time again, change management can be a major risk if not thought through in a detailed fashion, so make sure to address it head on.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Budget Estimates: What good is a strategy if it doesn’t have budget estimates? My advice is to be as realistic and comprehensive as possible. If you take short cuts to get a strategy approved, it’s just a matter of time before it comes back to bite you.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Key Performance Indicators (KPIs) and Metrics: To assess if the strategy is on track or needs adjusting, identify KPIs that need to be tracked on a short and long-term basis.">
                </insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
