import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.sass']
})
export class AboutComponent implements OnInit {
  phase: number = 0;
  typewriterInterval: number = 0;

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);

    document.querySelectorAll('.about').forEach(el => {
      el.addEventListener('mouseenter', this.onAboutHandler.bind(this));
      el.addEventListener('mouseleave', this.onAboutHandler.bind(this));
    });

    this.aboutTypeWriter();
  }

  onAboutHandler(event: any) {
    let el = event.target as HTMLDivElement;
    let title = el.children[2];
    if (title.classList.contains('in-view')) {
      
      title.classList.remove('in-view')
    }
    else {
      title.classList.add('in-view');
    }
  }

  aboutBackTypeWriter(): void {
    let dynamicText = document.getElementById("aboutTypewriterText");
    if (dynamicText != null) {
      let innerText = dynamicText.textContent;
      if (innerText.length > 0) {
        innerText = innerText.substring(0, innerText.length - 1);
        dynamicText.textContent = innerText;
        window.setTimeout(this.aboutBackTypeWriter.bind(this), 40);
      } else {
        this.typewriterInterval = 0;
        this.aboutTypeWriter();
      }
    }
  }

  aboutTypeWriter(): void {
    let phrase = "Come Join Us!";
    let dynamicText = document.getElementById("aboutTypewriterText");

    if (dynamicText != null) {
      if (this.phase === 0) {
        dynamicText.setAttribute("fill", "url(#grad1)");
      }
      else if (this.phase === 1) {
        dynamicText.setAttribute("fill", "url(#grad2)");
      }
      else {
        dynamicText.setAttribute("fill", "url(#grad3)");
      }

      if (this.typewriterInterval < phrase.length) {
        dynamicText.textContent += phrase.charAt(this.typewriterInterval);
        this.typewriterInterval++;
        window.setTimeout(this.aboutTypeWriter.bind(this), 100);
      }
      else if (this.typewriterInterval === phrase.length) {
        if (this.phase === 0) {
          this.phase = 1;
          window.setTimeout(this.aboutBackTypeWriter.bind(this), 2000);
        }
        else if (this.phase === 1) {
          this.phase = 2;
          window.setTimeout(this.aboutBackTypeWriter.bind(this), 2000);
        }
        else {
          this.phase = 0;
          window.setTimeout(this.aboutBackTypeWriter.bind(this), 2000);
        }
      }
    }
  }
}
