<div class="section" id="section1">
  <div id="canvas-wrapper">
    <canvas></canvas>
    <div class="container hero-content">
        <div class="column center-content col-12">
          <div class="copy-wrapper text-white">
            <h1 class="hero-title">We are Data Concepts</h1>
            <h2 class="hero-kicker" data-aos-delay="100">
              Driving Digital Transformation
            </h2>
          </div>
        </div>
        <hero-arrow fillColor="white"></hero-arrow>
      </div>
  </div>
</div>
