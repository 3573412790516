import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sub-footer',
  templateUrl: './sub-footer.component.html',
  styleUrls: ['./sub-footer.component.sass']
})
export class SubFooterComponent implements OnInit {
	copy_right_year: number = new Date().getFullYear();
  constructor() { }

  ngOnInit() {
  }

}
