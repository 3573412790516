import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytics-to-life',
  templateUrl: './analytics-to-life.component.html',
  styleUrls: ['./analytics-to-life.component.sass']
})
export class AnalyticsToLifeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
