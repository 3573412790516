<section class="padded-xlarge gradient-2" [ngClass]="{'after-black': background }">
    <div class="container">
      <div class="row right">
        <div class="column col-8 responsive">
          <div data-aos="fade-up" class="content-wrapper aside-right-wrapper text-white">
            <h1 class="aside-right-header section-title left text-white medium">{{name}}</h1>
            <p class="aside-right-text">{{content}}</p>
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </section>
