<div class="__layout-root">
    <modal-menu></modal-menu>
    <header [ngClass]="{ 'hidden': scrolled }">
      <div class="container">
        <nav data-aos="fade-down" data-aos-duration="500">
          <a class="logo" [ngClass]="{ 'active': showMobileMenu }" [routerLink]="['/']">dataconcepts</a>
          <div class="menu-wrap">
            <ul class="menu pseudo">
              <li class="menu-item">
                <a class="nav-link" routerLink="/work">Work</a>
              </li>
              <li class="menu-item">
                <a class="nav-link" routerLink="/services">Services</a>
              </li>
              <li class="menu-item">
                <a class="nav-link" routerLink="/insights">Insights</a>
              </li>
              <li class="menu-item">
                <a class="nav-link" routerLink="/careers">Careers</a>
              </li>
              <li class="menu-item">
                <a class="nav-link" routerLink="/about">About</a>
              </li>
              <li class="menu-item">
                <a class="nav-link" routerLink="/contact">Contact</a>
              </li>
            </ul>
            <a class="target-burger"
               (click)="(showMobileMenu ? hideMenu() : showMenu())">
              <ul class="buns">
                <li class="bun" [ngClass]="{ 'active': showMobileMenu }"></li>
                <li class="bun" [ngClass]="{ 'active': showMobileMenu }"></li>
                <li class="bun" [ngClass]="{ 'active': showMobileMenu }"></li>
              </ul>
            </a>
          </div>
        </nav>
      </div>
    </header>
    <ng-content></ng-content>
    <main-footer></main-footer>
    <sub-footer></sub-footer>
    <back-to-top></back-to-top>
</div>
