import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'challenge',
  templateUrl: './challenge.component.html',
  styleUrls: ['./challenge.component.sass']
})
export class ChallengeComponent implements OnInit {
  @Input() color: string;
  @Input() content: string;
  
  constructor() { }

  ngOnInit() {
  }

}
