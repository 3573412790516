import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'insight-container',
  templateUrl: './insight-container.component.html',
  styleUrls: ['./insight-container.component.sass']
})
export class InsightContainerComponent implements OnInit {
  @Input() name: string;
  @Input() summary: string;
  @Input() category: string;
  @Input() uri: string;
  
  constructor() { }

  ngOnInit() {
  }

}
