import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-first-union',
  templateUrl: './first-union.component.html',
  styleUrls: ['./first-union.component.sass']
})
export class FirstUnionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
