import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'solution-header',
  templateUrl: './solution-header.component.html',
  styleUrls: ['./solution-header.component.sass']
})
export class SolutionHeaderComponent implements OnInit {
  @Input() content: string;
  
  constructor() { }

  ngOnInit() {
  }

}
