import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-firearms-application',
  templateUrl: './firearms-application.component.html',
  styleUrls: ['./firearms-application.component.sass']
})
export class FirearmsApplicationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
