import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'recto',
  templateUrl: './recto.component.html',
  styleUrls: ['./recto.component.sass']
})
export class RectoComponent implements OnInit {
  @Input() subtitle: string;
  
  constructor() { }

  ngOnInit() {
  }

}
