import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-freight-volume',
  templateUrl: './freight-volume.component.html',
  styleUrls: ['./freight-volume.component.sass']
})
export class FreightVolumeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
