<div class="row subdefinition">
    <div class="column col-4 responsive">
      <div class="content-wrapper">
        <span>{{subtitle}}</span>
        <h1 class="subdefinition-title">{{name}}</h1>
      </div>
    </div>
    <div class="column col-8 responsive-8">
      <div class="content-wrapper">
        <p class="subdefinition-text">{{content}}</p>
        <ng-content></ng-content>
      </div>
    </div>
  </div>