<light-layout>
  <div class="__page-root">
    <hero name="Virginia State Police" kicker="Sex Offender Registry.">
      <div class="cover-image hero-background tinted"></div>
      <back-to-button></back-to-button>
    </hero>
    <div class="sections">
      <app-summary color="#ffc200"
                   content="Virginia State Police [VSP] is a statewide law enforcement agency for the Commonwealth of Virginia.">
        <role [style.width.%]="100">
          <li>Portal Development</li>
          <li>Web Services</li>
          <li>JavaScript</li>
          <li>XML</li>
        </role>
      </app-summary>

      <challenge color="#ffc200"
                     content="VSP owns and maintains the Sex Offender Registry [SOR] public website for the Commonwealth of Virginia. VSP awarded Data Concepts the entire project to redesign, rewrite and replace the Sex Offender Registry application with an elegant, flexible and user-friendly custom web portal for the citizens of the Commonwealth, so that they can have better control of searches, and develop their own customized views of information."></challenge>

      <solution-header content="a vital public resource made user-friendly"></solution-header>

      <solution>
        <p>Data Concepts redesigned and developed the new Sex Offender [SOR] Registry portal website based on VSP’s Requirements and integrated it with the existing SOR backend applications. Major functional additions in the portal included:</p>
        <ul class="pseudo">
          <li>- Statewide Sex-Offender Notification.</li>
          <li>- Radius Offender Search.</li>
          <li>- Interface with Google Maps.</li>
          <li>- Map View.</li>
          <li>- Captcha.</li>
          <li>- Supports approximately 284 hits per second.</li>
        </ul>
      </solution>

      <outcome color="#ffc200"
                   content="The SOR application was dramatically enhanced in usability, features and sophistication, and was successfully deployed in production by Data Concepts."></outcome>
    </div>
  </div>
</light-layout>
