<section class="most-page">
    <ng-content></ng-content>
    <div class="recto">
        <span class="diamond"></span>
        <div class="table">
            <div class="table-cell text-white">
                <h6 class="section-subtitle" style="margin-bottom: 16px">Insight &nbsp; &nbsp; &nbsp; {{category}}</h6>
                <h3>{{name}}</h3>
            </div>
        </div>
    </div>
</section>