<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Cloud" name="Six Application Migration Strategies to the Cloud"
                summary="You should gain a thorough understanding of which migration strategy will be best suited for certain portions of your portfolio.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
            <insight-pamphlet>
                <insight-sub-section class="subsection" name="Six Application Migration Strategies to the Cloud"
                    content="Organizations usually begin to think about how they will migrate an application during the second stage of the migration process. This stage is when you determine what is in your environment and the migration strategy for each application.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="You should gain a thorough understanding of which migration strategy will be best suited for certain portions of your portfolio. It is also important to consider that while one of the six strategies may be best for migrating certain applications in a given portfolio, another strategy might work better for moving different applications in the same portfolio.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="1. Rehost (“lift and shift”)"
                    content="In a large legacy migration scenario where the organization is looking to quickly implement its migration and scale to meet a business case, we find that the majority of applications are rehosted. Most rehosting can be automated with tools such as AWS SMS, although you may prefer to do this manually as you learn how to apply your legacy systems to the cloud.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="You may also find that applications are easier to re-architect once they are already running in the cloud. This happens partly because your organization will have developed better skills to do so and partly because the hard part - migrating the application, data, and traffic - has already been accomplished.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="2. Replatform (“lift, tinker and shift”)"
                    content="This entails making a few cloud optimizations without changing the core architecture of the application. For example, you may be looking to reduce the amount of time you spend managing database instances by migrating to a managed relational database service such as Amazon Relational Database Service (RDS), or migrating your application to a fully managed platform like AWS Elastic Beanstalk.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="3. Repurchase (“drop and shop”)"
                    content="This is a decision to move to a different product, and likely means your organization is willing to change the existing licensing model you have been using. For workloads that can easily be upgraded to newer versions, this strategy might facilitate feature upgrades and smoother implementation.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="4. Refactor / Re-architect"
                    content="Typically, this is driven by a strong business need to add features, scale, or performance that would otherwise be difficult to achieve in the application’s existing environment. If your organization is looking to boost agility or improve business continuity by moving to a service-oriented architecture (SOA) this strategy may be worth pursuing - even though it is often the most expensive solution.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="5. Retire"
                    content="Identifying IT assets that are no longer useful and can be turned off will help boost your business case and direct your attention towards maintaining the resources that are widely used.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="6. Retain"
                    content="You may want to retain portions of your IT portfolio because there are some applications that you are not ready to migrate and feel more comfortable keeping on-premises, or you are not ready to prioritize an application that was recently upgraded by making changes to it again.">
                </insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
