import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-data-business',
  templateUrl: './big-data-business.component.html',
  styleUrls: ['./big-data-business.component.sass']
})
export class BigDataBusinessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
