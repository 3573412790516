<light-layout>
  <div class="__page-root">
    <hero name="Virginia Department of Social Services" kicker="A Re-engineered Enterprise Data Reporting Solution.">
      <div class="cover-image hero-background tinted"></div>
      <back-to-button></back-to-button>
    </hero>
    <div class="sections">
      <app-summary color="#426EFF"
                   content="The Virginia Department of Social Services (VDSS) is a state supervised and locally administered social
                            services system. Providing oversight and guidance to 120 local offices across the state, VDSS delivers a
                            wide variety of services and benefits to over 1.6 million Virginians each year.
                            VDSS&#39; programs are designed to help Virginia&#39;s most vulnerable citizens find permanent solutions to
                            life&#39;s many challenges. The Department is responsible for administering a variety of programs, including
                            Temporary Assistance for Needy Families (TANF), Supplemental Nutrition Assistance Program (SNAP),
                            Medicaid, Adoption, Child Care Assistance, Refugee Resettlement Services, and Child and Adult
                            Protective Services.
                            Our goal is to promote the well-being of our citizens through the delivery of essential services and
                            benefits to ensure families are strengthened, and individuals achieve their highest level of self-
                            sufficiency.">
        <role [style.width.%]="100">
          <li>IBM DB2</li>
          <li>SQL Server</li>
          <li>Microsoft Power BI</li>
        </role>
      </app-summary>

      <challenge color="#426EFF"
                 content="Our client required a scalable, extensible, reusable, secure and user-friendly dashboard that allows for
better visualization and reporting capabilities, for the purpose  of generating insights."></challenge>

      <solution-header content="A re-engineered enterprise data reporting solution  designed for analyzing the ‘spend’ for child support across the commonwealth of virginia"></solution-header>

      <solution>
        <p>
          This project intends to know the percent of child support services performed by data visualization and reporting
          capabilities. Considering the project timelines and the client’s IT investments, we proposed the following solution.
        </p>
        <ul class="pseudo">
          <li>
            - The parameters Region Code, Region Name, District code, District name and the date
            dimensions Year, Month, Quarter were used here.
          </li>
          <li>- All OLAP/OLTP Child support data was migrated from IBM DB2 to SQL Server.</li>
          <li>
            - Implemented some additional checks by updating old district code and old district name to new
            district code and new district name.
          </li>
          <li>
            - Leveraged Power BI services to sign-on with secure database connection and perform
            visualizations to see the amount of last few years across different regions and its districts.
          </li>
          <li>
            - Performed multi-level visualizations which helped in getting all the required comparisons across
            different regions and its districts. One of the key findings that came out of this data visualization
            was the current support percent gradually increasing over the years with respect to different
            regions and its corresponding districts.
          </li>
          <li>
            - Tested out the end to end connection with business teams if they can view the report to ensure
            there are no data visualization discrepancies.
          </li>
          <li>
            - Leverage this application architecture for greater integration flexibility with other social services
            systems and external interfaces.
          </li>

        </ul>
      </solution>

      <outcome color="#426EFF"
               content="A reengineered enterprise data report designed for analyzing the amount spent for child support across
                    different regions and districts in Virginia over the last few years which reduced the child support
                    application dependency on legacy systems and eliminated multiple risks related to data risk, compliance
                    and governance.
                    Designed for users and stakeholders, this data visualization report would be the sole report to make
                    non-data users understand data on the fly with visuals and is an extensible and scalable modern
                    solution with reusable components to meet current and future business needs."></outcome>
    </div>
  </div>
</light-layout>
