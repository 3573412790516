import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { ContactComponent } from "./contact/contact.component";
import { CultureComponent } from './culture/culture.component';
import { CommunityServiceComponent } from './community-service/community-service.component';
import { HireUsComponent } from './hire-us/hire-us.component';
import { CareersComponent } from "./careers/careers.component";
import { JobDetailsComponent } from './careers/job-details/job-details.component';

import { LoginComponent } from './admin/login/login.component';
import { RegisterComponent } from './admin/register/register.component';
import { AdminComponent } from './admin/admin.component';
import { ManageCareersComponent } from './admin/manage-careers/manage-careers.component';
import { JobComponent } from './admin/manage-careers/job/job.component';
import { AddJobComponent } from './admin/manage-careers/job/add-job/add-job.component';
import { ManageLocationsComponent } from './admin/manage-careers/manage-locations/manage-locations.component';
import { JobLocationComponent } from './admin/manage-careers/job-location/job-location.component';
import { AddLocationComponent } from './admin/manage-careers/job-location/add-location/add-location.component';
import { CounterpageComponent } from './counterpage/counterpage.component';

import { AuthGuard } from './_helpers/auth.guard';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "about", component: AboutComponent },
  { path: "contact", component: ContactComponent },
  { path: "culture", component: CultureComponent },
  { path: "community-service", component: CommunityServiceComponent },
  { path: "hire-us", component: HireUsComponent },
  { path: "careers", component: CareersComponent },
  { path: "careers/:id", component: JobDetailsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'test', component: CounterpageComponent },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
          { path: 'careers', component: ManageCareersComponent },
          { path: 'careers/location', component: ManageLocationsComponent },
          { path: 'careers/location/add', component: AddLocationComponent },
          { path: 'careers/location/:id', component: JobLocationComponent },
          { path: 'careers/add/:id', component: AddJobComponent },
          { path: 'careers/job/:id', component: JobComponent },
          { path: 'careers/:id', component: ManageCareersComponent },
          { path: 'register', component: RegisterComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
