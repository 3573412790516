<section class="full-page">
  <ng-content></ng-content>
  <div class="container">
    <div class="row">
      <div class="column column-12 center-content">
        <div class="content-wrapper hero-wrapper text-white">
          <h3 data-aos="fade-up">{{name}}</h3>
          <p data-aos="fade-up" data-aos-delay="100">{{kicker}}</p>
        </div>
      </div>
      <hero-arrow fillColor="white"></hero-arrow>
      <div class="hero-line"></div>
    </div>
  </div>
</section>
