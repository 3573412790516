import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JobLocation } from '../_models/job-location';
import { Observable } from 'rxjs';
import { Job } from '../_models/job';

const headers: HttpHeaders = new HttpHeaders({ "Content-Type": 'application/json' });
const jobsApiUrl: string = '/api/Jobs';
const jobLocationsApiUrl: string = '/api/JobLocations';

@Injectable({
  providedIn: 'root'
})
export class CareerService {
  constructor(private http: HttpClient) { }

  getJobs(): Observable<Job[]> {
    return this.http.get<Job[]>(jobsApiUrl, { headers: headers });
  }

  getJobByID(id: number): Observable<Job> {
    return this.http.get<Job>(`${jobsApiUrl}/${id}`, { headers: headers });
  }

  getJobsByLocationID(id: number): Observable<Job[]> {
    return this.http.get<Job[]>(`${jobsApiUrl}/GetByLocationID/${id}`, { headers: headers });
  }

  addJob(job: Job) {
    return this.http.post<Job>(jobsApiUrl, job, { headers: headers });
  }

  updateJob(job: Job) {
    return this.http.put<Job>(jobsApiUrl, job, { headers: headers });
  }

  deleteJob(id: number) {
    return this.http.delete(`${jobsApiUrl}/${id}`, { headers: headers });
  }

  getJobLocations() : Observable<JobLocation[]> {
    return this.http.get<JobLocation[]>(jobLocationsApiUrl, { headers: headers });
  }

  getJobLocationByID(id: number): Observable<JobLocation> {
    return this.http.get<JobLocation>(`${jobLocationsApiUrl}/${id}`, { headers: headers });
  }

  addJobLocation(jobLocation: JobLocation) {
    return this.http.post<JobLocation>(jobLocationsApiUrl, jobLocation, { headers: headers });
  }

  updateJobLocation(jobLocation: JobLocation) {
    return this.http.put<JobLocation>(jobLocationsApiUrl, jobLocation, { headers: headers });
  }

  deleteJobLocation(id: number) {
    return this.http.delete(`${jobLocationsApiUrl}/${id}`, { headers: headers });
  }
}
