import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-azure-migration',
  templateUrl: './azure-migration.component.html',
  styleUrls: ['./azure-migration.component.sass']
})
export class AzureMigrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
