import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'insight-sub-section',
  templateUrl: './insight-sub-section.component.html',
  styleUrls: ['./insight-sub-section.component.sass']
})
export class InsightSubSectionComponent implements OnInit {
  @Input() name: string;
  @Input() content: string;
  
  constructor() { }

  ngOnInit() {
  }

}
