import { Component, OnInit } from '@angular/core';
import { Job } from '../../_models/job';
import { JobLocation } from '../../_models/job-location';
import { CareerService } from '../../_services/career.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manage-careers',
  templateUrl: './manage-careers.component.html',
  styleUrls: ['./manage-careers.component.sass']
})
export class ManageCareersComponent implements OnInit {
  jobs: Job[];
  jobLocations: JobLocation[];
  currentLocation: JobLocation;

  constructor(private route: ActivatedRoute, private careerService: CareerService) { }

  ngOnInit() {
    this.getJobLocations();
  }

  onLocationChange(index: number) {
    this.currentLocation = this.jobLocations[index];
    this.getJobs(this.currentLocation.id);
  }

  getJobLocations() {
    this.careerService.getJobLocations()
      .subscribe(locations => {
        this.jobLocations = locations;
        const id = + (this.currentLocation && this.currentLocation.id) || this.route.snapshot.paramMap.get('id') || -1;
        if (id != -1) {
          this.currentLocation = this.jobLocations.filter(location => location.id == id)[0];
        }
        else {
          this.currentLocation = locations[0];
        }
          
        this.getJobs(this.currentLocation.id);
      });
  }

  getJobs(id: number) {
    this.careerService.getJobsByLocationID(id)
      .subscribe(jobs => this.jobs = jobs);
  }

  deleteJob(id: number) {
    let job = this.jobs.filter(j => j.id == id)[0];
    if (confirm(`You are about to delete ${job.title}`)) {
      this.careerService.deleteJob(id)
        .subscribe(() => {
          this.getJobLocations();
        });
    }
  }
}
