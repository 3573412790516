<section class="light-grey padded-small">
    <div class="container">
      <div class="row">
        <div class="column col-12">
          <div class="content-wrapper subscribe-wrapper" data-aos="fade-up" data-aos-duration="600">
            <h6 class="section-subtitle">Stay Informed</h6>
            <form [formGroup]="subscribeForm" (ngSubmit)="onSubmit($event)">
              <input class="subscribe-input light-grey"
                     type="email"
                     formControlName="email"
                     placeholder="Subscribe" />

              <button class="arrow-wrapper" type="submit">
                <svg *ngIf="loading" class="lds-spinner" width="30px" height="30px" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"
                     style="background: none;">
                  <g transform="rotate(0 50 50)">
                    <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9s"
                               repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(36 50 50)">
                    <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8s"
                               repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(72 50 50)">
                    <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.7s"
                               repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(108 50 50)">
                    <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6s"
                               repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(144 50 50)">
                    <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s"
                               repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(180 50 50)">
                    <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4s"
                               repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(216 50 50)">
                    <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3s"
                               repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(252 50 50)">
                    <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.2s"
                               repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(288 50 50)">
                    <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.1s"
                               repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(324 50 50)">
                    <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#000">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s"
                               repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                </svg>
                <svg *ngIf="!loading" class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     x="0px" y="0px" width="25px" height="26.005px" viewBox="286.5 382.498 39.002 26.005"
                     enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                  <g>
                    <polygon fill="#000"
                             points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                    </polygon>
                  </g>
                </svg>
              </button>
              <div *ngIf="email.invalid && email.dirty" class="alert alert-danger">
                <div *ngIf="email.errors.required">
                  Please enter an email address.
                </div>
                <div *ngIf="email.errors.email">
                  Please enter a valid email address.
                </div>
              </div>
              <div *ngIf="result" class="show-result">{{result}}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
