<section data-aos="fade-up" class="padded-top">
    <div class="container">
        <div class="row">
            <div class="column col-12">
                <div class="copy-wrapper solution-wrapper">
                    <h1 class="section-title">SOLUTION</h1>
                    <p>{{content}}</p>
                </div>
            </div>
        </div>
    </div>
</section>