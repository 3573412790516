import { Component, OnInit } from '@angular/core';
import {  FormBuilder, Validators } from '@angular/forms';
import { HttpHeaders, HttpEventType, HttpClient } from '@angular/common/http';

@Component({
  selector: 'subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.sass']
})
export class SubscribeComponent implements OnInit {
  loading: boolean = false;
  result: string;
  subscribeForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]]
  });
  
  constructor(private fb: FormBuilder, private http: HttpClient) { }

  ngOnInit(): void { }

  onSubmit(event: any): void {
    event.preventDefault();
    this.result = "";
    if (this.subscribeForm.valid) {
      return this.submitSubscription();
    }
  }

  submitSubscription(): void {
    this.loading = true;

    let headers = new HttpHeaders();
    headers.append("Content-Type", 'application/json');

    const formData = new FormData();
    formData.append("email", this.subscribeForm.get('email').value);

    this.http.post('/api/Email/Subscribe', formData, { observe: 'events', headers: headers })
      .subscribe(event => {
        if (event.type === HttpEventType.Response) {
          this.loading = false;
          if (event.ok) {
            this.subscribeForm.reset();
            this.result = "Your submission was successful.";
          }
          else {
            this.result = "Sorry there was an error processing your form, please try again.";
          }
        }
      });
  }

  get email() { return this.subscribeForm.get('email'); }
}
