import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-governance-journey',
  templateUrl: './governance-journey.component.html',
  styleUrls: ['./governance-journey.component.sass']
})
export class GovernanceJourneyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
