<div class="row">
    <div class="column col-5 responsive">
        <div class="content-wrapper text-white">
            <h6 class="subtitle white">
                <span>role</span>
            </h6>
        </div>
    </div>
    <div class="column col-7 responsive">
        <div class="content-wrapper list-wrapper text-white">
            <ul class="pseudo">
                <ng-content></ng-content>
            </ul>
        </div>
    </div>
</div>