import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'section-divider',
  templateUrl: './section-divider.component.html',
  styleUrls: ['./section-divider.component.sass']
})
export class SectionDividerComponent implements OnInit {
  @Input() name: string;
  @Input() content: string;
  @Input() black: boolean = false;
  
  constructor() { }

  ngOnInit() {
  }

}
