import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytics-is-popular',
  templateUrl: './analytics-is-popular.component.html',
  styleUrls: ['./analytics-is-popular.component.sass']
})
export class AnalyticsIsPopularComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
