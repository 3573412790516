import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-data',
  templateUrl: './big-data.component.html',
  styleUrls: ['./big-data.component.sass']
})
export class BigDataComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
