<section class="light-grey padded-large">
    <div class="container">
        <div class="row">
            <div class="column col-4">
                <div class="content-wrapper">
                    <h6 class="subtitle">
                        <span>{{subtitle}}</span>
                    </h6>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="column col-12">
                <div data-aos="fade-up" class="content-wrapper technology-wrapper">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </div>
</section>