import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass']
})
export class RegisterComponent implements OnInit {
  result: string;
  loading: boolean = false;
  registerForm = this.fb.group({
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    email: ["", [Validators.required, Validators.email]],
    password: ["", Validators.required],
    passwordConfirm: ["", Validators.required]
  });

  constructor(private fb: FormBuilder, private http: HttpClient) { }

  ngOnInit() {
  }

  onSubmit(event: any): void {
    event.preventDefault();
    this.result = "";
    if (this.registerForm.valid) {
      this.submitRegistration();
    }
  }

  submitRegistration(): void {
    this.loading = true;

    const formData = new FormData();
    formData.append("FirstName", this.registerForm.get('firstName').value);
    formData.append("LastName", this.registerForm.get('lastName').value);
    formData.append("Username", this.registerForm.get('email').value);
    formData.append("Password", this.registerForm.get('password').value);

    let headers = new HttpHeaders();
    headers.append("Content-Type", 'application/json');

    this.http.post('/api/Users/Register', formData, { observe: 'events', headers: headers })
      .subscribe(event => {
        if (event.type === HttpEventType.Response) {
          this.loading = false;
          if (event.ok) {
            this.registerForm.reset();
            this.result = "Success: User account has been created.";
          }
          else {
            this.result = "Fail: Sorry there was an error processing registration, please try again.";
          }
        }
      });
  }

  // validation helpers
  get firstName() { return this.registerForm.get('firstName'); }
  get lastName() { return this.registerForm.get('lastName'); }
  get email() { return this.registerForm.get('email'); }
  get password() { return this.registerForm.get('password'); }
  get passwordConfirm() { return this.registerForm.get('passwordConfirm'); }
}
