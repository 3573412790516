<div class="__page-root">
  <div class="admin-form-wrapper">
    <form [formGroup]="jobLocationForm" (ngSubmit)="onSubmit($event)">
      <h1>Edit Job Location</h1>
      <input class="form-control" type="text" formControlName="city" placeholder="City" autofocus>
      <state-select formControlName="state" id="stateSelect"></state-select>
      <div class="admin-form-actions">
        <button class="btn btn-light" type="button" (click)="cancelAction()">Cancel</button>&nbsp;&nbsp;&nbsp;<button class="btn btn-primary" type="submit">Save</button>
      </div>
    </form>
    <div *ngIf="error">{{error}}</div>
  </div>
</div>
