import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonComponentsModule } from '../common-components.module';
import { InsightsModule } from '../insights/insights.module';

// work components
import { BackToButtonComponent } from '../common/work/back-to-button/back-to-button.component';
import { ChallengeComponent } from '../common/work/challenge/challenge.component';
import { OutcomeComponent } from '../common/work/outcome/outcome.component';
import { RoleComponent } from '../common/work/role/role.component';
import { SolutionComponent } from '../common/work/solution/solution.component';
import { SolutionHeaderComponent } from '../common/work/solution-header/solution-header.component';
import { SummaryComponent } from '../common/work/summary/summary.component';

import { WorkComponent } from "./work.component";
import { SabraComponent } from './sabra/sabra.component';
import { CapitalOneComponent } from './capital-one/capital-one.component';
import { CapitalOneTwoComponent } from './capital-one-two/capital-one-two.component';
import { CircuitCourtsComponent } from './circuit-courts/circuit-courts.component';
import { CourtComponent } from './court/court.component';
import { CriminalHistoryComponent } from './criminal-history/criminal-history.component';
import { DataLakeComponent } from './data-lake/data-lake.component';
import { DevopsTransformationComponent } from './devops-transformation/devops-transformation.component';
import { EnergyComponent } from './energy/energy.component';
import { EnergyTwoComponent } from './energy-two/energy-two.component';
import { EstesQuoteComponent } from './estes-quote/estes-quote.component';
import { EstesImprovementComponent } from './estes-improvement/estes-improvement.component';
import { FinancePortalComponent } from './finance-portal/finance-portal.component';
import { FirearmsApplicationComponent } from './firearms-application/firearms-application.component';
import { FirstUnionComponent } from './first-union/first-union.component';
import { FreightVolumeComponent } from './freight-volume/freight-volume.component';
import { FreightMovementComponent } from './freight-movement/freight-movement.component';
import { JudicialEnforcementComponent } from './judicial-enforcement/judicial-enforcement.component';
import { JuryManagementComponent } from './jury-management/jury-management.component';
import { MagellenHealthComponent } from './magellen-health/magellen-health.component';
import { PredictiveModelingComponent } from './predictive-modeling/predictive-modeling.component';
import { ScalaArchitectureComponent } from './scala-architecture/scala-architecture.component';
import { ServerMigrationComponent } from './server-migration/server-migration.component';
import { SexRegistryComponent } from './sex-registry/sex-registry.component';
import { UnifiedDataComponent } from './unified-data/unified-data.component';
import { VehicleInspectionComponent } from './vehicle-inspection/vehicle-inspection.component';
import { WebPortalComponent } from './web-portal/web-portal.component';
import { VdssComponent } from './vdss/vdss.component';

import { WorkRoutingModule } from './work-routing.module';


@NgModule({
  imports: [
    CommonModule,
    CommonComponentsModule,
    InsightsModule,
    WorkRoutingModule
  ],
  exports: [
    BackToButtonComponent,
    ChallengeComponent,
    OutcomeComponent,
    RoleComponent,
    SolutionComponent,
    SolutionHeaderComponent,
    SummaryComponent
  ],
  declarations: [
    BackToButtonComponent,
    ChallengeComponent,
    OutcomeComponent,
    RoleComponent,
    SolutionComponent,
    SolutionHeaderComponent,
    SummaryComponent,
    WorkComponent,
    SabraComponent,
    CapitalOneComponent,
    CapitalOneTwoComponent,
    CircuitCourtsComponent,
    CourtComponent,
    CriminalHistoryComponent,
    DataLakeComponent,
    DevopsTransformationComponent,
    EnergyComponent,
    EnergyTwoComponent,
    EstesQuoteComponent,
    EstesImprovementComponent,
    FinancePortalComponent,
    FirearmsApplicationComponent,
    FirstUnionComponent,
    FreightVolumeComponent,
    FreightMovementComponent,
    JudicialEnforcementComponent,
    JuryManagementComponent,
    MagellenHealthComponent,
    PredictiveModelingComponent,
    ScalaArchitectureComponent,
    ServerMigrationComponent,
    SexRegistryComponent,
    UnifiedDataComponent,
    VehicleInspectionComponent,
    WebPortalComponent,
    VdssComponent
  ]
})
export class WorkModule { }
