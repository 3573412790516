import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-capital-one-two',
  templateUrl: './capital-one-two.component.html',
  styleUrls: ['./capital-one-two.component.sass']
})
export class CapitalOneTwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
