<div class="__layout-root">
  <div class="login">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit($event)">
      <h1>Please sign in</h1>
      <input class="login-input" type="text" formControlName="username" placeholder="Username" autofocus>
      <input type="password" formControlName="password" class="login-input" placeholder="Password">
      <div style="text-align:center;">
        <button class="btn btn-primary" type="submit">
          <svg *ngIf="loading" class="lds-spinner" width="30px" height="30px" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"
               style="background: none;">
            <g transform="rotate(0 50 50)">
              <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9s"
                         repeatCount="indefinite"></animate>
              </rect>
            </g>
            <g transform="rotate(36 50 50)">
              <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8s"
                         repeatCount="indefinite"></animate>
              </rect>
            </g>
            <g transform="rotate(72 50 50)">
              <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.7s"
                         repeatCount="indefinite"></animate>
              </rect>
            </g>
            <g transform="rotate(108 50 50)">
              <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6s"
                         repeatCount="indefinite"></animate>
              </rect>
            </g>
            <g transform="rotate(144 50 50)">
              <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s"
                         repeatCount="indefinite"></animate>
              </rect>
            </g>
            <g transform="rotate(180 50 50)">
              <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4s"
                         repeatCount="indefinite"></animate>
              </rect>
            </g>
            <g transform="rotate(216 50 50)">
              <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3s"
                         repeatCount="indefinite"></animate>
              </rect>
            </g>
            <g transform="rotate(252 50 50)">
              <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.2s"
                         repeatCount="indefinite"></animate>
              </rect>
            </g>
            <g transform="rotate(288 50 50)">
              <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.1s"
                         repeatCount="indefinite"></animate>
              </rect>
            </g>
            <g transform="rotate(324 50 50)">
              <rect x="25" y="25" rx="23.5" ry="12" width="6" height="12" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s"
                         repeatCount="indefinite"></animate>
              </rect>
            </g>
          </svg>
          <span *ngIf="!loading">Sign in</span>
        </button>
      </div>
    </form>
    <div *ngIf="error">{{error}}</div>
  </div>
</div>
