<section class="padded-xxlarge">
    <div class="container">
      <div data-aos="fade-up" class="row">
        <div class="column col-4 responsive">
          <div class="content-wrapper">
            <h6 class="subtitle">
              <span>{{subtitle}}</span>
            </h6>
          </div>
        </div>
        <div class="column col-8 responsive">
          <div class="content-wrapper">
            <p class="promise-text">{{content}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>