<section class="medium-grey padded-large">
    <div class="container">
        <div class="row">
            <div class="column col-12">
                <div class="content-wrapper wrapper">
                    <h1 class="section-header">{{name}}</h1>
                    <div data-aos="fade-up" class="block">
                        <ng-content></ng-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>