<light-layout>
  <div class="__page-root">
    <section class="jobs-hero black">
      <div class="container">
        <div class="row">
          <div class="column col-12 center-content">
            <div class="content-wrapper hero-text-wrapper text-white">
              <div>
                <span id="location" class="hero-kicker" *ngIf="jobLocation">{{jobLocation.city}}, {{jobLocation.state}}</span>
                <h3 id="position" class="text-hero-title" *ngIf="job">{{job.title}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="sections">
      <section class="padded-large">
        <div class="container">
          <div class="row">
            <div class="column col-4 responsive">
              <div class="content-wrapper">
                <h6 class="subtitle">
                  <span>summary</span>
                </h6>
              </div>
            </div>
            <div class="column col-8 responsive-8">
              <div class="content-wrapper">
                <div *ngIf="job" [innerHtml]="job.htmlContent"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <job-app></job-app>
    </div>
  </div>
</light-layout>
