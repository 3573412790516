import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sub-service',
  templateUrl: './sub-service.component.html',
  styleUrls: ['./sub-service.component.sass']
})
export class SubServiceComponent implements OnInit {
  @Input() name: string;
  @Input() content: string;
  
  constructor() { }

  ngOnInit() {
  }

}
