import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-strategy-right',
  templateUrl: './data-strategy-right.component.html',
  styleUrls: ['./data-strategy-right.component.sass']
})
export class DataStrategyRightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
