<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Data Governance" name="Data Governance is a Journey, not a Destination"
                summary="Governance is the ongoing process of creating and managing processes, policies, and information. This includes strategies, processes, activities, skills, organizations, and technologies for the purpose of accelerating business outcomes.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
            <insight-pamphlet>
                <insight-sub-section class="subsection subsection-padding" name="Data Governance Summary"
                    content="Governance is the ongoing process of creating and managing processes, policies, and information. This includes strategies, processes, activities, skills, organizations, and technologies for the purpose of accelerating business outcomes. The Data Governance Institute defines data governance as “a system of decision rights and accountabilities for information-related processes, executed according to agreed-upon models which describe who can take what actions with what information, and when, under what circumstances, using what methods.” The definition looks complicated, but at the end of the day, data governance is essentially about standards, policies and reusable models.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="Data Governance for Your Company"
                    content="If you have a Data Warehouse (DW), which is the traditional approach towards getting insights with data, you probably already have some data governance frameworks and standards in place by having standards for your dimensional tables. When we talk about best practices in DG, the first step is to understand what DG is for your company.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="Do you have a data governance program? This question is not easy to answer because data governance as a concept is not fully understood. Your company is probably already doing some Data Governance but you just don’t realize it, which begs the question: what is data governance (DG)? DG is a program which sets rules and standards for data-related matters. The IT teams are responsible for providing solutions for the project in addition to development and infrastructure services, but it is the responsibility of the data governance team to guide the IT teams regarding data-related policies and standards. This brings us to the next key consideration.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="The Data Governance Council"
                    content="The Data Governance Council is responsible for setting the data governance framework for the organization. The DG framework should be customized for your company’s specific needs, but in general, the framework could include strategic planning tasks such as determining data needs, developing data policies and guidelines, or planning data management projects. The framework also may include ongoing control tasks such as managing and resolving data related issues, monitoring data policies, and promoting the value of data assets.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Similar to the IT project leadership teams, the Data Governance Council members would need to include members from the business and IT. It is critical to get business buy-in as they will be actively involved in the DG tasks.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="It is also important to have a flexible org structure for the council. A good practice is to follow a top-down approach where the leadership of the council is driving the governance, while the business analysts and Data Stewards are implementing the policies. The Data Stewards are responsible for providing feedback to the leadership.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Communication"
                    content="Implementing DG involves a huge change in the organization. That is why it is imperative for the DG Council to come up with a mission which aligns with the business interests and takes into consideration the strengths of the Implementation teams. The mission of the DG programs must be communicated clearly and succinctly, articulating the DG main drivers within the organization. The mission needs to be communicated repeatedly and consistently across various avenues.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="Focus Area"
                    content="A Data Governance program can include a multitude of focus areas and it is important to pick an area which provides the most value to your company. These initiatives can be at the enterprise level, or at the project level. Below are some focus areas and brief descriptions:">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Standards and Policies: This sort of program would collect standards, review existing them and check against the corporate standards. Another main activity is to define a Data Strategy for the company and provide support for any siloed projects trying to join the enterprise landscape.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Data Quality (DQ): This kind of program deals with finding, correcting and monitoring Data Quality issues in the enterprise. These programs normally involve software for profiling, cleansing and matching engines. DQ initiatives also lead to Master Data Management (MDM) projects, which define the master data and give a 360-degree view of domains such as customer or Vendor.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Data Security and Privacy: Every company has compliance and regulations requirements, and this focus area works to address these issues by setting Access Management rights, Information Security controls, Data privacy procedures etc., particularly for sensitive data.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Architecture/Integration: This focus area aims to achieve operational efficiency by simplifying Data Integration Architecture components such as Data Modelling, Master Data modeling, Service Oriented Architecture etc.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="DW and Business Intelligence (BI): This program promotes the use of building Data Warehouses and Data Marts to support historical reporting and also futuristic reporting.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="Self-service architectures: This kind of program takes into consideration the stewardship and Data Preparation challenges, and aims to build workflows limiting the ‘shadow IT’ paradigm, which happens so often in organizations.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="It’s a Journey, Not a Destination"
                    content="It is important to understand just like corporate governance, Data Governance is not a project, but an ongoing process. Any ongoing process will need to have goals defined and a method to measure the progress of the program. A recommended approach is to scan the progress against a Data Governance Maturity Model. Depending on the focus area you chose for the DG program, your company should also have metrics defined to measure the success of the program. It is also recommended to use Agile practices. Agile methods, such as continuous delivery, constant collaboration between IT and business, welcoming change, and continuous attention to technical excellence and good design, fit perfectly into Data Governance practices.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="Evolve"
                    content="Just like processes and people, technology is a big part of Data Governance, and technology is ever changing. Whether you are in business or IT, it is recommended to embrace technological innovation. New innovations in machine learning, cloud, and the big data space can make data governance initiatives effective. For example, building a data lake on Hadoop could make storage of master data and DW data cheaper and the processing faster.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="There is a very good chance your company already has one of the data governance initiatives successfully implemented. Our recommendation is to use that as a base for implementing other focus areas. Have a vision for data governance in your company, get buy-in from business and IT leadership and make IT and business collaboration better. With these initial steps in place, DG can successfully evolve and provide true value to your organization!">
                </insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
