import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'svg-typewriter',
  templateUrl: './svg-typewriter.component.html',
  styleUrls: ['./svg-typewriter.component.sass']
})
export class SvgTypewriterComponent implements OnInit, AfterViewInit {
  phase: number = 0;
  typewriterInterval: number = 0;

  @Input() phrases: string[];

  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.typeWriter();
  }

  backTypeWriter(): void {
    let dynamicText = document.getElementById("typewriterText");
    if (dynamicText != null) {
      let innerText = dynamicText.textContent;
      if (innerText.length > 0) {
        innerText = innerText.substring(0, innerText.length - 1);
        dynamicText.textContent = innerText;
        window.setTimeout(this.backTypeWriter.bind(this), 40);
      } else {
        this.typewriterInterval = 0;
        this.typeWriter();
      }
    }
  }

  typeWriter(): void {
    let phrase = "";
    let dynamicText = document.getElementById("typewriterText");

    if (dynamicText != null) {
      if (this.phase === 0) {
        dynamicText.setAttribute("fill", "url(#grad1)");
      }
      else if (this.phase === 1) {
        dynamicText.setAttribute("fill", "url(#grad2)");
      }
      else {
        dynamicText.setAttribute("fill", "url(#grad3)");
      }

      if (this.typewriterInterval < this.phrases[this.phase].length) {
        dynamicText.textContent += this.phrases[this.phase].charAt(this.typewriterInterval);
        this.typewriterInterval++;
        window.setTimeout(this.typeWriter.bind(this), 60);
      }
      else if (this.typewriterInterval === this.phrases[this.phase].length) {
        if (this.phase === 0) {
          this.phase = 1;
          window.setTimeout(this.backTypeWriter.bind(this), 2000);
        }
        else if (this.phase === 1) {
          this.phase = 2;
          window.setTimeout(this.backTypeWriter.bind(this), 2000);
        }
        else {
          this.phase = 0;
          window.setTimeout(this.backTypeWriter.bind(this), 2000);
        }
      }
    }
  }
}
