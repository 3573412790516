import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-capital-one',
  templateUrl: './capital-one.component.html',
  styleUrls: ['./capital-one.component.sass']
})
export class CapitalOneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
