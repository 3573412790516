<default-layout>
    <div class="__page-root">
        <root-hero name="Life."></root-hero>
        <div class="sections">
            <section data-aos="fade-up" class="padded-large black">
                <div class="container">
                    <div class="row">
                        <div class="column col-3 responsive">
                            <div class="content-wrapper text-white">
                                <h6 class="subtitle white">
                                    <span>who we are</span>
                                </h6>
                            </div>
                        </div>
                        <div class="column col-9 responsive">
                            <div class="content-wrapper text-white">
                                <h1 class="about-us-header">IT Consultancy of the Future.</h1>
                                <p class="about-us-text">Data Concepts exists to create winning systems that disrupt,
                                    connect, and engage prospects and customers. We’ve assembled a distinctive and powerful
                                    combination of business strategists, data engineers, full stack technologists,
                                    designers, and writers in a highly collaborative environment to create the consultancy
                                    of the future.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-aos="fade-up">
                <div class="culture-section">
                    <div class="image"></div>
                    <div class="image-wrap">
                        <div class="image-3"></div>
                        <div class="image-2"></div>
                    </div>
                </div>
            </section>
            <div class="image-10 full-page"></div>
            <section data-aos="fade-up" class="padded-xlarge  9*">
                <div class="container">
                    <div class="row">
                        <div class="column col-12">
                            <div class="copy-wrapper">
                                <h1 class="interim-title">You get our A-Team. Always.</h1>
                                <h3 class="interim-text">We’re large enough to accomplish anything but still boutique enough
                                    to deliver world-class service to every client. Comprised of storytellers, designers,
                                    strategists, analysts, and trained mixologists, our highly-tenured team drives hard
                                    during the day and then over to our DC-DrinkUP for a glass of collaborative inspiration
                                    and humor.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="padded-medium">
              <app-video videoID="teamVideo" videoSrc="TeamVideo.mp4" posterSrc="/assets/video/letshangout.svg"></app-video>
            </section>
            <section data-aos="fade-up" class="padded-xlarge">
                <div class="container">
                    <div class="row">
                        <div class="column col-12">
                            <div class="copy-wrapper">
                                <h1 class="interim-title">We’re passionate about our craft.</h1>
                                <h3 class="interim-text">We also believe different points of view make for stronger teams
                                    and better ideas. We practice grateful collaboration, letting the rich experiences of
                                    expert practitioners shape solutions that transcend any one individual’s capabilities.
                                    And if that doesn’t work, a team run can sometimes do the trick.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			<section>
                <div class="image-wrap-2">
                    <div class="image-7"></div>
                    <div class="image-8"></div>
                </div>
            </section>
			<section>
                <div class="image-wrap-2">
                    <div class="image-9"></div>
                    <div class="image-10"></div>
                </div>
            </section>
			<section>
                <div class="image-wrap-2">
                    <div class="image-11"></div>
                    <div class="image-12"></div>
                </div>
            </section>
            <section data-aos="fade-up">
                <div class="culture-section">
                    <div class="image-4"></div>
                </div>
            </section>
            <section data-aos="fade-up" >
                <div class="image-wrap-2">
                    <div class="image-5"></div>
                    <div class="image-6"></div>
                </div>
            </section>
			<section data-aos="fade-up" >
				<a routerLink="/careers" class="wrappy-link-1 join-us-link">
                        <div class="container join-us-container">
                            <div class="row join-us-row">
                                <div class="column col-12 responsive center-content">
                                  <div class="content-wrapper join-us-wrapper">
                                    <h1 class="join-us-header">Join Us.</h1>
                                    <div class="content-wrapper arrow-wrapper">
                                      <div class="join-us-link">
                                        <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1"
                                             xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="39.002px"
                                             height="26.005px" viewBox="286.5 382.498 39.002 26.005"
                                             enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                                          <g>
                                            <polygon fill="#fff"
                                                     points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                                            </polygon>
                                          </g>
                                        </svg>
                                        </div>
                                      </div>
                                      <p class="joining-text">Like what you see? Want to hang out?</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </a>
			</section>

			<section data-aos="fade-up" >
			<a routerLink="/community-service" class="wrappy-link-2 join-us-link">
                        <div class="container join-us-container">
                            <div class="row join-us-row">
                                <div class="column col-12 responsive center-content">
                                  <div class="content-wrapper join-us-wrapper">
                                    <h1 class="join-us-header">Outside the Office.</h1>
                                    <div class="content-wrapper arrow-wrapper">
                                      <div class="join-us-link">
                                        <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1"
                                             xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="39.002px"
                                             height="26.005px" viewBox="286.5 382.498 39.002 26.005"
                                             enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                                          <g>
                                            <polygon fill="#fff"
                                                     points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                                            </polygon>
                                          </g>
                                        </svg>
                                      </div>
                                    </div>
                                    <p class="joining-text">Citizenship counts.</p>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </a>
			</section>
            
        </div>
    </div>
</default-layout>
