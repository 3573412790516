import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonComponentsModule } from '../common-components.module';
import { InsightComponentsModule } from './insight-components.module';

// pages
import { InsightsComponent } from "../insights/insights.component";
import { AgileEmbraceComponent } from '../insights/agile-embrace/agile-embrace.component';
import { AgileTransformationComponent } from '../insights/agile-transformation/agile-transformation.component';
import { AgileUnderstandingComponent } from '../insights/agile-understanding/agile-understanding.component';
import { AnalyticsIsPopularComponent } from '../insights/analytics-is-popular/analytics-is-popular.component';
import { AnalyticsPowerComponent } from '../insights/analytics-power/analytics-power.component';
import { AnalyticsToLifeComponent } from '../insights/analytics-to-life/analytics-to-life.component';
import { ApplicationDevelopmentApproachComponent } from '../insights/application-development-approach/application-development-approach.component';
import { AzureMachineLearningComponent } from '../insights/azure-machine-learning/azure-machine-learning.component';
import { AzureMigrationComponent } from '../insights/azure-migration/azure-migration.component';
import { BiDataWarehousingComponent } from '../insights/bi-data-warehousing/bi-data-warehousing.component';
import { BigDataBusinessComponent } from '../insights/big-data-business/big-data-business.component';
import { BigDataLeverageComponent } from '../insights/big-data-leverage/big-data-leverage.component';
import { BigDataPlanComponent } from '../insights/big-data-plan/big-data-plan.component';
import { BlockchainWorldComponent } from '../insights/blockchain-world/blockchain-world.component';
import { CloudApplicationDevelopmentComponent } from '../insights/cloud-application-development/cloud-application-development.component';
import { DataAnalyticsChangeComponent } from '../insights/data-analytics-change/data-analytics-change.component';
import { InsightDataGovernanceComponent } from '../insights/data-governance/data-governance.component';
import { DataMiningComponent } from '../insights/data-mining/data-mining.component';
import { DataSecurityBiComponent } from '../insights/data-security-bi/data-security-bi.component';
import { DataSilosComponent } from '../insights/data-silos/data-silos.component';
import { DataStrategyRightComponent } from '../insights/data-strategy-right/data-strategy-right.component';
import { DataStrategyVsDataAnalyticsComponent } from '../insights/data-strategy-vs-data-analytics/data-strategy-vs-data-analytics.component';
import { DevopsRoadmapComponent } from '../insights/devops-roadmap/devops-roadmap.component';
import { DigitalCultureComponent } from '../insights/digital-culture/digital-culture.component';
import { DigitalLandscapeComponent } from '../insights/digital-landscape/digital-landscape.component';
import { DigitalTransformationComponent } from '../insights/digital-transformation/digital-transformation.component';
import { GovernanceJourneyComponent } from '../insights/governance-journey/governance-journey.component';
import { GovernancePathComponent } from '../insights/governance-path/governance-path.component';
import { GovernanceProcessComponent } from '../insights/governance-process/governance-process.component';
import { InconsistentDataComponent } from '../insights/inconsistent-data/inconsistent-data.component';
import { InformationArchitectureComponent } from '../insights/information-architecture/information-architecture.component';
import { ItAgilityComponent } from '../insights/it-agility/it-agility.component';
import { MdmVsDwComponent } from '../insights/mdm-vs-dw/mdm-vs-dw.component';
import { ModernDataArchitectureComponent } from '../insights/modern-data-architecture/modern-data-architecture.component';
import { ModernUtilityComponent } from '../insights/modern-utility/modern-utility.component';
import { PowerBiComponent } from '../insights/power-bi/power-bi.component';
import { QuickPeekComponent } from '../insights/quick-peek/quick-peek.component';
import { SharepointComponent } from '../insights/sharepoint/sharepoint.component';
import { SixCloudMigrationStrategiesComponent } from '../insights/six-cloud-migration-strategies/six-cloud-migration-strategies.component';
import { WhyGoDigitalComponent } from '../insights/why-go-digital/why-go-digital.component';

import { InsightsRoutingModule } from './insights-routing.module';

@NgModule({
  declarations: [
    InsightsComponent,
    AgileEmbraceComponent,
    AgileTransformationComponent,
    AgileUnderstandingComponent,
    AnalyticsIsPopularComponent,
    AnalyticsPowerComponent,
    AnalyticsToLifeComponent,
    ApplicationDevelopmentApproachComponent,
    AzureMachineLearningComponent,
    AzureMigrationComponent,
    BiDataWarehousingComponent,
    BigDataBusinessComponent,
    BigDataLeverageComponent,
    BigDataPlanComponent,
    BlockchainWorldComponent,
    CloudApplicationDevelopmentComponent,
    DataAnalyticsChangeComponent,
    InsightDataGovernanceComponent,
    DataMiningComponent,
    DataSecurityBiComponent,
    DataSilosComponent,
    DataStrategyRightComponent,
    DataStrategyVsDataAnalyticsComponent,
    DevopsRoadmapComponent,
    DigitalCultureComponent,
    DigitalLandscapeComponent,
    DigitalTransformationComponent,
    GovernanceJourneyComponent,
    GovernancePathComponent,
    GovernanceProcessComponent,
    InconsistentDataComponent,
    InformationArchitectureComponent,
    ItAgilityComponent,
    MdmVsDwComponent,
    ModernDataArchitectureComponent,
    ModernUtilityComponent,
    PowerBiComponent,
    QuickPeekComponent,
    SharepointComponent,
    SixCloudMigrationStrategiesComponent,
    WhyGoDigitalComponent
  ],
  imports: [
    CommonModule,
    CommonComponentsModule,
    InsightComponentsModule,
    InsightsRoutingModule
  ]
})
export class InsightsModule { }
