import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'verso',
  templateUrl: './verso.component.html',
  styleUrls: ['./verso.component.sass']
})
export class VersoComponent implements OnInit {
  @Input() name: string;
  @Input() subtitle: string;
  constructor() { }

  ngOnInit() {
  }

}
