import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'light-layout',
  templateUrl: './light-layout.component.html',
  styleUrls: ['./light-layout.component.sass']
})
export class LightLayoutComponent implements OnInit, OnDestroy {
  showMobileMenu: boolean;
  public scrolled: boolean;

  constructor() { }

  ngOnInit() {
    this.hideMenu();
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  showMenu() {
    this.showMobileMenu = true;
    const el1 = document.body;
    const el2 = document.querySelector(".modal-menu");

    el1.classList.add("hide-overflow");
    el2.classList.add("show");
  }

  hideMenu() {
    const el1 = document.body;
    const el2 = document.querySelector(".modal-menu");
    this.showMobileMenu = false;

    el1.classList.remove("hide-overflow");
    el2.classList.remove("show");
  }

  handleScroll(event) {
    this.scrolled = document.body.scrollTop > 0 || document.documentElement.scrollTop > 0;
  }
}
