<light-layout>
  <div class="__page-root">
    <hero name="Virginia State Police" kicker="Web Portal.">
      <div class="cover-image hero-background tinted"></div>
      <back-to-button></back-to-button>
    </hero>
    <div class="sections">
      <app-summary color="#cb3694"
                   content="Virginia State Police [VSP] is a statewide law enforcement agency for the Commonwealth of Virginia.">
        <role [style.width.%]="100">
          <li>Dashboard Development</li>
          <li>Portal Development</li>
          <li>Legal Compliance</li>
        </role>
      </app-summary>

      <challenge color="#cb3694"
                     content="VSP’s existing Firearms Transaction Program provided a point-of-sale approval or disapproval decision regarding the sale or transfer of all firearms in the State of Virginia. VSP wanted to replace the existing application with a more scalable, extensible and robust web architecture and add major new features and functionalities including enhancements based on new laws enacted by the State General Assembly after the Virginia Tech incident. In addition to improving the UI for external users including firearms dealers and sellers, most importantly VSP wanted to provide a custom portal to its internal team of users that were tasked with conducting the background checks and hence, enable them to be more productive, efficient and to minimize the probability of any lapses in processing of background checks."></challenge>

      <solution-header content="legal compliance assured, public safety promoted"></solution-header>

      <solution>
        <p>Data Concepts was awarded by VSP the work to completely redesign, rewrite and replace the current Firearms system in web technologies. This system developed by Data Concepts is used statewide by VSP personnel, firearm dealers and sellers. Every firearm sold or bought in the Commonwealth of Virginia is processed through this portal. Major Custom Web Portal features:</p>
        <ul class="pseudo">
          <li>- Allows each VSP team member take a case/request from a queue.</li>
          <li>- Each team member has a personalized Dashboard.</li>
          <li>- For each firearm request, users can do a study, take notes, approve or disapprove, or escalate to a supervisor directly from their personal homepage.</li>
          <li>- Portal interfaces with State and Federal Law Enforcement Databases/systems including National Instant Criminal Background Check System (NICS), Interstate Identification Index (III), and National Crime Information Center (NCIC).</li>
          <li>- Allows supervisors to view performance reports for VSP team members.</li>
          <li>- Role-based Security.</li>
          <li>- Integration with VSP Single Sign on.</li>
          <li>- Approximately 60-70 Web Pages for Dealers and Firearms Transaction Center (VSP) Users.</li>
          <li>- Instant Background Check decision processing.</li>
          <li>- Supports approximately 300,000 transactions per year.</li>
        </ul>
      </solution>

      <outcome color="#cb3694"
                   content="Data Concepts successfully managed, executed and deployed the Firearms Application in production for the Virginia State Police and continues to maintain and support it."></outcome>
    </div>
  </div>
</light-layout>
