<section class="padded-bottom">
    <div class="container insight-wrapper">
        <div class="post-bar">
            <a routerLink="/insights" class="all-posts">
                <h5>All Insights</h5>
                <span></span>
            </a>
        </div>
        <ng-content></ng-content>
    </div>
</section>