<section class="gray footer">
    <div class="container">
      <div class="row">
        <div class="column col-4 responsive">
          <div class="content-wrapper footer-logo-wrapper">
            <a class="footer-logo" routerLink="/">dataconcepts</a>
            <p class="footer-description">
              We are an IT Consultancy that blends strategy, process and technology to deliver game changing outcomes for our clients.
            </p>
          </div>
        </div>

        <div class="column col-8 responsive">
          <div class="content-wrapper footer-wrapper">
            <ul class="footer-menu pseudo col-3">
              <span>
                <a class="footer-link-header" routerLink="/services">Services</a>
              </span>
              <li class="footer-menu-item">
                <a
                  class="footer-link"
                  routerLink="/services/application-development"
                >App Development</a>
              </li>
              <li class="footer-menu-item">
                <a
                  class="footer-link"
                  routerLink="/services/business-intelligence"
                >Business Intelligence</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/services/program-management">Program Management</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/services/machine-learning">ML & IoT</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/work/predictive-modeling">Predictive Modeling</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/services/ui-ux">UI / UX</a>
              </li>
            </ul>
            <ul class="footer-menu pseudo col-3">
              <span>
                <a class="footer-link-header" routerLink="/services">Data</a>
              </span>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/services/analytics">Analytics</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/services/data-strategy">Data Strategy</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/services/big-data">Big Data</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/services/data-governance">Governance</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/services/data-science">Data Science</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/services/data-visualization">Visualization</a>
              </li>
            </ul>

            <ul class="footer-menu pseudo col-3">
              <span>
                <a class="footer-link-header" routerLink="/work">Work</a>
              </span>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/work/estes-improvement">Trucking</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/work/jury-management">Judiciary</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/work/criminal-history">Public Safety</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/work/energy">Energy</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link" routerLink="/work/sex-registry">Non-Profit</a>
              </li>
            </ul>

            <ul class="footer-menu pseudo col-3">
              <li class="footer-menu-item">
                <a class="footer-link-header" routerLink="/about">About</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link-header" routerLink="/careers">Careers</a>
              </li>
              <li class="footer-menu-item">
                <a class="footer-link-header" routerLink="/contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
