<light-layout>
  <div class="__page-root">
    <hero name="Unified Data Processing Platform" kicker>
      <div class="cover-image hero-background tinted"></div>
      <back-to-button></back-to-button>
    </hero>
    <div class="sections">
      <app-summary color="#0e414c"
                   content="Our Client, a leading non-profit needed their data to get to a deeper understanding of what’s going on in their business. They needed a scalable data lake that could handle the size and complexity of a massive, growing data set. They turned to Data Concepts to guide them in designing and developing a technology architecture that would be cost effective, efficient, and yet flexible enough to meet their current and future needs.">
        <role [style.width.%]="100">
          <li>Big Data</li>
          <li>Data Lake Architecture</li>
          <li>Cloud Migration</li>
          <li>Kafka</li>
          <li>Scala</li>
          <li>Fast Data & IoT</li>
          <li>Spark</li>
          <li>AWS</li>
          <li>Docker</li>
          <li>S3</li>
          <li>Cassandra</li>
        </role>
      </app-summary>

      <challenge color="#0e414c"
                     content="Our client wanted to design and develop a scalable data lake that supports both, Batch & Stream data that works along with their platform. Also, the objective was to make a self-containing application that can be packaged into an image [Docker] and deployed on any cloud service or on-premise."></challenge>

      <solution-header content="accelerated digital transformation journey"></solution-header>

      <solution>
        <p>
          Data Concepts worked on two key parts –
          Fast API – An interface that provides various actions for processing Batch and streaming data. Also, they help in connecting information from Kafka & other static resources like Files, DB(s), etc. Microservice Service (based on Lagom) for Background Tasks – which was developed to take load off the processing engine for saving data to the data lake (S3 and Cassandra) and assign it to a separate node. The new platform and technologies recommended and architected by Data Concepts successfully did the following:
        </p>
        <ul class="pseudo">
          <li>- Created streaming and batch pipelines along with workflows, in the same platform.</li>
          <li>- Handled different sources of data like files, tables, streams (Kafka, Kinesis, etc) in the same platform.</li>
          <li>- Supported 100,000 streams with zero latency.</li>
        </ul>
      </solution>

      <outcome color="#0e414c"
                   content="We dramatically increased processing speed, saved money while scaling their business, and substantially increased storage capacity. If you are looking to build a Reactive Product with Scala, Akka, Play Framework or a Big Data Solution leveraging Spark, we are here to help."></outcome>
    </div>
  </div>
</light-layout>
