import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../_models/user';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private sessionUserKey: string = 'sessionUser';
  private tokenSubject: BehaviorSubject<string>;
  public token: Observable<string>;

  constructor(private http: HttpClient) {
    const user: User = JSON.parse(sessionStorage.getItem(this.sessionUserKey));
    let token: string;
    if (user && user.token) {
      token = user.token;
    }
    this.tokenSubject = new BehaviorSubject<string>(token);
    this.token = this.tokenSubject.asObservable();
  }

  public get tokenValue(): string {
    return this.tokenSubject.value;
  }

  login(username: string, password: string): Observable<HttpResponse<User>> {
    const formData = new FormData();
    formData.append("Username", username);
    formData.append("Password", password);

    let headers = new HttpHeaders();
    headers.append("Content-Type", 'application/json');

    return this.http.post<User>('/api/Users/Authenticate', formData, { observe: 'response', headers: headers });
  }

  logout() {
    // remove user from local storage and set token to null
    sessionStorage.removeItem(this.sessionUserKey);
    this.tokenSubject.next(null);
  }
}
