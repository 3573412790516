import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inconsistent-data',
  templateUrl: './inconsistent-data.component.html',
  styleUrls: ['./inconsistent-data.component.sass']
})
export class InconsistentDataComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
