import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-freight-movement',
  templateUrl: './freight-movement.component.html',
  styleUrls: ['./freight-movement.component.sass']
})
export class FreightMovementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
