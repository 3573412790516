import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'aside-left',
  templateUrl: './aside-left.component.html',
  styleUrls: ['./aside-left.component.sass']
})
export class AsideLeftComponent implements OnInit {
  @Input() name: string;
  @Input() content: string;
  
  constructor() { }

  ngOnInit() {
  }

}
