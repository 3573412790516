<default-layout>
    <div class="__page-root">
        <root-hero name="Hello."></root-hero>
        <div class="sections">
            <section class="padded-large black">
                <div class="container">
                    <div class="row">
                        <div class="column col-4 responsive">
                            <div class="content-wrapper text-white">
                                <h6 class="subtitle white">
                                    <span>contact</span>
                                </h6>
                            </div>
                        </div>
                        <div class="column col-8 responsive">
                            <div class="content-wrapper text-white">
                                <h1 class="contact-title">We're here for you.</h1>
                                <div class="contact-wrapper">
                                    <div class="contact">
                                        <h4 class="contact-subtitle">Become a Client.</h4>
                                        <a class="telephone" href="tel:1-804-968-4700">+1-804-968-4700</a>
                                        <a class="email"
                                            href="mailto:business@dataconcepts-inc.com">hello@dataconcepts-inc.com</a>
                                    </div>
                                    <div class="contact">
                                        <h4 class="contact-subtitle">Media Inquiries.</h4>
                                        <a class="telephone" href="tel:1-303-575-1000">+1-303-575-1000</a>
                                        <a class="email"
                                            href="mailto:abc@dataconcepts-inc.com">media@dataconcepts-inc.com</a>
                                    </div>
                                    <div class="contact">
                                        <h4 class="contact-subtitle">Join Us.</h4>
                                        <a class="telephone" href="tel:1-804-968-4700">+1-804-968-4700</a>
                                        <a class="email" href="mailto:hr@dataconcepts-inc.com">hr@dataconcepts-inc.com</a>
                                    </div>
                                    <div class="contact">
                                        <h4 class="contact-subtitle">Everything Else.</h4>
                                        <a class="telephone" href="tel:1-919-425-2300">+1-919-425-2300</a>
                                        <a class="email" href="mailto:abc@dataconcepts-inc.com">abc@dataconcepts-inc.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="black">
                <div class="offices-container">
                    <div class="office">
                        <span class="city">Richmond, VA.</span>
                        <span class="address">4405 Cox Rd</span>
                        <span class="address">Glen Allen VA 23060</span>
                        <a class="office-telephone" href="tel:1-804-968-4700">+1-804-968-4700</a>
                        <div class="demo-container clocks active bounce">
                            <article id="atl-clock" class="clock station">
                                <section class="hours-container">
                                    <section class="hours"></section>
                                </section>
                                <section class="minutes-container">
                                    <section class="minutes"></section>
                                </section>
                                <section class="seconds-container">
                                    <section class="seconds"></section>
                                </section>
                                <div class="center-dot"></div>
                            </article>
                        </div>
                    </div>
                    <div class="office">
                        <span class="city">Denver, CO.</span>
                        <span class="address">1490 Delgany St</span>
                        <span class="address">Suite 521 Denver CO 80202</span>
                        <a class="office-telephone" href="tel:1-303-575-1000">+1-303-575-1000</a>
                        <div class="demo-container clocks active bounce">
                            <article id="dnv-clock" class="clock station">
                                <section class="hours-container">
                                    <section class="hours"></section>
                                </section>
                                <section class="minutes-container">
                                    <section class="minutes"></section>
                                </section>
                                <section class="seconds-container">
                                    <section class="seconds"></section>
                                </section>
                                <div class="center-dot"></div>
                            </article>
                        </div>
                    </div>
                    <div class="office">
                        <span class="city">Raleigh, NC.</span>
                        <span class="address">4405 Cox Rd</span>
                        <span class="address">Glen Allen VA 23060</span>
                        <a class="office-telephone" href="tel:1-919-425-2300">+1-919-425-2300</a>
                        <div class="demo-container clocks active bounce">
                            <article id="atl-clock" class="clock station">
                                <section class="hours-container">
                                    <section class="hours"></section>
                                </section>
                                <section class="minutes-container">
                                    <section class="minutes"></section>
                                </section>
                                <section class="seconds-container">
                                    <section class="seconds"></section>
                                </section>
                                <div class="center-dot"></div>
                            </article>
                        </div>
                    </div>
                    <div class="office">
                        <span class="city">Lansing, MI.</span>
                        <span class="address">4405 Cox Rd</span>
                        <span class="address">Glen Allen VA 23060</span>
                        <a class="office-telephone" href="tel:1-571-827-5444">+1-571-827-5444</a>
                        <div class="demo-container clocks active bounce">
                            <article id="atl-clock" class="clock station">
                                <section class="hours-container">
                                    <section class="hours"></section>
                                </section>
                                <section class="minutes-container">
                                    <section class="minutes"></section>
                                </section>
                                <section class="seconds-container">
                                    <section class="seconds"></section>
                                </section>
                                <div class="center-dot"></div>
                            </article>
                        </div>
                    </div>
                    <div class="office">
                        <span class="city">Columbia, SC.</span>
                        <span class="address">606 Garden Arbor Ln</span>
                        <span class="address">Columbia SC 29072</span>
                        <a class="office-telephone" href="tel:1-803-336-3900">+1-803-336-3900</a>
                        <div class="demo-container clocks active bounce">
                            <article id="atl-clock" class="clock station">
                                <section class="hours-container">
                                    <section class="hours"></section>
                                </section>
                                <section class="minutes-container">
                                    <section class="minutes"></section>
                                </section>
                                <section class="seconds-container">
                                    <section class="seconds"></section>
                                </section>
                                <div class="center-dot"></div>
                            </article>
                        </div>
                    </div>
                    <div class="office">
                        <span class="city">Flemington, NJ.</span>
                        <span class="address">272 Old York Rd</span>
                        <span class="address">Flemingtion NJ 08822</span>
                        <a class="office-telephone" href="tel:1-803-336-3900">+1-803-336-3900</a>
                        <div class="demo-container clocks active bounce">
                            <article id="atl-clock" class="clock station">
                                <section class="hours-container">
                                    <section class="hours"></section>
                                </section>
                                <section class="minutes-container">
                                    <section class="minutes"></section>
                                </section>
                                <section class="seconds-container">
                                    <section class="seconds"></section>
                                </section>
                                <div class="center-dot"></div>
                            </article>
                        </div>
                    </div>
                    <div class="office">
                        <span class="city">Seattle, WA.</span>
                        <span class="address">1531 Grandview Place East, Suite A</span>
                        <span class="address">Seattle WA 98112</span>
                        <a class="office-telephone" href="tel:1-803-336-3900">+1-803-336-3900</a>
                        <div class="demo-container clocks active bounce">
                            <article id="sfo-clock" class="clock station">
                                <section class="hours-container">
                                    <section class="hours"></section>
                                </section>
                                <section class="minutes-container">
                                    <section class="minutes"></section>
                                </section>
                                <section class="seconds-container">
                                    <section class="seconds"></section>
                                </section>
                                <div class="center-dot"></div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <a class="join-us-link" routerLink="/careers">
                    <div class="container join-us-container">
                        <div class="row join-us-row">
                            <div class="column col-8 responsive center-content">
                                <div class="content-wrapper join-us-wrapper">
                                    <h1 class="join-us-header">Join Our Team.</h1>
                                </div>
                            </div>
                            <div class="column col-4 responsive center-content">
                                <div class="content-wrapper arrow-wrapper">
                                    <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="39.002px"
                                        height="26.005px" viewBox="286.5 382.498 39.002 26.005"
                                        enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                                        <g>
                                            <polygon fill="#fff"
                                                points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                                            </polygon>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </section>
        </div>
    </div>
</default-layout>
