<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Data Strategy" name="Data Strategy vs. Analytics Roadmap"
                summary="Let’s discuss the difference between data strategy and analytics roadmap.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
            <insight-pamphlet>
                <insight-sub-section class="subsection subsection-padding" name="What is a Data Strategy?"
                    content="An initial and high-level look at an organization’s objectives for investing in analytics, a data strategy helps businesses get an understanding of how to leverage data and what sorts of opportunities it could provide.  At a more granular level, there are a number of components that are important to include in a data strategy, including the following:">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="1."
                    content="Opportunities that can be enabled by data and analytics."></insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="2." content="Data content (what data do you have already?)">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="3." content="Quality and performance (how good is it?)">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="4."
                    content="Roles and responsibilities, ownership (who is going to handle what?)"></insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="5."
                    content="Data migration and integration  - will the data live in a data mart?"></insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="6." content="Security, privacy and licensing."></insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="7." content="Organizational capability model and evolution.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="8." content="Internal communication of the strategy."></insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="These factors cumulatively give organizations a picture of what they want out of an investment in analytics.  Many of these desired outcomes tend to be similar across all manner of industries and company sizes which makes sense, because businesses typically want the same thing:  to learn why things are happening and determine how to optimize those things in order to increase revenue.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="What is an Analytics Roadmap?"
                    content="An analytics roadmap is designed to translate the data strategy’s intent into a plan of action - something that outlines how to implement the strategy’s key initiatives. Creating this kind of framework allows you to evaluate the potential value of each strategic initiative (as well as what the constraints would be) so you don’t end up squandering time and resources on the wrong projects.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="At a very practical level, the roadmap considers all of the different everyday activities that will need to occur in order to bring the data strategy to fruition, as well as the key milestones and dependencies you’ll need to keep in mind. To give an example, your data strategy might say ‘We want to build internal capability.’ You have a goal, but you haven’t outlined how you’re going to get from here to there. Your analytics roadmap would then determine what skills you need, what kinds of people you want to hire, what the timeframe is, etc. You’re breaking down goals into actionable, bite-sized activities.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="One of the most important components of an analytics roadmap is determining the phasing of these various activities.  Rather than arbitrarily choosing a project to start on, you have to outline what needs to happen at what stage of the process in order to facilitate other activities down the road.">
                </insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
