import { Component, OnInit } from '@angular/core';
import { Job } from '../../_models/job';
import { JobLocation } from '../../_models/job-location';
import { CareerService } from '../../_services/career.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.sass']
})
export class JobDetailsComponent implements OnInit {
  job: Job;
  jobLocation: JobLocation;

  constructor(
    private route: ActivatedRoute,
    private careerService: CareerService) { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
    this.getJob();
  }

  getJob() {
    const id = + this.route.snapshot.paramMap.get('id');

    this.careerService.getJobByID(id)
      .subscribe(job => {
        this.job = job;
        this.getJobLocation(this.job.jobLocationId);
      });
  }

  getJobLocation(id: number) {
    this.careerService.getJobLocationByID(id)
      .subscribe(jobLocation => this.jobLocation = jobLocation);
  }

}
