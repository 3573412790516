import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'root-hero',
  templateUrl: './root-hero.component.html',
  styleUrls: ['./root-hero.component.sass']
})
export class RootHeroComponent implements OnInit {
  @Input() name: string;
  constructor() { }

  ngOnInit() {
  }

}
