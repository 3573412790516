<default-layout>
  <div class="__page-root">
    <root-hero name="Impact."></root-hero>
    <div id="work-section-beginning" class="sections">
      <section class="padded-small black">
        <div class="special-container">
		<div class="container hero row">
			<div class="">
				<p class="expertise_title text-white">Expertise In Action.</p>
				<div class="copy-wrapper text-wrapper text-white">
				</div>
			</div>
		</div>
          <div class="row">
            <div class="column col-12">
              <div class="content-wrapper work-wrapper">
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/criminal-history">
                    <div id="criminal-history" class="work-background"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Virginia State Police: Computerized Criminal History</h3>
                    </div>
					<span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/court">
                    <div id="court" class="work-background"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">
                        Supreme Court of Virginia: Data Warehouse Rearchitecture
                      </h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/circuit-courts">
                    <div id="circuit-courts" class="work-background"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">The Circuit Courts of Virginia: Legacy Modernization</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/vehicle-inspection">
                    <div class="work-background" id="vehicle-inspection"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Virginia State Police: Vehicle Inspection Program</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/magellan-health">
                    <div class="work-background" id="magellan-health"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Magellan Health: Single Sign On</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/web-portal">
                    <div class="work-background" id="web-portal"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Virginia State Police: Web Portal</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/sex-registry">
                    <div class="work-background" id="sex-registry"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Virginia Sex Offender Registry</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/first-union">
                    <div class="work-background" id="first-union"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">First Union Bank: Crowd-Sourcing</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/finance-portal">
                    <div class="work-background" id="finance-portal"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Courts Of Virginia: Finance Portal</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/server-migration">
                    <div class="work-background" id="server-migration"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">WebSphere Server Migration</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/estes-improvement">
                    <div class="work-background" id="estes-improvement"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Estes Trucking: Performance Improvement</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/freight-volume">
                    <div class="work-background" id="freight-volume"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Freight Volume Planning System</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/freight-movement">
                    <div class="work-background" id="freight-movement"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">FEMA: Freight Movement Missions</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/estes-quote">
                    <div class="work-background" id="estes-quote"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Estes Trucking: Quote Generation System</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/capital-one">
                    <div class="work-background" id="capital-one"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Capital One: Salesforce Data Load Automation</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/predictive-modeling">
                    <div class="work-background" id="predictive-modeling"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Predictive Modeling For Debt Collection</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/judicial-enforcement">
                    <div class="work-background" id="judicial-enforcement"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Judicial Enforcement Management System</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/jury-management">
                    <div class="work-background" id="jury-management"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Jury Management System</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/data-lake">
                    <div class="work-background" id="data-lake"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Cloud Data Lake: Business Transformation</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/scala-architecture">
                    <div class="work-background" id="scala-architecture"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Scala Architecture Migration</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/devops-transformation">
                    <div class="work-background" id="devops-transformation"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">DevOps Tranformation Strategy & Roadmap</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/unified-data">
                    <div class="work-background" id="unified-data"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Unified Data Processing Platform</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/energy">
                    <div class="work-background" id="energy"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Energy: Mobile Business Solution</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/firearms-application">
                    <div class="work-background" id="firearms-application"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Virginia State Police: Firearms Application</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/sabra">
                    <div class="work-background" id="sabra"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Sabra: Excel to .Net Conversion</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/energy-2">
                    <div class="work-background" id="energy-2"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Energy 2</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/capital-one-2">
                    <div class="work-background" id="capital-one-2"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Capital One: Financial Services</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
                <div class="work" data-aos="fade-up" data-aos-offset="-50">
                  <a class="work-link" routerLink="/work/vdss">
                    <div class="work-background" id="vdss"></div>
                    <div class="work-overlay">
                      <h3 class="text-white">Virginia Department of Social Services: Enterprise data report</h3>
                    </div>
                    <span class="more"></span>
                    <work-arrow></work-arrow>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section data-aos="fade-up" class="padded-large">
        <div class="container">
          <div class="row">
            <div class="column col-12">
              <div class="copy-wrapper">
                <h1 class="expertise_title text-black">Clients</h1>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column col-12">
              <div class="content-wrapper clientele-wrapper">
                <div class="clientele">
                  <img src="../../assets/image/client/nordstrom.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/cdot.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/colorado-state.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/advanced-auto.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/allianz.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/dcr.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/dominion.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/estes.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/federal-reserve.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/fidelity.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/hughes.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/learning-tree.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/magellan.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/pfg.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/red-cross.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/reuters.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/virginia-police.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/union.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/vdh.png" alt="logo">
                </div>
                <div class="clientele">
                  <img id="virginia-credit"
                       src="../../assets/image/client/virginia-credit.png"
                       alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/clarivate-analytics.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/sabra.png" alt="logo">
                </div>
                <div class="clientele">
                  <img src="../../assets/image/client/colorado-courts.png" alt="logo">
                </div>
                <div class="clientele">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <subscribe></subscribe>
      <hire-us-footer></hire-us-footer>
    </div>
  </div>
</default-layout>
