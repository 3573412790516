import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-security-bi',
  templateUrl: './data-security-bi.component.html',
  styleUrls: ['./data-security-bi.component.sass']
})
export class DataSecurityBiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
