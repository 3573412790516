<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Application Development" name="Application Development: Our Approach"
                summary="High performance, outcome-driven systems.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
            <insight-pamphlet>
                <insight-sub-section class="subsection subsection-padding" name="Our approach:"
                    content="Data Concepts’ approach to application development and maintenance extends beyond technology and cost reduction. We focus on delivering strategic business outcomes by helping clients fully maximize the value of their applications. Our application services cover all that is needed to build and maintain high-performance, outcome-driven applications that drive operational excellence and competitive advantage.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Client-centric Governance Model:"
                    content="Ensures a strong focus on the right outcomes at the right time, the agility to quickly adapt to changing conditions, and efficient and accountable project management that leads to operational excellence. Our IT governance model ensures your business strategies are aligned with your IT priorities. Our management framework fosters operational excellence in accountability, control and service delivery.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Innovation and Transformation:"
                    content="Drives the development of new ideas and breakthrough solutions through close collaboration with clients, insight and thought leadership. Whether implementing new digital systems and solutions or finding creative ways to digitalize clients’ current investments, our experts follow a practical and collaborative approach.  We collaborate and innovate with our clients while maintaining strong delivery excellence, and have the ability to align people, processes and technology to enable true digital transformation.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="Application Portfolio Management:"
                    content="Enables clients to go beyond simple assessment and optimize the entire application portfolio in line with business objectives through a comprehensive methodology and supporting tools that drive smarter investment strategies.">
                </insight-sub-section>
                <insight-sub-section class="subsection " name="Results-driven Performance:"
                    content="Uncovers the potential for business transformation by combining granular data analysis and collection with continuous business and IT alignment through Data Concepts’ advanced methodologies.">
                </insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
