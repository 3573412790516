<div class="__layout-root">
  <div class="admin-form-wrapper">
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit($event)">
      <h1>Register</h1>
      <input class="form-control" type="text" formControlName="firstName" placeholder="First Name" autofocus>
      <input class="form-control" type="text" formControlName="lastName" placeholder="Last Name">
      <input class="form-control" type="email" formControlName="email" placeholder="Email Address">
      <input type="password" formControlName="password" class="form-control" placeholder="Password">
      <input type="password" formControlName="passwordConfirm" class="form-control" placeholder="Confirm Password">
      <button class="btn btn-primary" type="submit">Register</button>
    </form>
    <div *ngIf="result">{{result}}</div>
  </div>
</div>
