import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InsightsComponent } from "../insights/insights.component";
import { AgileEmbraceComponent } from '../insights/agile-embrace/agile-embrace.component';
import { AgileTransformationComponent } from '../insights/agile-transformation/agile-transformation.component';
import { AgileUnderstandingComponent } from '../insights/agile-understanding/agile-understanding.component';
import { AnalyticsIsPopularComponent } from '../insights/analytics-is-popular/analytics-is-popular.component';
import { AnalyticsPowerComponent } from '../insights/analytics-power/analytics-power.component';
import { AnalyticsToLifeComponent } from '../insights/analytics-to-life/analytics-to-life.component';
import { ApplicationDevelopmentApproachComponent } from '../insights/application-development-approach/application-development-approach.component';
import { AzureMachineLearningComponent } from '../insights/azure-machine-learning/azure-machine-learning.component';
import { AzureMigrationComponent } from '../insights/azure-migration/azure-migration.component';
import { BiDataWarehousingComponent } from '../insights/bi-data-warehousing/bi-data-warehousing.component';
import { BigDataBusinessComponent } from '../insights/big-data-business/big-data-business.component';
import { BigDataLeverageComponent } from '../insights/big-data-leverage/big-data-leverage.component';
import { BigDataPlanComponent } from '../insights/big-data-plan/big-data-plan.component';
import { BlockchainWorldComponent } from '../insights/blockchain-world/blockchain-world.component';
import { CloudApplicationDevelopmentComponent } from '../insights/cloud-application-development/cloud-application-development.component';
import { DataAnalyticsChangeComponent } from '../insights/data-analytics-change/data-analytics-change.component';
import { InsightDataGovernanceComponent } from '../insights/data-governance/data-governance.component';
import { DataMiningComponent } from '../insights/data-mining/data-mining.component';
import { DataSecurityBiComponent } from '../insights/data-security-bi/data-security-bi.component';
import { DataSilosComponent } from '../insights/data-silos/data-silos.component';
import { DataStrategyRightComponent } from '../insights/data-strategy-right/data-strategy-right.component';
import { DataStrategyVsDataAnalyticsComponent } from '../insights/data-strategy-vs-data-analytics/data-strategy-vs-data-analytics.component';
import { DevopsRoadmapComponent } from '../insights/devops-roadmap/devops-roadmap.component';
import { DigitalCultureComponent } from '../insights/digital-culture/digital-culture.component';
import { DigitalLandscapeComponent } from '../insights/digital-landscape/digital-landscape.component';
import { DigitalTransformationComponent } from '../insights/digital-transformation/digital-transformation.component';
import { GovernanceJourneyComponent } from '../insights/governance-journey/governance-journey.component';
import { GovernancePathComponent } from '../insights/governance-path/governance-path.component';
import { GovernanceProcessComponent } from '../insights/governance-process/governance-process.component';
import { InconsistentDataComponent } from '../insights/inconsistent-data/inconsistent-data.component';
import { InformationArchitectureComponent } from '../insights/information-architecture/information-architecture.component';
import { ItAgilityComponent } from '../insights/it-agility/it-agility.component';
import { MdmVsDwComponent } from '../insights/mdm-vs-dw/mdm-vs-dw.component';
import { ModernDataArchitectureComponent } from '../insights/modern-data-architecture/modern-data-architecture.component';
import { ModernUtilityComponent } from '../insights/modern-utility/modern-utility.component';
import { PowerBiComponent } from '../insights/power-bi/power-bi.component';
import { QuickPeekComponent } from '../insights/quick-peek/quick-peek.component';
import { SharepointComponent } from '../insights/sharepoint/sharepoint.component';
import { SixCloudMigrationStrategiesComponent } from '../insights/six-cloud-migration-strategies/six-cloud-migration-strategies.component';
import { WhyGoDigitalComponent } from '../insights/why-go-digital/why-go-digital.component';

const insightsRoutes: Routes = [
  { path: "insights", component: InsightsComponent },
  { path: "insights/agile-embrace", component: AgileEmbraceComponent },
  { path: "insights/agile-transformation", component: AgileTransformationComponent },
  { path: "insights/agile-understanding", component: AgileUnderstandingComponent },
  { path: "insights/analytics-is-popular", component: AnalyticsIsPopularComponent },
  { path: "insights/analytics-power", component: AnalyticsPowerComponent },
  { path: "insights/analytics-to-life", component: AnalyticsToLifeComponent },
  { path: "insights/application-development-approach", component: ApplicationDevelopmentApproachComponent },
  { path: "insights/azure-machine-learning", component: AzureMachineLearningComponent },
  { path: "insights/azure-migration", component: AzureMigrationComponent },
  { path: "insights/bi-data-warehousing", component: BiDataWarehousingComponent },
  { path: "insights/big-data-business", component: BigDataBusinessComponent },
  { path: "insights/big-data-leverage", component: BigDataLeverageComponent },
  { path: "insights/big-data-plan", component: BigDataPlanComponent },
  { path: "insights/blockchain-world", component: BlockchainWorldComponent },
  { path: "insights/cloud-application-development", component: CloudApplicationDevelopmentComponent },
  { path: "insights/data-analytics-change", component: DataAnalyticsChangeComponent },
  { path: "insights/data-governance", component: InsightDataGovernanceComponent },
  { path: "insights/data-mining", component: DataMiningComponent },
  { path: "insights/data-security-bi", component: DataSecurityBiComponent },
  { path: "insights/data-silos", component: DataSilosComponent },
  { path: "insights/data-strategy-right", component: DataStrategyRightComponent },
  { path: "insights/data-strategy-vs-data-analytics", component: DataStrategyVsDataAnalyticsComponent },
  { path: "insights/devops-roadmap", component: DevopsRoadmapComponent },
  { path: "insights/digital-culture", component: DigitalCultureComponent },
  { path: "insights/digital-landscape", component: DigitalLandscapeComponent },
  { path: "insights/digital-transformation", component: DigitalTransformationComponent },
  { path: "insights/governance-journey", component: GovernanceJourneyComponent },
  { path: "insights/governance-path", component: GovernancePathComponent },
  { path: "insights/governance-process", component: GovernanceProcessComponent },
  { path: "insights/inconsistent-data", component: InconsistentDataComponent },
  { path: "insights/information-architecture", component: InformationArchitectureComponent },
  { path: "insights/it-agility", component: ItAgilityComponent },
  { path: "insights/mdm-vs-dw", component: MdmVsDwComponent },
  { path: "insights/modern-data-architecture", component: ModernDataArchitectureComponent },
  { path: "insights/modern-utility", component: ModernUtilityComponent },
  { path: "insights/power-bi", component: PowerBiComponent },
  { path: "insights/quick-peek", component: QuickPeekComponent },
  { path: "insights/sharepoint", component: SharepointComponent },
  { path: "insights/six-cloud-migration-strategies", component: SixCloudMigrationStrategiesComponent },
  { path: "insights/why-go-digital", component: WhyGoDigitalComponent },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(insightsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class InsightsRoutingModule { }
