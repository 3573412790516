import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonComponentsModule } from '../common-components.module';
import { InsightComponentsModule } from '../insights/insight-components.module';

// services components
import { PromiseComponent } from '../common/services/promise/promise.component';
import { ServicesCtrlComponent } from '../common/services/services-ctrl/services-ctrl.component';
import { SubServiceComponent } from '../common/services/sub-service/sub-service.component';
import { AsideRightComponent } from '../common/services/aside-right/aside-right.component';
import { DefinitionComponent } from '../common/services/definition/definition.component';
import { SubDefinitionComponent } from '../common/services/sub-definition/sub-definition.component';
import { AsideLeftComponent } from '../common/services/aside-left/aside-left.component';
import { CapabilitiesComponent } from '../common/services/capabilities/capabilities.component';
import { SubCapabilityComponent } from '../common/services/sub-capability/sub-capability.component';
import { AdditionalServicesComponent } from '../common/services/additional-services/additional-services.component';
import { InsightHighlightComponent } from '../common/services/insight-highlight/insight-highlight.component';
import { RelatedComponent } from '../common/services/related/related.component';
import { BackToServicesComponent } from '../common/services/back-to-services/back-to-services.component';
import { SectionDividerComponent } from '../common/services/section-divider/section-divider.component';
import { SrvPamphletComponent } from '../common/services/srv-pamphlet/srv-pamphlet.component';
import { VersoComponent } from '../common/services/verso/verso.component';
import { RectoComponent } from '../common/services/recto/recto.component';
import { TechnologyComponent } from '../common/services/technology/technology.component';
import { SubTechnologyComponent } from '../common/services/sub-technology/sub-technology.component';
import { BlockTextComponent } from '../common/services/block-text/block-text.component';

// pages
import { ServicesComponent } from "../services/services.component";
import { AnalyticsComponent } from '../services/analytics/analytics.component';
import { ApplicationDevelopmentComponent } from '../services/application-development/application-development.component';
import { ArtificialIntelligenceComponent } from '../services/artificial-intelligence/artificial-intelligence.component';
import { BigDataComponent } from '../services/big-data/big-data.component';
import { BlockchainComponent } from '../services/blockchain/blockchain.component';
import { BusinessIntelligenceComponent } from '../services/business-intelligence/business-intelligence.component';
import { CloudComponent } from '../services/cloud/cloud.component';
import { CyberSecurityComponent } from '../services/cyber-security/cyber-security.component';
import { DataGovernanceComponent } from '../services/data-governance/data-governance.component';
import { DataIntegrationComponent } from '../services/data-integration/data-integration.component';
import { DataScienceComponent } from '../services/data-science/data-science.component';
import { DataStrategyComponent } from '../services/data-strategy/data-strategy.component';
import { DataVisualizationComponent } from '../services/data-visualization/data-visualization.component';
import { DataWarehouseComponent } from '../services/data-warehouse/data-warehouse.component';
import { DevopsComponent } from '../services/devops/devops.component';
import { DigitalComponent } from '../services/digital/digital.component';
import { IotComponent } from '../services/iot/iot.component';
import { MachineLearningComponent } from '../services/machine-learning/machine-learning.component';
import { MasterDataManagementComponent } from '../services/master-data-management/master-data-management.component';
import { MicrosoftAzureComponent } from '../services/microsoft-azure/microsoft-azure.component';
import { MobilityComponent } from '../services/mobility/mobility.component';
import { ProgramManagementComponent } from '../services/program-management/program-management.component';
import { UiUxComponent } from '../services/ui-ux/ui-ux.component';

import { ServicesRoutingModule } from './services-routing.module';

@NgModule({
  declarations: [
    ServicesComponent,
    AnalyticsComponent,
    ApplicationDevelopmentComponent,
    ArtificialIntelligenceComponent,
    BigDataComponent,
    BlockchainComponent,
    BusinessIntelligenceComponent,
    CloudComponent,
    CyberSecurityComponent,
    DataGovernanceComponent,
    DataIntegrationComponent,
    DataScienceComponent,
    DataStrategyComponent,
    DataVisualizationComponent,
    DataWarehouseComponent,
    DevopsComponent,
    DigitalComponent,
    IotComponent,
    MachineLearningComponent,
    MasterDataManagementComponent,
    MicrosoftAzureComponent,
    MobilityComponent,
    ProgramManagementComponent,
    UiUxComponent,
    PromiseComponent,
    ServicesCtrlComponent,
    SubServiceComponent,
    AsideRightComponent,
    DefinitionComponent,
    SubDefinitionComponent,
    AsideLeftComponent,
    CapabilitiesComponent,
    SubCapabilityComponent,
    AdditionalServicesComponent,
    InsightHighlightComponent,
    RelatedComponent,
    BackToServicesComponent,
    SectionDividerComponent,
    SrvPamphletComponent,
    VersoComponent,
    RectoComponent,
    TechnologyComponent,
    SubTechnologyComponent,
    BlockTextComponent
  ],
  exports: [
    ServicesCtrlComponent,
    SubServiceComponent,
    AsideRightComponent,
    DefinitionComponent,
    SubDefinitionComponent,
    AsideLeftComponent,
    CapabilitiesComponent,
    SubCapabilityComponent,
    AdditionalServicesComponent,
    InsightHighlightComponent,
    RelatedComponent,
    BackToServicesComponent,
    SectionDividerComponent,
    SrvPamphletComponent,
    VersoComponent,
    RectoComponent,
    TechnologyComponent,
    SubTechnologyComponent,
    BlockTextComponent
  ],
  imports: [
    CommonModule,
    CommonComponentsModule,
    InsightComponentsModule,
    ServicesRoutingModule
  ]
})
export class ServicesModule { }
