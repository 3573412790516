import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sabra',
  templateUrl: './sabra.component.html',
  styleUrls: ['./sabra.component.sass']
})
export class SabraComponent implements OnInit {
  content: string;

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
