import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { ErrorInterceptor } from "./_helpers/error.interceptor";

import { AngularFullpageModule } from "@fullpage/angular-fullpage";

import { AppComponent } from "./app.component";

import { InsightComponentsModule } from './insights/insight-components.module';


// app modules
import { CommonComponentsModule } from './common-components.module';
import { WorkModule } from "./work/work.module";
import { ServicesModule } from "./services/services.module";
import { InsightsModule } from './insights/insights.module';
import { AppRoutingModule } from "./app-routing.module";

// pages
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { ContactComponent } from "./contact/contact.component";
import { CultureComponent } from './culture/culture.component';
import { CommunityServiceComponent } from './community-service/community-service.component';
import { HireUsComponent } from './hire-us/hire-us.component';
import { CareersComponent } from "./careers/careers.component";
import { JobDetailsComponent } from './careers/job-details/job-details.component';

import { CKEditorModule } from 'ckeditor4-angular';
import { AdminLayoutComponent } from './admin/admin-layout/admin-layout.component';
import { AdminModalMenuComponent } from './admin/admin-layout/admin-modal-menu/admin-modal-menu.component';

import { LoginComponent } from './admin/login/login.component';
import { RegisterComponent } from './admin/register/register.component';
import { AdminComponent } from './admin/admin.component';
import { ManageCareersComponent } from './admin/manage-careers/manage-careers.component';
import { JobComponent } from './admin/manage-careers/job/job.component';
import { AddJobComponent } from './admin/manage-careers/job/add-job/add-job.component';

import { JobLocationComponent } from './admin/manage-careers/job-location/job-location.component';
import { AddLocationComponent } from './admin/manage-careers/job-location/add-location/add-location.component';
import { ManageLocationsComponent } from './admin/manage-careers/manage-locations/manage-locations.component';
import { StateSelectComponent } from './common/state-select/state-select.component';
import { HomeHeroComponent } from './common/hero/home-hero/home-hero.component';
import { SvgTypewriterComponent } from './common/svg-typewriter/svg-typewriter.component';
import { CounterpageComponent } from './counterpage/counterpage.component';
import { AnimatedComponent } from './animated/animated.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CKEditorModule,
    AngularFullpageModule,
    CommonComponentsModule,
    WorkModule,
    ServicesModule,
    InsightsModule,
    AppRoutingModule,
    InsightComponentsModule
  ],
  declarations: [
    AppComponent,
    AboutComponent,
    ContactComponent,
    HomeComponent,
    CultureComponent,
    CommunityServiceComponent,
    HireUsComponent,
    CareersComponent,
    JobDetailsComponent,
    LoginComponent,
    ManageCareersComponent,
    AdminComponent,
    RegisterComponent,
    JobComponent,
    AdminLayoutComponent,
    AdminModalMenuComponent,
    AddJobComponent,
    JobLocationComponent,
    AddLocationComponent,
    ManageLocationsComponent,
    StateSelectComponent,
    HomeHeroComponent,
    SvgTypewriterComponent,
    CounterpageComponent,
    AnimatedComponent
  ],
  exports: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
