<div class="__page-root">
  <h1>Add New Position</h1>
  <h3 *ngIf="jobLocation">Location: {{jobLocation.city}}, {{jobLocation.state}}</h3>
  <form [formGroup]="jobForm" (submit)="onSubmit($event)">
    <input class="form-control" type="text" formControlName="title" style="width: 100%;margin:10px 0;" placeholder="Job Title" autofocus />
    <ckeditor formControlName="htmlContent" [config]="ckConfig"></ckeditor>
    <div class="admin-form-actions">
      <button class="btn btn-light" type="button" (click)="cancelAction()">Cancel</button>&nbsp;&nbsp;&nbsp;<button class="btn btn-primary" type="submit">Save</button>
    </div>
  </form>
</div>
