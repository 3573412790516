import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agile-transformation',
  templateUrl: './agile-transformation.component.html',
  styleUrls: ['./agile-transformation.component.sass']
})
export class AgileTransformationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
