<div class="container hero">
    <div class="row">
        <div class="column col-12">
            <div class="content-wrapper text-hero-wrapper">
                <h1 data-aos="fade-in" data-aos-duration="800" class="text-hero-title">{{name}}</h1>
            </div>
        </div>
        <hero-arrow fillColor="black"></hero-arrow>
        <div class="hero-line reverse"></div>
    </div>
</div>
