import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RootHeroComponent } from './common/hero/root-hero/root-hero.component';

// navigation
import { ModalMenuComponent } from "./common/nav/modal-menu/modal-menu.component";

// layout
import { DefaultLayoutComponent } from './common/layout/default-layout/default-layout.component';
import { HomeLayoutComponent } from './common/layout/home-layout/home-layout.component';
import { LightLayoutComponent } from './common/layout/light-layout/light-layout.component';

import { SubFooterComponent } from './common/footer/sub-footer/sub-footer.component';
import { BackToTopComponent } from './common/back-to-top/back-to-top.component';
import { MainFooterComponent } from './common/footer/main-footer/main-footer.component';
import { SubscribeComponent } from './common/footer/subscribe/subscribe.component';
import { HireUsFooterComponent } from './common/footer/hire-us-footer/hire-us-footer.component';

import { ServiceVideoComponent } from './common/services/service-video/service-video.component';
import { VideoComponent } from './common/video/video.component';

import { HeroArrowComponent } from "./common/arrow/hero-arrow/hero-arrow.component";
import { WorkArrowComponent } from "./common/arrow/work-arrow/work-arrow.component";

import { HeroComponent } from "./common/hero/hero.component";
import { JobAppComponent } from './common/job-app/job-app.component';

@NgModule({
  declarations: [
    RootHeroComponent,
    ModalMenuComponent,
    DefaultLayoutComponent,
    LightLayoutComponent,
    HomeLayoutComponent,
    SubFooterComponent,
    BackToTopComponent,
    VideoComponent,
    ServiceVideoComponent,
    MainFooterComponent,
    SubscribeComponent,
    HireUsFooterComponent,
    HeroArrowComponent,
    WorkArrowComponent,
    HeroComponent,
    JobAppComponent
  ],
  exports: [
    RootHeroComponent,
    ModalMenuComponent,
    DefaultLayoutComponent,
    LightLayoutComponent,
    HomeLayoutComponent,
    SubFooterComponent,
    BackToTopComponent,
    VideoComponent,
    ServiceVideoComponent,
    MainFooterComponent,
    SubscribeComponent,
    HireUsFooterComponent,
    HeroArrowComponent,
    WorkArrowComponent,
    HeroComponent,
    JobAppComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class CommonComponentsModule { }
