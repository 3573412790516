import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-architecture',
  templateUrl: './information-architecture.component.html',
  styleUrls: ['./information-architecture.component.sass']
})
export class InformationArchitectureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector(".__page-root").scrollIntoView(true);
  }

}
