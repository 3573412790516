<section data-aos="fade-up" data-aos-offset="-280" class="padded-bottom">
    <div class="container">
        <div class="row">
            <div class="column col-12">
                <div class="content-wrapper solution-wrapper">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </div>
</section>