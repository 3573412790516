import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'definition',
  templateUrl: './definition.component.html',
  styleUrls: ['./definition.component.sass']
})
export class DefinitionComponent implements OnInit {
  @Input() subtitle: string;
  @Input() white: string;
  @Input() isBlackBG: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
