import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'insight-pamphlet',
  templateUrl: './insight-pamphlet.component.html',
  styleUrls: ['./insight-pamphlet.component.sass']
})
export class InsightPamphletComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
