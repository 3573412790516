<section [ngClass]="{'padded-top': true, 'black': black}">
    <div class="container">
        <div class="row">
            <div class="column col-12">
                <div data-aos="fade" class="copy-wrapper">
                    <h1 [ngClass]="[black ? 'section-title medium text-white' : 'section-title medium']">{{name}}</h1>
                    <p [ngClass]="[black ? 'section-divider-text text-white' : 'section-divider-text ']">{{content}}</p>
                </div>
            </div>
        </div>
    </div>
</section>
