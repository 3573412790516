import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.sass']
})
export class RoleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
