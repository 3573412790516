<default-layout>
  <div class="__page-root">
    <root-hero name="Expertise."></root-hero>
    <div id="services-section-beginning" class="sections">
      <section class="black">
        <div class="content-wrapper services-wrapper">
          <div class="service">
            <a class="service-link" routerLink="/services/big-data"></a>
            <div id="big-data-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">Big Data</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/iot"></a>
            <div id="iot-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">IoT</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/cloud"></a>
            <div id="cloud-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">Cloud</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/business-intelligence"></a>
            <div id="bi-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">Business Intelligence</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/analytics"></a>
            <div id="data-analytics-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">Analytics</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/artificial-intelligence"></a>
            <div id="ai-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">Artificial Intelligence</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/blockchain"></a>
            <div id="blockchain-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">Blockchain</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/application-development"></a>
            <div id="app-dev-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">Application Development</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/mobility"></a>
            <div id="mobility-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">Mobility</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/machine-learning"></a>
            <div id="ml-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">Machine Learning</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/program-management"></a>
            <div id="program-management-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">Program Management</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/devops"></a>
            <div id="devops-background" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">DevOps</h3>
            </div>
          </div>

          <div class="service">
            <a class="service-link" routerLink="/services/digital"></a>
            <div id="digital-strategy-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">Digital</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/cyber-security"></a>
            <div id="cyber-security-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">Cyber Security</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/ui-ux"></a>
            <div id="ui-ux-bround" class="service-background"></div>
            <div class="service-content">
              <h3 class="service-title">UI/UX</h3>
            </div>
          </div>
        </div>
      </section>


      <section class="padded-xlarge black">
        <div class="container">
          <div class="row">
            <div class="column col-12">
              <div class="lets-talk-wrap">
                <svg id="letsTalk">
                  <defs>
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="10%" style="stop-color:#29d5ff;stop-opacity:1" />
                      <stop offset="90%" style="stop-color:#6c03a8;stop-opacity:1" />
                    </linearGradient>
                  </defs>
                  <text id="letsTalkText" fill="url(#grad1)" x="0" y="100">Let's talk data.</text>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="black">
        <div class="content-wrapper services-wrapper">
          <div class="service">
            <a class="service-link" routerLink="/services/data-strategy"></a>
            <div id="data-strategy-bround" class="service-background-2"></div>
            <div class="service-content">
              <h3 class="service-title">Data Engineering</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/data-warehouse"></a>
            <div id="data-ware-bround" class="service-background-2"></div>
            <div class="service-content">
              <h3 class="service-title">Data Warehouse</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/data-governance"></a>
            <div id="data-gov-bround" class="service-background-2"></div>
            <div class="service-content">
              <h3 class="service-title">Data Governance</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/master-data-management"></a>
            <div id="mdm-bround" class="service-background-2"></div>
            <div class="service-content">
              <h3 class="service-title">Master Data Management</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/data-visualization"></a>
            <div id="data-vis-bround" class="service-background-2"></div>
            <div class="service-content">
              <h3 class="service-title">Data Visualization</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/data-integration"></a>
            <div id="data-int-bround" class="service-background-2"></div>
            <div class="service-content">
              <h3 class="service-title">Data Integration</h3>
            </div>
          </div>
          <div class="service">
            <a class="service-link" routerLink="/services/data-science"></a>
            <div id="data-sci-bround" class="service-background-2"></div>
            <div class="service-content">
              <h3 class="service-title">Data Science</h3>
            </div>
          </div>
        </div>
      </section>
      <!--<a routerLink="/insights"
         class="full-page product-link inspire-product"
         style="display: block;">
        <div class="container">
          <div class="row">
            <div class="column col-7 responsive center-content">
              <div class="content-wrapper text-white product-wrapper">
                <h2 class="product-title join-us-header">See What Inspires Us.</h2>
                <div>
                  <svg class="arrow-thin-right__svg"
                       version="1.1"
                       id="Layer_1"
                       xmlns="http://www.w3.org/2000/svg"
                       x="0px"
                       y="0px"
                       width="39.002px"
                       height="26.005px"
                       viewBox="286.5 382.498 39.002 26.005"
                       enable-background="new 286.5 382.498 39.002 26.005"
                       title="Next">
                    <g>
                      <polygon fill="#fff"
                               points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>-->
      <section class="padded-small" style="position:relative;height:100vh;background-color: black">
        <div class="fp-bg mad-max" style="z-index: 1"></div>
        <div class="mad-max-menu-panel" style="z-index: 2;">
          <div class="mad-max-menu-wrapper">
            <ul class="mad-max-menu">
              <li><a class="text-white" href="">SOME WORDS</a></li>
              <li><a class="text-white" href="">PROJECTS</a></li>
              <li><a class="text-white" href="">TEAM</a></li>
              <li><a class="text-white" href="">CONTACT</a></li>
            </ul>
          </div>
        </div>
        <div class="container">
          <h3 class="come-join-us text-white" style="width: 100%;">
            <svg id="serviceTypewriterSvg">
              <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="10%" style="stop-color:#ff48a6;stop-opacity:1" />
                  <stop offset="90%" style="stop-color:#520881;stop-opacity:1" />
                </linearGradient>
                <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="10%" style="stop-color:#29d5ff;stop-opacity:1" />
                  <stop offset="90%" style="stop-color:#6c03a8;stop-opacity:1" />
                </linearGradient>
                <linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="10%" style="stop-color:#93d522;stop-opacity:1" />
                  <stop offset="90%" style="stop-color:#033e8e;stop-opacity:1" />
                </linearGradient>
              </defs>
              <text id="serviceTypewriterText" fill="url(#grad1)" x="0" y="100"></text>
            </svg>
          </h3>
        </div>
        <div class="mad-max-video-panel-backdrop"style="z-index: 2;"></div>
        <div class="mad-max-video-panel" style="z-index: 2;">
          <video id="aboutVideo" controls style="width:100%;" poster="/assets/video/abitaboutus.svg">
            <source src="/assets/video/dc5-3b.mp4" type="video/mp4">
          </video>
        </div>
      </section>


	  <section>
			<div class="section" style="height:100vh;">
			  <div class="fp-bg culture"></div>
			  <div class="section-wrapper">
				<div class="section-details">
				  <div class="">
<p class="product-subtitle text-white">About Us</p>
<h2 class="product-title text-white">Culture</h2>
<p class="product-text text-white">A dynamic, entrepreneurial culture makes <br>
Data Concepts teams extraordinarily effective</p>
<a class="section-box-link" routerLink="/culture">
					  <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
						x="0px" y="0px" width="39.002px" height="26.005px" viewBox="286.5 382.498 39.002 26.005"
						enable-background="new 286.5 382.498 39.002 26.005" title="Next">
						<g>
						  <polygon fill="#F0F0F0"
							points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
						  </polygon>
						</g>
					  </svg>
					</a>
				  </div>
				</div>
			  </div>
			</div>
		  </section>
	
	<!-- <a routerLink="/careers" class="full-page product-link join-product" style="display: block;">
        <div class="container">
          <div class="row">
            <div class="column col-7 responsive center-content">
              <div class="content-wrapper text-white product-wrapper">
                <div class="product-line-wrapper">
            <span>05.</span>
            &nbsp;
            <span class="product-line"></span>
            &nbsp;
            </div>
                <h2 class="product-title join-us-header">Join Our Team.</h2>
                <div>
                  <svg class="arrow-thin-right__svg"
                       version="1.1"
                       id="Layer_1"
                       xmlns="http://www.w3.org/2000/svg"
                       x="0px"
                       y="0px"
                       width="39.002px"
                       height="26.005px"
                       viewBox="286.5 382.498 39.002 26.005"
                       enable-background="new 286.5 382.498 39.002 26.005"
                       title="Next">
                    <g>
                      <polygon fill="#fff"
                               points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	"></polygon>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="staff-name">
          <p class="staff-name-name">Dan Watson</p>
          <p class="staff-name-title">Project Associate</p>
        </div>
      </a> -->
    </div>
  </div>
</default-layout>
