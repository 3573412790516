<light-layout>
    <div class="__page-root">
        <hero name="Circuit Courts of Virginia" kicker="Legacy Modernization.">
            <div class="cover-image hero-background tinted"></div>
            <back-to-button></back-to-button>
        </hero>
        <div class="sections">
            <app-summary
                color="#FF2828"
                content="Circuit Courts of Virginia is the civil court system for the Commonwealth of Virginia.">
                <role [style.width.%]="100">
                    <li>Javat</li>
                    <li>JEE</li>
                    <li>Spring</li>
                    <li>Struts</li>
                    <li>Hibernate</li>
                    <li>DB2</li>
                    <li>IBM WebSphere</li>
                    <li>Angular</li>
                    <li>React</li>
                </role>
            </app-summary>
            <challenge
                color="#FF2828"
                content="The Legacy Mainframe System needed to be converted into a Web-Based Case Management Application that would allow judicial users to manage all phases of case documents."></challenge>
            <solution-header content="an intricate legacy modernization into java/jee"></solution-header>
            <solution>
                <p>This was a high complexity, high risk large engagement completely managed and implemented by Data Concepts. It involved replacing a stable 20-year legacy system with a new web solution across the state.</p>
                <ul class="pseudo">
                    <li>- The Re-engineered system provided all business functions of the current legacy system while implementing additional improvements to increase productivity in courts.</li>
                    <li>- Multilevel security controls for accessing non-public information such as Sealed Indictments.</li>
                    <li>- Granular role-based security that scales to manage small, medium and large volume courts.</li>
                    <li>- On-Demand Interactive Docket creation along other ad-hoc reporting capabilities.</li>
                    <li>- Replace home grown services infrastructure with an industry solution based on open standards that provides both agility and flexibility.</li>
                    <li>- Customizable reports that support a variety of document formats.</li>
                    <li>- Comprehensive auditing capabilities to track any and all case inquiries and updates.</li>
                </ul>
            </solution>
            <outcome
                color="#FF2828"
                content="Data Concepts managed and executed this complex, highly sensitive and critical modernization and successfully deployed the new web-based system."></outcome>
        </div>
    </div>
</light-layout>
