<light-layout>
  <div class="__page-root">
    <hero name="Capital One" kicker="Financial Services.">
      <div class="cover-image hero-background tinted"></div>
      <back-to-button></back-to-button>
    </hero>
    <div class="sections">
      <app-summary color="#FF2828" content="Capital One is a bank holding company specializing in credit cards, auto loans, banking and savings
          accounts. It operates in three divisions – Credit Cards, Consumer Banking and Commercial Banking. It is
          the 5th largest credit card issuer by purchase volume in US with 75% of company’s revenue coming
          from credit cards, 14% coming from consumer banking and 11% from commercial banking.">
        <role [style.width.%]="100">
          <li>IBM DB2</li>
          <li>PostgreSQL</li>
          <li>Apache Spark</li>
          <li>AWS</li>
          <li>ELK</li>
        </role>
      </app-summary>
      <challenge color="#FF2828"
                 content="This project intends to enhance the offer catalog area of UNICA to capture monetary transaction data
                    related to specific offers and send that data to Offer Management environment (PostgreSQL).
                    We manage offer management environment where credit card offers are generated. The criteria that
                    we check upon if a customer is eligible when a customer requests a credit card are Credit score, balance
                    transfer, age of the account, no. of missed payments, type of customers. Based on these criteria, we
                    respond back to the customer on their eligibility for different types of credit cards."></challenge>
      <solution-header content="A reengineered enterprise data warehouse designed for establishing new credit card offers."></solution-header>
      <solution>
        <p>
          Our client required a scalable, extensible, reusable, secure and user-friendly data warehouse that allows
          for better productivity and growth. Considering the project timelines and the client’s IT investments, we
          proposed the following solution.
        </p>
        <ul class="pseudo">
          <li>- Implement Apache Spark framework to load historical data from source (UNICA) to target
              (PostgreSQL) and keep a backup of all the legacy data as the current legacy system (UNICA) was
              getting replaced by our own legacy system Ophelia.
          </li>
          <li>- Create an offer catalog table in PostgreSQL database. This table would contain all the offer
              information like Offer ID, Name, Offer Type, Offer Start Date, Offer End Date etc. PostgreSQL
              would be the sole enterprise warehouse where new offers are generated.</li>
          <li>- Leverage AWS services to sign-on with secure database connection and provide access to the
              data warehouse to different business groups within Capital One to have a seamless mechanism
              to generate new offers.</li>
          <li>- Test out the end to end connection with business teams if they can insert new offers into the
              offer catalog data warehouse and make required data checks to ensure there are no data
              discrepancies.</li>
          <li>- Leverage this application architecture for greater integration flexibility with other banking
              systems and external interfaces.</li>
          <li>- Develop components that conform to industry standard architecture norms and follow best
              practices to ensure database code reusability.
          </li>
        </ul>
      </solution>
      <outcome color="#FF2828"
               content="A re-engineered enterprise secure data warehouse which reduced the card application dependency on
legacy systems and eliminated multiple risks related to data risk, compliance and governance.

Designed for security and storage, this data warehouse would be the sole target to store all the new
credit card offers generated and is an extensible and scalable modern solution with reusable
components to meet current and future business needs."></outcome>
    </div>
  </div>
</light-layout>
