<style>
  .headline {
    font-family: Bower,Georgia,Times New Roman,serif;
    font-weight: 500;
    font-size: 3.25rem;
    line-height: 3.75rem;
    text-align: center;
    margin-bottom: 50px;
    width: 100%;
  }

  .container {
    z-index: 100
  }

  .featured-solutions__card--small {
    position: relative;
    transition: all 600ms
  }

    .featured-solutions__card--small .featured-solutions__animated-text {
      position: absolute;
      top: 1.5rem;
      left: 1.25rem;
      font-size: 2rem;
      transition: font-size 600ms;
      width: calc(100% - 1.25rem * 2);
      height: 10.9375rem;
      overflow: hidden
    }

      .featured-solutions__card--small .featured-solutions__animated-text:after {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%);
        bottom: 0;
        content: ' ';
        height: 3rem;
        left: 0;
        position: absolute;
        width: 100%;
        opacity: 1;
        transition: opacity 600ms
      }

    .featured-solutions__card--small .featured-solutions__placeholder-text {
      display: block;
      font-family: "Graphik", Helvetica, sans-serif, Lucida Sans Unicode;
      font-size: 1.125rem;
      font-weight: 500;
      opacity: 0
    }

    .featured-solutions__card--small .featured-solutions__card-description {
      font-size: .9375rem;
      display: block;
      opacity: 0;
      transition: opacity 600ms;
      position: relative;
      max-height: 9.375rem;
      margin-top: 1.25rem;
      margin-bottom: .75rem;
      padding-bottom: 3rem;
      overflow: hidden
    }

      .featured-solutions__card--small .featured-solutions__card-description p {
        font-size: .9375rem
      }

      .featured-solutions__card--small .featured-solutions__card-description:after {
        bottom: 0;
        content: " ";
        position: absolute;
        right: 0;
        left: unset;
        width: 30%;
        height: 1.44rem;
        background: linear-gradient(to right, rgba(249, 249, 249, 0) 0%, #f9f9f9 100%)
      }

    .featured-solutions__card--small:after {
      background-color: #c00;
      z-index: 2;
      transition: width 600ms
    }

    .featured-solutions__card--small:hover {
      border-bottom: 1px solid #D8D8D8;
      background-color: #F9F9F9
    }

      .featured-solutions__card--small:hover .textlink_cta:after {
        right: -.5rem
      }

      .featured-solutions__card--small:hover .featured-solutions__animated-text {
        font-size: 1.125rem;
        line-height: 1.44
      }

        .featured-solutions__card--small:hover .featured-solutions__animated-text:after {
          opacity: 0
        }

      .featured-solutions__card--small:hover .featured-solutions__card-description {
        opacity: 1
      }

      .featured-solutions__card--small:hover:after {
        width: 100%
      }

  .featured-solutions__card--full {
    padding: 0
  }

  .featured-solutions__card--no-animate.featured-solutions__card--small {
    transition: none
  }

    .featured-solutions__card--no-animate.featured-solutions__card--small:hover {
      background: none
    }

    .featured-solutions__card--no-animate.featured-solutions__card--small .featured-solutions__card-description {
      margin-top: 0;
      opacity: 1;
      max-height: 9.375rem
    }

      .featured-solutions__card--no-animate.featured-solutions__card--small .featured-solutions__card-description:after {
        content: "";
        position: absolute;
        right: 0;
        left: unset;
        width: 30%;
        height: 1.44rem;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.1), #fff 50%)
      }

  .featured-solutions__card {
    -webkit-box-align: left;
    -webkit-align-items: left;
    -ms-flex-align: left;
    align-items: left;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    line-height: 1.44;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 1.5rem 1.25rem;
    width: 100%;
    img

  {
    width: 3.75rem;
    height: 3.75rem;
    margin-bottom: 2rem;
  }

  .featured-solutions__card-description {
    color: #424242;
    font-size: 1rem;
    margin-bottom: 1rem;
    p

  {
    color: #424242;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.44;
  }

  }

  h4 {
    padding-bottom: 1rem;
  }

  &:hover {
    color: #000;
  }

  }

  .featured-solutions__card--small {
    border-bottom: 1px solid #D8D8D8;
    border-right: 1px solid #D8D8D8;
    border-left: 1px solid #D8D8D8;
    position: relative;
    background-color: #fff;
    min-height: 12.5rem;
    height: 100%;
    right: 1px;
    .featured-solutions__card-description

  {
    font-size: .9375rem;
  }

  .textlink {
    margin-bottom: 0;
    margin-top: auto;
    z-index: 1;
  }

  &:before {
    position: absolute;
    content: ' ';
    display: block;
    height: 3px;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #D8D8D8;
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: ' ';
    display: block;
    height: 3px;
    top: 0;
    left: 0;
    width: 0px;
  }

  &:hover {
    border-bottom: 1px solid #D8D8D8;
    border-color: #D8D8D8 !important;
    .textlink

  {
    color: #c00;
  }

  }

  &:focus {
    border-bottom: 1px solid #D8D8D8;
    border-color: #D8D8D8 !important;
    .textlink

  {
    color: #c00;
  }

  }

  .featured-solutions__placeholder-text {
    display: none;
  }

  }

  .featured-solutions__card--small.featured-solutions__card--no-animate {
    .featured-solutions__card-description

  {
    p

  {
    font-size: .9375rem;
  }

  }
  }

  .featured-solutions__card--full {
    padding: 0;
    background: none;
    .textlink

  {
    margin-top: auto;
    padding-bottom: 2rem;
    border-bottom: 1px solid #D8D8D8;
  }

  .featured-solutions__card-description {
    min-height: 50px;
  }

  }

  .featured-solutions__card--small:after {
    display: none
  }

  .home .column {
    position: relative;
    padding-bottom: 16%;
    width: 15%;
    margin: 0 auto;
  }

  .home .stats {
    background-attachment: fixed;
    background-color: rgba(105, 179, 69, 0.7);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    padding-top: 10vh;
    padding-bottom: 5rem;
    z-index: 5
  }

    .home .stats::after {
      content: '';
      background-color: rgba(255, 255, 255, 0.7);
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10
    }

    .home .stats .row {
      position: relative;
      z-index: 20;
      margin: 0 auto;
    }

    .home .stats .column {
      position: relative;
      padding-bottom: 16%
    }

      .home .stats .column:nth-child(5) .number {
        font-size: 6.5rem;
        top: 32%
      }

    .home .stats .content {
      background-color: #000;
      height: 100%;
      left: 5%;
      overflow: hidden;
      padding: 2em;
      position: absolute;
      -webkit-transition: all .25s ease-out;
      transition: all .25s ease-out;
      width: 90%
    }

      .home .stats .content::after {
        content: '';
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid white;
        bottom: 0;
        height: 0;
        position: absolute;
        right: 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transition: all .25s ease-out;
        transition: all .25s ease-out;
        width: 0
      }

      .home .stats .content .tile-front,
      .home .stats .content .tile-back {
        color: white;
        font-size: 1.5rem;
        height: 100%;
        left: 50%;
        line-height: 1.3;
        position: absolute;
        text-transform: uppercase;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        -webkit-transition: all .25s ease-out;
        transition: all .25s ease-out;
        width: 80%
      }

      .home .stats .content .tile-back {
        opacity: 0;
        -webkit-transform: translateX(-50%) translateY(-40%);
        transform: translateX(-50%) translateY(-40%)
      }

      .home .stats .content .number,
      .home .stats .content .title,
      .home .stats .content .tagline {
        position: absolute;
        z-index: 20
      }

      .home .stats .content .number {
        font-size: 7.25rem;
        font-weight: 300;
        line-height: 0;
        left: 50%;
        top: 32%;
        -webkit-transition: font-size .25s ease-out;
        transition: font-size .25s ease-out;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
      }

      .home .stats .content .title {
        bottom: 7%;
        left: .5em
      }

      .home .stats .content .tagline {
        color: #fefefe;
        bottom: 6%
      }

      .home .stats .content:hover {
        background-color: #000
      }

        .home .stats .content:hover .tile-front {
          opacity: 0;
          -webkit-transform: translateX(-50%) translateY(-60%);
          transform: translateX(-50%) translateY(-60%)
        }

        .home .stats .content:hover .tile-back {
          opacity: 1;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%)
        }

        .home .stats .content:hover::after {
          opacity: 0
        }

  .home .team {
    padding-bottom: 0
  }

    .home .team .swiper-container {
      width: 100%;
      max-height: 500px
    }

    .home .team .swiper-slide {
      background-color: #69b345;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 400px;
      max-height: 500px;
      font-size: 18px;
      text-align: center;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center
    }

      .home .team .swiper-slide::after {
        content: '';
        background-color: rgba(0, 0, 0, 0.7);
        display: block;
        position: absolute;
        z-index: 5;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        -webkit-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out
      }

      .home .team .swiper-slide.swiper-slide-active::after {
        background-color: transparent
      }

      .home .team .swiper-slide.swiper-slide-active a span {
        opacity: 1
      }

      .home .team .swiper-slide a {
        color: #fefefe;
        height: 100%;
        position: absolute;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
        width: 100%;
        z-index: 20
      }

        .home .team .swiper-slide a span {
          bottom: 1em;
          font-size: 1.25rem;
          font-weight: bold;
          left: 1em;
          position: absolute;
          opacity: 0
        }

  @media all and (max-width: 1440px) {
    .home .stats .column:nth-child(5) .number {
      font-size: 5.2rem
    }

    .home .stats .content .tile-front,
    .home .stats .content .tile-back {
      font-size: 1.35rem
    }

    .home .stats .content .number {
      font-size: 5.7rem
    }
  }

  @media screen and (max-width: 74.9375em) {
    .home .stats .column:nth-child(5) .number {
      font-size: 4.5rem
    }

    .home .stats .content .tile-front,
    .home .stats .content .tile-back {
      font-size: 1.2rem
    }

    .home .stats .content .number {
      font-size: 5rem
    }
  }

  @media screen and (max-width: 63.9375em) {
    .home .work .featured-left::before {
      width: 58%
    }

    .home .work .featured-right::after {
      width: 58%
    }

    .home .work .cs2 .content {
      padding-left: 2em
    }

    .home .work .secondary .column {
      min-height: 320px
    }

    .home .work .secondary h2 {
      font-size: 1.5rem
    }

    .home .stats .column {
      padding-bottom: 28%
    }

      .home .stats .column:nth-child(5) .number {
        font-size: 6.75rem
      }

    .home .stats .content .tile-front,
    .home .stats .content .tile-back {
      font-size: 1.5rem
    }

    .home .stats .content .number {
      font-size: 7.5rem
    }
  }

  @media screen and (max-width: 50.75em) {
    .home #site-header .hero h2 {
      font-size: 2rem
    }

      .home #site-header .hero h2 span {
        font-size: 3.5rem
      }

    .home .stats .column {
      padding-bottom: 30%
    }

      .home .stats .column:nth-child(5) .number {
        font-size: 5.75rem
      }

    .home .stats .content .tile-front,
    .home .stats .content .tile-back {
      font-size: 1.3rem
    }

    .home .stats .content .number {
      font-size: 6.75rem
    }
  }

  @media screen and (max-width: 40.4375em) {
    .home #site-header {
      background-attachment: unset;
      background-size: cover;
      min-height: 620px
    }

      .home #site-header .hero {
        width: 90%
      }

        .home #site-header .hero .container {
          top: 40%
        }

        .home #site-header .hero h2 {
          font-size: 1.7rem;
          word-break: normal;
          padding: 1.25em
        }

          .home #site-header .hero h2 span {
            font-size: 3rem
          }

    .home .about {
      padding-bottom: 1em
    }

      .home .about h1 {
        font-size: 1.5rem
      }

      .home .about .badges .clutch-badge {
        float: left;
        margin: 0;
        max-width: 50%
      }

    .home .work .case-study {
      padding: 0
    }

      .home .work .case-study .row {
        padding: 0
      }

      .home .work .case-study::before,
      .home .work .case-study::after {
        display: none
      }

      .home .work .case-study .block-content {
        background-color: #000;
        padding: 2em 1em;
        text-align: center
      }

        .home .work .case-study .block-content .content {
          left: 0;
          max-width: 100%;
          position: relative;
          -webkit-transform: none;
          transform: none
        }

        .home .work .case-study .block-content blockquote {
          margin: 2em 0
        }

      .home .work .case-study .block-image img {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
      }

    .home .work .featured-left:hover h2 {
      color: #BC2A46
    }

    .home .work .featured-right:hover h2 {
      color: #B3D88C
    }

    .home .work .secondary .secondary-left,
    .home .work .secondary .secondary-right {
      padding: 2em 1em
    }

      .home .work .secondary .secondary-left .content,
      .home .work .secondary .secondary-right .content {
        padding: 1em;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        -webkit-transform: none;
        transform: none
      }

      .home .work .secondary .secondary-left .button,
      .home .work .secondary .secondary-right .button {
        margin-bottom: 0
      }

    .home .stats {
      padding-top: 3rem;
      padding-bottom: 2rem
    }

      .home .stats .column {
        padding-bottom: 45%
      }

        .home .stats .column:nth-child(2) .tile-back {
          font-size: 1rem
        }

        .home .stats .column:nth-child(5) .number {
          font-size: 4.5rem
        }

      .home .stats .content .tile-front,
      .home .stats .content .tile-back {
        font-size: 1.15rem
      }

      .home .stats .content .number {
        font-size: 5.25rem
      }
  }

  .
</style>
<default-layout>
  <div class="__page-root">
    <root-hero name="Identity."></root-hero>
    <div class="sections">
      <section data-aos="fade-up" class="padded-large black">
        <div class="container">
          <div class="row">
            <div class="column col-3 responsive">
              <div class="content-wrapper text-white">
                <h6 class="subtitle white">
                  <span>who we are</span>
                </h6>
              </div>
            </div>
            <div class="column col-9 responsive">
              <div class="content-wrapper text-white">
                <h1 class="about-us-header">We drive digital transformation <br>& harness the power of data. </h1>
                <p class="about-us-text">
                  Data Concepts transforms companies by designing best-in-class systems, driven by strong
                  creative and rooted
                  in the technology, data, and prudent analytics strategies required for operational
                  excellence. Data Concepts
                  solves our clients' toughest challenges by providing unmatched consulting services in
                  data, analytics, digital
                  and cloud. We are a full-service, digitally-savvy collection of technical specialists.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!----------------------------------------------------->
      <section class="padded-large">
        <div class="container">
          <div class="row">
            <div class="column col-4">
              <div class="content-wrapper">
                <h6 class="subtitle">
                  <span>OUR PROMISE</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <img src="../../assets/image/about/halfscreen_2.jpg" width="90%">
        </div>
      </section>
      <!----------------What’s your goal today?------------------------------------->
      <section class="padded-top padded-large">
        <div class="container" style="margin-bottom:100px;">
          <h3 class="headline">
            What’s your goal today?
          </h3>
          <div class="row about_blocks_two_row">
            <div class="column col-4">
              <div class="about_blocks_two">
                <h4 class="about_blocks_two_animated_text">Harness the power of data and analytics</h4>
                <p class="arrow">
                  <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                       x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                       enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                    <g>
                      <polygon fill="#2251ff"
                               points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                      </polygon>
                    </g>
                  </svg>
                </p>
                <div class="about_blocks_two_placeholder_description">
                  <ul>
                    <li>
					<a routerLink="/services/analytics"> 
                      Analytics & Artificial Intelligence <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                               x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                               enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
					  </a>
                    </li>
                    <li>
					 
                      Data Transformation & Delivery <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                          x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                          enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
					 
                    </li>
                    <li>
					<a routerLink="/services/digital">
                      Digital Strategy <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                            x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                            enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
					  </a>
                    </li>
                    <li>
						<a routerLink="/services/iot"> 
                      Internet of Things <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                              x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                              enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
					  </a>
                    </li>
                    <li>
						<a routerLink="/services/artificial-intelligence"> 
                      Robotics & Automation <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                 x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                 enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
					  </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!----------------------------------------------->
            <div class="column col-4">
              <div class="about_blocks_two">
                <h4 class="about_blocks_two_animated_text">Build a digital culture</h4>
                <p class="arrow">
                  <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                       x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                       enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                    <g>
                      <polygon fill="#2251ff"
                               points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                      </polygon>
                    </g>
                  </svg>
                </p>
                <div class="about_blocks_two_placeholder_description">
                  <ul>
                    <li>
                      Digital Culture & Capabilities<svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                         x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                         enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Digital Organization <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Full-Scale Transformation <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                     x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                     enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Enterprise Agility <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                              x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                              enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <!----------------------------------------------->
            <div class="column col-4">
              <div class="about_blocks_two">
                <h4 class="about_blocks_two_animated_text">Modernize core technology</h4>
                <p class="arrow">
                  <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                       x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                       enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                    <g>
                      <polygon fill="#2251ff"
                               points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                      </polygon>
                    </g>
                  </svg>
                </p>
                <div class="about_blocks_two_placeholder_description">
                  <ul>
                    <li>
                      Technology Transformation <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                     x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                     enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Infrastructure & Cloud <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                  x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                  enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Enterprise Architecture <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                   x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                   enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Cybersecurity <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                         x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                         enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Agile Delivery <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                          x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                          enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Technology Sourcing <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                               x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                               enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!----------------------------------------------->
            <div class="column col-4">
              <div class="about_blocks_two">
                <h4 class="about_blocks_two_animated_text">Create stunning digital experiences</h4>
                <p class="arrow">
                  <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                       x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                       enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                    <g>
                      <polygon fill="#2251ff"
                               points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                      </polygon>
                    </g>
                  </svg>
                </p>
                <div class="about_blocks_two_placeholder_description">
                  <ul>
                    <li>
                      End-to-End Journey Redesign <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                       x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                       enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Experience Design <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                             x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                             enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!----------------------------------------------->
            <div class="column col-4">
              <div class="about_blocks_two">
                <h4 class="about_blocks_two_animated_text">Turbo-charge marketing & sales</h4>
                <p class="arrow">
                  <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                       x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                       enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                    <g>
                      <polygon fill="#2251ff"
                               points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                      </polygon>
                    </g>
                  </svg>
                </p>
                <div class="about_blocks_two_placeholder_description">
                  <ul>
                    <li>
                      Digital Marketing Operations & Technology <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                                     x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                                     enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Digital Sales <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                         x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                         enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Dynamic E-commerce Pricing <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                      x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                      enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Personalization at Scale <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                    x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                    enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!----------------------------------------------->
            <div class="column col-4">
              <div class="about_blocks_two">
                <h4 class="about_blocks_two_animated_text">Optimize your operations</h4>
                <p class="arrow">
                  <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                       x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                       enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                    <g>
                      <polygon fill="#2251ff"
                               points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                      </polygon>
                    </g>
                  </svg>
                </p>
                <div class="about_blocks_two_placeholder_description">
                  <ul>
                    <li>
                      Corporate Functions <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                               x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                               enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Enterprise Resource Planning <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                        x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                        enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      IT Project Optimization <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                   x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                   enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Lean IT <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                   x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                   enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                    <li>
                      Digitizing Operations <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                 x="0px" y="0px" width="25px" height="15px" viewBox="286.5 382.498 39.002 26.005"
                                                 enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                        <g>
                          <polygon fill="#F0F0F0"
                                   points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                          </polygon>
                        </g>
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!----------------------------------------------->

          </div>

        </div>
      </section>
      <!-------------------------how we do it---------------------------->
      <section data-aos="fade-up" class="padded-top padded-large black">
        <div class="container">
          <div class="row">
            <div class="column col-4 responsive">
              <div class="content-wrapper text-white">
                <h6 class="subtitle white">
                  <span>how we do it</span>
                </h6>
              </div>
            </div>
            <div class="column col-8 responsive-8">
              <div class="content-wrapper text-white">
                <div class="outer">
                  <h1 class="how-title">Agility.</h1>
                  <p class="how-copy">
                    We believe in a seamless client partnership that connects us into a
                    single unified team. We're not only agile in code but in discovery, planning and
                    design.
                  </p>
                </div>
                <div class="outer">
                  <h1 class="how-title">Team.</h1>
                  <p class="how-copy">
                    Our dedicated team model ensures that every project receives the
                    meticulous attention and resource it deserves from our Strategists, Architects,
                    Designers & Engineers.
                  </p>
                </div>
                <div class="outer">
                  <h1 class="how-title">Collaboration.</h1>
                  <p class="how-copy">
                    Nothing we do is in isolation. Our process is extremely
                    collaborative, leveraging our clients unique perspectives to activate our own
                    expertise.
                  </p>
                </div>
                <div class="outer">
                  <h1 class="how-title">Iteration.</h1>
                  <p class="how-copy">
                    Even if the first try is a hit, we believe that constant analysis
                    and adjustment helps us realize the best possible version of whatever we're
                    delivering.
                  </p>
                </div>
                <div class="outer">
                  <h1 class="how-title">Results.</h1>
                  <p class="how-copy">
                    More than how a product looks, we care about how it works. Our
                    results-driven approach guides every aspect of the engagement from technical
                    discovery to deployment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!---------------------Data and Analytics-------------------------------->
      <section class="padded-large">
        <div class="container">
          <div class="col-6 column responsive center-content" style="background-color: #F4F4F4;padding:60px; height:50vh">
            <div style="">
              <h1 style="color:#002856;font-size: 50px;
    line-height: 55px;
    font-weight: 700;">
                Data and Analytics<br>for Digital <br>Transformation
              </h1>
              <div class="text">
                <p style="color: rgb(0,40,86); font-size: 26px;
    line-height: 32px;
    margin-bottom: 20px;" class="p-intro">Digital business success begins with strong data analytics leadership and promoting data literacy</p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="data_analytics_bg">
              <img src="../../assets/image/about/hero-photo-meeting-consult-data.jpg">
            </div>
          </div>
        </div>
      </section>
	  <!-----------------------------------------insights----------------------------------->
	  <section class="black padded-large">
                <div class="container">
                   <div class="row">
					<div class="column col-4 responsive">
						<div class="content-wrapper text-white">
							<h6 class="subtitle white">
								<span>OUR INSIGHTS</span>
							</h6>
						</div>
					</div>
                        <div class="column col-12">
                            <div class="content-wrapper insights-wrapper">
                                <insight-container class="insight" data-aos="fade-up" name="A Quick Peek in to  Data Strategy"
                                    summary="Every business and organisation has its own unique set of goals, KPIs and targets, and we believe that everything that we do should be devised, planned and executed with these objectives in mind."
                                    category="Data Strategy" uri="/insights/quick-peek">
                                    <span class="insight-background quick-peek"></span>
                                </insight-container>
                                <insight-container class="insight" data-aos="fade-up" uri="/insights/blockchain-world" category="Blockchain"
                                    name="Our Insights on the World of Blockchain"
                                    summary="Blockchain is one of the most dynamic inventions of this century. Though this revolutionary technology is still in its infancy, it has the potential to change how the world works.">
                                    <span class="insight-background blockchain"></span>
                                </insight-container>
                                <insight-container class="insight" data-aos="fade-up" uri="/insights/data-mining" category="Big Data"
                                    name="Extracting Actionable Insights from Data"
                                    summary="Data mining is the extraction of actionable insights from data.">
                                    <span class="insight-background entry"></span>
                                </insight-container>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      <!------------------------We champion the bold to achieve----------------------------->
      <a routerLink="/services" class="full-page product-link join-product" style="display: block;">
        <div class="container text-black">
          <div class="row">
            <div class="column col-7 responsive center-content">
              <div class="content-wrapper text-black product-wrapper">
                <h2 class="product-title text-black join-us-header">" We champion the bold to achieve the extraordinary."</h2>
                <div>
                  <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                       x="0px" y="0px" width="39.002px" height="26.005px" viewBox="286.5 382.498 39.002 26.005"
                       enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                    <g>
                      <polygon fill="#fff"
                               points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                      </polygon>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="staff-name text-black">
          <p class="staff-name-name">Chad Taylor</p>
          <p class="staff-name-title">Client Solutions</p>
        </div>
      </a>
      <!------------------------WHAT WE BELIEVE----------------------------->
      <section class="padded-large full-page black text-white">
        <div class="container">
          <div class="row">
            <div class="column col-4 responsive">
              <div class="content-wrapper text-white">
                <h6 class="subtitle white">
                  <span>WHAT WE BELIEVE</span>
                </h6>
              </div>
            </div>
            <div class="column col-12">
              <div data-aos="fade-up"
                   class="content-wrapper services-wrapper aos-init">

                <div class="subservice">
                  <h2>Bold steps define the future</h2>
                  <p>That the ambition to lead requires the courage <br>to adapt</p>
                </div>
                <div class="subservice">
                  <h2>Everyone is Bold</h2>
                  <p>We seek out people and clients who <br>challenge themselves to be exceptional</p>
                </div>

                <div class="subservice">
                  <h2>Bold Collaboration solves</h2>
                  <p>We come together to act on challenges, <br>and champion that spirit in others</p>
                </div>

                <div class="subservice">
                  <h2>Bold Thinking</h2>
                  <p>We combine bold thinking with a practical <br>approach that focuses on getting the job done</p>
                </div>

                <div class="subservice">
                  <h2>Innovation starts with people</h2>
                  <p>Our unwavering bold commitment to always <br>do the right thing by our clients, people and communities</p>
                </div>

                <div class="subservice">
                  <h2>Bold Honesty & Openness</h2>
                  <p>We have the candor to tell it like it is. We remain open to the possibility that current beliefs could <br>be wrong</p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <!-------------------------A FULL SERVICE DISRUPTOR---------------------------->
      <section data-aos="fade-up" class="section padded-small" style="height:100vh">
        <div class="container">
          <div class="row">
            <div class="column col-9 responsive">
              <div class="section-wrapper">
                <h1 class="about-us-header color_blue hero--medium-height">
                  <span style="font-weight: 300;color:#444444;">
                    A FULL SERVICE <br>DISRUPTOR
                  </span>
                </h1>
                <p class="about-us-text">
                  Whether project-specific or global, agency-of-record work, Data Concepts is uniquely positioned to
                  deliver strategic, growth-minded, and results-driven services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-----------------------------The advantage of a unified approach------------------------>
      <section class="padded-large" style="background:#f2f2f2;">
        <div class="aboutblocks container" data-mobile-carousel="">
          <div class="row">
            <div class="column col-4">
              <div class="content-wrapper">
                <h6 class="subtitle">
                  <span>OUR SERVICES</span>
                </h6>
              </div>
            </div>
          </div>
          <p class="p_advantage">The advantage of a unified approach to reinvention</p>
          <p class="about-us-text">
            Rely on our global network of people – supported by powerful assets, platforms and
            partnerships – to help you move swiftly from idea to execution.
          </p>
          <div class="row">
            <div class="column col-3">
              <div class="about_blocks">
                <h4 class="about_blocks_animated_text">Agile Services</h4>
                <span class="about_blocks_placeholder_text">Agile Services</span>
                <div class="about_blocks_placeholder_description">
                  <p>
                    Agile is a proven way to transform your business, catalyze innovation, and accelerate profitable
                    growth. We can help you scale Agile across your entire organization to improve time-to-market, boost
                    quality, raise employee morale, and become a truly adaptive organization.
                  </p>
                </div>
                <span class="textlink textlink_cta">
                  <a href="">More</a>
                </span>
              </div>
            </div>

            <div class="column col-3">
              <div class="about_blocks">
                <h4 class="about_blocks_animated_text">Digital Transformation</h4>
                <span class="about_blocks_placeholder_text">Digital Transformation</span>
                <div class="about_blocks_placeholder_description">
                  <p>
                    We deliver immediate, tangible benefits that propel you into your digital future.Customer habits
                    and expectations are changing. Ecosystems are evolving.
                  </p>
                </div>
                <span class="textlink textlink_cta">
                  <a routerLink="/services/digital">More</a>
                </span>
              </div>
            </div>

            <div class="column col-3">
              <div class="about_blocks">
                <h4 class="about_blocks_animated_text">Enterprise Technology</h4>
                <span class="about_blocks_placeholder_text">Enterprise Technology</span>
                <div class="about_blocks_placeholder_description">
                  <p>
                    We will transform your technology and address your evolving infrastructure, from developing a
                    transformation roadmap to system migration & architecture modernization
                  </p>
                </div>
                <span class="textlink textlink_cta">
                  <a routerLink="/services/application-development">More</a>
                </span>
              </div>
            </div>

            <div class="column col-3">
              <div class="about_blocks">
                <h4 class="about_blocks_animated_text">Strategy</h4>
                <span class="about_blocks_placeholder_text">Strategy</span>
                <div class="about_blocks_placeholder_description">
                  <p>
                    We offer solutions that have helped our clients across all sectors develop and execute winning
                    strategies. We’ve been a strategy thought leader for nearly two decades.
                  </p>
                </div>
                <span class="textlink textlink_cta">
                  <a routerLink="/insights">More</a>
                </span>
              </div>
            </div>

            <div class="column col-3">
              <div class="about_blocks">
                <h4 class="about_blocks_animated_text">Analytics</h4>
                <span class="about_blocks_placeholder_text">Analytics</span>
                <div class="about_blocks_placeholder_description">
                  <p>
                    We apply the latest analytics techniques and tools to help you extract value from all your data
                    assets, no matter their location or format; and generate insights that yield better, faster
                    decisions. From customer experience to employee engagement, our Advanced Analytics team can help you
                    transform your operations and transform your data into a perpetual source of value.
                  </p>
                </div>
                <span class="textlink textlink_cta">
                  <a href="">More</a>
                </span>
              </div>
            </div>

            <div class="column col-3">
              <div class="about_blocks">
                <h4 class="about_blocks_animated_text">Automation</h4>
                <span class="about_blocks_placeholder_text">Automation</span>
                <div class="about_blocks_placeholder_description">
                  <p>
                    We will help your organization map and accelerate your automation journey. We work collaboratively
                    with your business and technical teams to identify and prioritize the right automation
                    opportunities, develop the business case, conduct proof-of-concepts and pilots, and support fully
                    scaled deployments.
                  </p>
                </div>
                <span class="textlink textlink_cta">
                  <a href="">More</a>
                </span>
              </div>
            </div>

            <div class="column col-3">
              <div class="about_blocks">
                <h4 class="about_blocks_animated_text">Data Management</h4>
                <span class="about_blocks_placeholder_text">Data Management</span>
                <div class="about_blocks_placeholder_description">
                  <p>
                    we will help you harness the power of transformative technologies to help businesses chart a clear
                    vision and develop a new data supply chain—all by instilling greater trust in data and exploring
                    improved ways to manage it. Data is the fuel of the new economy and the driving force of the digital
                    era. We help organizations transform data—from dark to dynamic—and build trust into their data to
                    achieve breakthrough results in this new age of intelligence.
                  </p>
                </div>
                <span class="textlink textlink_cta">
                  <a href="">More</a>
                </span>
              </div>
            </div>

            <div class="column col-3">
              <div class="about_blocks">
                <h4 class="about_blocks_animated_text">Application Services</h4>
                <span class="about_blocks_placeholder_text">Application Services</span>
                <div class="about_blocks_placeholder_description">
                  <p>
                    We will help you to reinvent your enterprise application portfolio, making use of emerging
                    technology and building with speed and agility to enable you to meet business needs, now and in the
                    future. From the development of new applications, through modernization, management and maintenance,
                    we cover all stages of the application lifecycle.
                  </p>
                </div>
                <span class="textlink textlink_cta">
                  <a href="">More</a>
                </span>
              </div>
            </div>
          </div>

        </div>
      </section>
      <!--------------------------180--------------------------->
      <section class="padded-top padded-large">
        <div class="container">
          <div class="row factoid">
            <div class="item column col-4 responsive">
              <div class="text-wrapper">
                <h2 class="headline  -color-none"> &gt;180 </h2>
                <h3 class="sub-headline -color-none"> implementation consultants</h3>
                <p class="description">around the globe with extensive industry and functional expertise, who coach and drive day-to-day work to ensure execution excellence</p>
              </div>
            </div>
            <div class="item column col-4 responsive">
              <div class="text-wrapper">
                <h2 class="headline  -color-none"> &gt;74 </h2>
                <h3 class="sub-headline -color-none"> capability building experts </h3>
                <p class="description">with deep expertise in digital, analytics and data engineering, and at-scale capability building, including a design center of excellence with over 40 architects</p>
              </div>
            </div>
            <div class="item column col-4 responsive">
              <div class="text-wrapper">
                <h2 class="headline  -color-none"> &gt;58 </h2>
                <h3 class="sub-headline -color-none"> leadership facilitators and coaches</h3>
                <p class="description">specialized in working with senior leaders to drive sustainable change, with deep expertise in transforming behavior and proven business leadership experience

</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!----------------------SOME WORDS------------------------------->
      <section class="padded-large" style="position:relative;height:100vh;">
        <div class="fp-bg mad-max"></div>
        <div class="mad-max-menu-panel">
          <div class="mad-max-menu-wrapper">
            <ul class="mad-max-menu">
              <li><a class="text-black" routerLink="/work">OUR WORK</a></li>
              <li><a class="text-black" routerLink="/services">OUR EXPERTISE</a></li>
              <li><a class="text-black" routerLink="/careers">JOIN US</a></li>
              <li><a class="text-black" routerLink="/culture">OUR CULTURE</a></li>
              <li><a class="text-black" routerLink="/contact">SAY HELLO</a></li>
            </ul>
          </div>
        </div>
        <div class="container">
          <h3 class="come-join-us text-white" style="width: 100%;">
            <!-- <svg id="aboutTypewriterSvg">
              <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="10%" style="stop-color:#ff48a6;stop-opacity:1" />
                  <stop offset="90%" style="stop-color:#520881;stop-opacity:1" />
                </linearGradient>
                <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="10%" style="stop-color:#29d5ff;stop-opacity:1" />
                  <stop offset="90%" style="stop-color:#6c03a8;stop-opacity:1" />
                </linearGradient>
                <linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="10%" style="stop-color:#93d522;stop-opacity:1" />
                  <stop offset="90%" style="stop-color:#033e8e;stop-opacity:1" />
                </linearGradient>
              </defs>
              <text id="aboutTypewriterText" fill="url(#grad1)" x="0" y="100"></text>
            </svg> -->
          </h3>
        </div>
        <div class="mad-max-video-panel-backdrop"></div>
        <div class="mad-max-video-panel">
          <video id="aboutVideo" controls style="width:100%;" poster="/assets/video/abitaboutus.svg">
            <source src="/assets/video/dc5-3b.mp4" type="video/mp4">
          </video>
        </div>
      </section>
      <!------------------------Technologists, Strategists, Creatives, & Engineers.----------------------------->
      <section id="people" class="padded-large">
        <div class="container">
          <div class="row">
            <div class="column col-4">
              <div class="content-wrapper">
                <h6 class="subtitle">
                  <span>our people</span>
                </h6>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column col-12">
              <div class="copy-wrapper">
                <h1 class="staff-header">Technologists, Strategists, Creatives, & Engineers.</h1>
              </div>
            </div>
          </div>
        </div>
	</section>
        <section class="white padded-large">
          <div class="content-wrapper services-wrapper padding-b-100">
            <div class="person">
              <div class="person-img staff-sean"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Cyber Security Architect</h4>
                <h4 class="person-name">Sean</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-chad-taylor"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Client Services Lead</h4>
                <h4 class="person-name">Chad Taylor</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-claire-jackson"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Office Manager</h4>
                <h4 class="person-name">Dana Chava</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-varun-mehta"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Managing Director</h4>
                <h4 class="person-name">Varun Mehta</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-zhichao-xu"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Full Stack Developer</h4>
                <h4 class="person-name">Zichao Xu</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-barbara-philips"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Re-engineering Analyst</h4>
                <h4 class="person-name">Barbara Philips</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-dan-watson"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Project Associate</h4>
                <h4 class="person-name">Dan Watson</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-sita-chava"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Solutions Architect</h4>
                <h4 class="person-name">Sita Chava</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-ken-jeter"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Data Architect</h4>
                <h4 class="person-name">Ken Jeter</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-brian-gagliardi"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Program Director</h4>
                <h4 class="person-name">Brian Gagliardi</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-manju-reddy"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Enterprise Architect</h4>
                <h4 class="person-name">Manju Reddy</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-matt-couch"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Process Re-Engineering Architect</h4>
                <h4 class="person-name">Matt Couch</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-anoop-shashidhar"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Data Engineering &amp; ML Architect</h4>
                <h4 class="person-name">A. Shashidhar</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-sujeet-s"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Big Shot</h4>
                <h4 class="person-name">Sujeet S.</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-jim-noble"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Software Architect</h4>
                <h4 class="person-name">Jim Noble</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-chad-taylor"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Project Associate</h4>
                <h4 class="person-name">Chad Taylor</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-anirban-basu"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Solutions Architect</h4>
                <h4 class="person-name">Anirban Basu</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-dana-chava"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Account Manager</h4>
                <h4 class="person-name">Dana Chava</h4>
              </div>
            </div>
            <div class="person">
              <div class="person-img staff-david-gentry"></div>
              <div class="person-info text-white">
                <h4 class="person-position">Program Manager</h4>
                <h4 class="person-name">David Gentry</h4>
              </div>
            </div>
          </div>
        </section>
      <!------------------------our guiding principles----------------------------->
      <services-ctrl subtitle="our guiding principles" small="true">
        <sub-service class="subservice" name="Impact"
                     content="Performance is at the center of everything we do. Inspired by sport, we know that the smallest edge can make the biggest difference. Our teams will help you achieve that edge.">
        </sub-service>
        <sub-service class="subservice" name="Trusted Partnership"
                     content="Data is the lifeblood of any modern organization, and trust is the currency of our engagements with clients. We strive to maintain the highest levels of data handling, governance and security.">
        </sub-service>
        <sub-service class="subservice" name="Transformational Thinking"
                     content="The real power of analytics is embedding them into the operating model of an organization. Our advanced analytics capabilities equip us with a powerful lens to evaluate and improve.">
        </sub-service>
        <sub-service class="subservice" name="Boundary-breaking"
                     content="We believe that the most valuable insights happen at the boundaries of different disciplines, data, and techniques. Our client work focuses on combining data expertise with human judgement to reimagine and rework how a business can operate in the future.">
        </sub-service>
        <sub-service class="subservice" name="Capacity Building"
                     content="To diagnose challenges and prioritize actions, we focus on capability building. We follow a proven protocol grounded in years of strategy work, to discovering and exploiting data-driven insights. This is formed of: Ideation, Intelligence, Inception, & Intervention. ">
        </sub-service>
      </services-ctrl>
      <!---------------------Full Potential-------------------------------->
      <section>
        <div class="section" style="height:100vh;">
          <div class="fp-bg we_are_partners"></div>
          <div class="section-wrapper">
            <div class="section-details">
              <div class="">
                <p class="product-subtitle text-white">Consulting Services</p>
                <h2 class="product-title-2 text-white">Full Potential<br>Transformation</h2>
                <p class="product-text text-white">
                  We are partners of choice to help businesses accelerate<br />their
                  journey to become digital enterprises
                </p>
                <a class="section-box-link" routerLink="/insights/analytics-to-life">
                  <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                       x="0px" y="0px" width="39.002px" height="26.005px" viewBox="286.5 382.498 39.002 26.005"
                       enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                    <g>
                      <polygon fill="#F0F0F0"
                               points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                      </polygon>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!------------------------Partners----------------------------->
      <section data-aos="fade-up" class="padded-large padding-b-100">
        <div class="container">
          <div class="row padded-small">
            <div class="column col-4">
              <div class="content-wrapper">
                <h6 class="subtitle">
                  <span>partners</span>
                </h6>
              </div>
            </div>
            <div class="column col-12">
              <div class="content-wrapper partners-wrapper">
                <div class="partner">
                  <img id="microsoft" class="partner-image" src="../../assets/image/partner/microsoft.jpg"
                       alt="partner logo">
                </div>
                <div class="partner">
                  <img class="partner-image" src="../../assets/image/partner/aws.jpg" alt="partner logo">
                </div>
                <div class="partner">
                  <img class="partner-image" id="confluent" src="../../assets/image/partner/confluent.jpg"
                       alt="partner logo">
                </div>
                <div class="partner">
                  <img class="partner-image" src="../../assets/image/partner/docker2.jpg" alt="partner logo">
                </div>
                <div class="partner">
                  <img class="partner-image" id="mongodb" src="../../assets/image/partner/mongodb.webp"
                       alt="partner logo">
                </div>
                <div class="partner">
                  <img class="partner-image" id="redhat" src="../../assets/image/partner/redhat.png" alt="partner logo">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <!------------------------People----------------------------->
      <section class="black">
        <div class="content-wrapper services-wrapper">
          <div class="service about">
            <a class="service-link" href="#people"></a>
            <div id="people-panel" class="service-background"></div>
            <div class="about-content">
              <h3 class="about-title text-white">People</h3>
              <br />
              <p class="text-white">Fearless. Relentless. <br />Committed People.</p>
            </div>
          </div>
          <div class="service about">
            <a class="service-link" routerLink="/services"></a>
            <div id="expertise-panel" class="service-background"></div>
            <div class="about-content">
              <h3 class="about-title text-white">Expertise</h3>
              <br />
              <p class="text-white">Fueled by data.<br />Driven by creativity.</p>
            </div>
          </div>
          <div class="service about">
            <a class="service-link" routerLink="/work"></a>
            <div id="impact-panel" class="service-background"></div>
            <div class="about-content">
              <h3 class="about-title text-white">Impact</h3>
              <br />
              <p class="text-white">Challenging the norm.<br />World class delivery.</p>
            </div>
          </div>
          <div class="service about">
            <a class="service-link" routerLink="/culture"></a>
            <div id="culture-panel" class="service-background"></div>
            <div class="about-content">
              <h3 class="about-title text-white">Culture</h3>
              <br />
              <p class="text-white">Built on human values.<br />Staying ahead of disruption.</p>
            </div>
          </div>
          <div class="service about">
            <a class="service-link" routerLink="/insights"></a>
            <div id="research-panel" class="service-background"></div>
            <div class="about-content">
              <h3 class="about-title text-white">Research</h3>
              <br />
              <p class="text-white">Super Powers in action.</p>
            </div>
          </div>
          <div class="service about">
            <a class="service-link" routerLink="/services/artificial-intelligence"></a>
            <div id="giving-panel" class="service-background"></div>
            <div class="about-content">
              <h3 class="about-title text-white">Giving</h3>
              <br />
              <p class="text-white">Social good is in our DNA.</p>
            </div>
          </div>
          <div class="service about">
            <a class="service-link" routerLink="/services/blockchain"></a>
            <div id="partners-panel" class="service-background"></div>
            <div class="about-content">
              <h3 class="about-title text-white">Partners</h3>
              <br />
              <p class="text-white">Fancy friends are great to have.</p>
            </div>
          </div>
          <div class="service about">
            <a class="service-link" routerLink="/services/application-development"></a>
            <div id="clients-panel" class="service-background"></div>
            <div class="about-content">
              <h3 class="about-title text-white">Clients</h3>
              <br />
              <p class="text-white">Influencing for Greatness.</p>
            </div>
          </div>
        </div>
      </section>
	  <!------------------------DROGA----------------------------->
      <section data-aos="fade-up" class="section content-wrapper padded-large text-center">
        <div class="Services__Capabilities">
          <div class="Container padded-small">
            <div class="row">
              <div class="column col-3">
              </div>
              <div class="column col-6">
                <div class="Project_Caption">Capabilities</div><div class="Headline_Large">
                  <p style="color:#646464">Our teams are purpose-built around our client’s unique business challenges, and they bring multidisciplinary creative thinking to every<br>stage of the engagement.</p>
                </div>
              </div>
              <div class="column col-3">
              </div>
            </div>
          </div>
        </div>
      </section>
      <!----------------------------------------------------->
      <div class="home" style="margin: 0 auto;">
        <section class="stats b-lazy b-loaded">
          <div class="container">
            <div class="row">
              <div class="column col-12">
                <div class="content-wrapper">
                  <h6 class="subtitle">
                    <span class="text-black">FEW STATS</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>


          <div class="row small-up-2 medium-up-3 large-up-6 aos-init aos-animate" data-aos="fade-up"
               data-aos-offset="150" data-aos-easing="ease-out-sine" data-aos-duration="600">
            <div class="column column-block">
              <div class="content">
                <div class="tile-front">
                  <div class="number">01</div>
                  <div class="title">One company</div>
                </div>
                <div class="tile-back">
                  <div class="tagline">with <span class="text-green">three divisions</span></div>
                </div>
              </div>
            </div>
            <div class="column column-block">
              <div class="content">
                <div class="tile-front">
                  <div class="number">03</div>
                  <div class="title">Three offices</div>
                </div>
                <div class="tile-back">
                  <div class="tagline"><span class="text-green">Denver</span>, Co Seattle, WA Logan, UT</div>
                </div>
              </div>
            </div>
            <div class="column column-block">
              <div class="content">
                <div class="tile-front">
                  <div class="number">11</div>
                  <div class="title">States</div>
                </div>
                <div class="tile-back">
                  <div class="tagline">and <span class="text-green">3</span> countries</div>
                </div>
              </div>
            </div>
            <div class="column column-block">
              <div class="content">
                <div class="tile-front">
                  <div class="number">213</div>
                  <div class="title">Team members</div>
                </div>
                <div class="tile-back">
                  <div class="tagline">with a <span class="text-green">creative</span> approach</div>
                </div>
              </div>
            </div>
            <div class="column column-block">
              <div class="content">
                <div class="tile-front">
                  <div class="number">80</div>
                  <div class="title">Awesome clients</div>
                </div>
                <div class="tile-back">
                  <div class="tagline">&amp; we <span class="text-green">love</span> them all</div>
                </div>
              </div>
            </div>
            <div class="column column-block">
              <div class="content">
                <div class="tile-front">
                  <div class="number">07</div>
                  <div class="title">Awards</div>
                </div>
                <div class="tile-back">
                  <div class="tagline">we have <span class="text-green">earned</span></div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
      <!-------------------------------------------------New Section-------------------------------------------------------->
      <!---------------------------------------------------------------------------------------------------------------->
      <!-- <section>
        <div class="section" style="height:100vh;">
          <div class="fp-bg product-analytics-life"></div>
          <div class="section-wrapper">
            <div class="section-details">
              <div class="section-box">

                <p class="section-box-subtitle">Our Research</p>
                <h1 class="section-box-title">Bringing Analytics to Life</h1>
                <a class="section-box-link" routerLink="/insights/analytics-to-life">
                  <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    x="0px" y="0px" width="39.002px" height="26.005px" viewBox="286.5 382.498 39.002 26.005"
                    enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                    <g>
                      <polygon fill="#F0F0F0"
                        points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                      </polygon>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!-------------------Six Blocks------------------------------------>
      <!-------------------Six Blocks------------------------------------>
      <!-------------------Six Blocks------------------------------------>
      <!-------------------------------------------------New Section-------------------------------------------------------->
      <!--<section data-aos="fade-up">
          <div class="giving-back-wrapper">
              <div class="giving-back-left">
                  <div class="table">
                      <div class="table-cell text-white">
                          <h1 class="giving-back-title">Giving Back</h1>
                          <p class="giving-back-text">We recognize our responsibility to local communities and the
                              world at large. Citizenship counts. We actively support our communities with volunteer
                              efforts, financial contributions and pro bono consulting work. Our offices and staff
                              around the world support a number of organizations.</p>
                          <a routerLink="/community-service" class="giving-back-arrow">
                              <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1"
                                  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="39.002px"
                                  height="26.005px" viewBox="286.5 382.498 39.002 26.005"
                                  enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                                  <g>
                                      <polygon fill="#FFFFFF"
                                          points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                                      </polygon>
                                  </g>
                              </svg>
                          </a>
                      </div>
                  </div>
              </div>
              <div class="giving-back-right black">
                  <div class="table">
                      <div class="table-cell">
                          <ul class="pseudo text-white giving-back-list">
                              <li>BARK</li>
                              <li>CARE</li>
                              <li>Smile Foundation</li>
                              <li>World Affairs Council</li>
                              <li>FeedMore</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </section>-->




      <section>
        <div class="section" style="height:100vh;">
          <div class="fp-bg basking"></div>
          <div class="section-wrapper">
            <div class="section-details">
              <div class="">
                <p class="product-subtitle text-white">Work</p>
                <h2 class="product-title-3 text-white">Basking in our Triumphant,<br>Award-Winning Glory</h2>
                <p class="product-text text-white">
                  We’re not in digital transformation for the riches and
                  fame.<br />Still, it’s nice to feel appreciated.<br />Here are some of our awards over the years.
                </p>
                <a class="section-box-link" routerLink="">
                  <svg class="arrow-thin-right__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                       x="0px" y="0px" width="39.002px" height="26.005px" viewBox="286.5 382.498 39.002 26.005"
                       enable-background="new 286.5 382.498 39.002 26.005" title="Next">
                    <g>
                      <polygon fill="#F0F0F0"
                               points="325.041,396.329 325.502,395.861 325.046,395.398 325.432,395.008 325.041,394.612 325.041,394.533 324.963,394.533 313.102,382.498 311.805,383.814 322.367,394.533 286.5,394.533 286.5,396.395 322.354,396.395 311.732,407.172 313.044,408.503 324.977,396.395 325.041,396.395 	">
                      </polygon>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <subscribe></subscribe>
      <hire-us-footer></hire-us-footer>
    </div>
  </div>
</default-layout>
