import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms'; 
import { HttpEventType, HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'job-app',
  templateUrl: './job-app.component.html',
  styleUrls: ['./job-app.component.sass']
})
export class JobAppComponent implements OnInit {
  uploadedFile: File;
  result: string;
  loading: boolean = false;
  jobAppForm = this.fb.group({
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    emailAddress: ["", [Validators.required, Validators.email]],
    phone: ["", Validators.pattern("^(?=.*[0-9])[- +()0-9]+$")],
    file: ["", Validators.required]
  });

  constructor(private fb: FormBuilder, private http: HttpClient) { }

  ngOnInit(): void {}

  processFile(event: any): void {
    this.uploadedFile = event.target.files[0];
  }

  onSubmit(event: any): void {
    event.preventDefault();
    this.result = "";
    if (this.jobAppForm.valid) {
      this.submitJobApp();
    }
  }

  submitJobApp(): void {
    this.loading = true;

    const formData = new FormData();
    formData.append("Position", document.getElementById("position").innerText);
    formData.append("Location", document.getElementById("location").innerText);

    formData.append("FirstName", this.jobAppForm.get('firstName').value);
    formData.append("LastName", this.jobAppForm.get('lastName').value);
    formData.append("EmailAddress", this.jobAppForm.get('emailAddress').value);
    formData.append("Phone", this.jobAppForm.get('phone').value);
    formData.append("File", this.uploadedFile, this.uploadedFile.name);

    let headers = new HttpHeaders();
    headers.append("Content-Type", 'application/json');

    this.http.post('/api/Email/JobApp', formData, { observe: 'events', headers: headers  })
      .subscribe(event => {
        if (event.type === HttpEventType.Response) {
          this.loading = false;
          if (event.ok) {
            this.resetForm();
            this.result = "Thank you for your application. We will reach out to you with regard to this position as soon as possible.";
          }
          else {
            this.result = "Sorry there was an error processing your application, please try again.";
          }
        }
      });
  }

  removeFile(): void {
    this.uploadedFile = null;
    let fileEl = document.getElementById("file") as HTMLInputElement
    fileEl.value = "";
  }

  resetForm(): void {
    this.jobAppForm.reset();
    this.uploadedFile = null;
  }

  // validation helpers
  get firstName() { return this.jobAppForm.get('firstName'); }
  get lastName() { return this.jobAppForm.get('lastName'); }
  get emailAddress() { return this.jobAppForm.get('emailAddress'); }
  get phone() { return this.jobAppForm.get('phone'); }
  get file() { return this.jobAppForm.get('file'); }
  
}
