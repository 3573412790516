import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.sass']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  showMobileMenu: boolean = false;
  scrolled: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.hideMenu();
  }

  ngOnDestroy() {
   
  }

  logout(event: any) {
    event.preventDefault();
    this.authService.logout();
    this.router.navigate(['login']);
  }

  showMenu() {
    this.showMobileMenu = true;
    const el1 = document.body;
    const el2 = document.querySelector(".modal-menu");

    el1.classList.add("hide-overflow");
    el2.classList.add("show");
  }

  hideMenu() {
    const el1 = document.body;
    const el2 = document.querySelector(".modal-menu");
    this.showMobileMenu = false;

    el1.classList.remove("hide-overflow");
    el2.classList.remove("show");
  }

}
