<default-layout>
    <div class="__page-root">
        <root-hero name="Citizenship."></root-hero>
        <div class="sections">
            <section data-aos="fade-up" class="full-page">
                <div class="cover-image donation-background"></div>
                <div class="container">
                    <div class="row">
                        <div class="column col-12 center-content">
                            <div class="content-wrapper donation-wrapper">
                                <div class="donation-box">
                                    <div class="table">
                                        <div class="table-cell">
                                            <h1 class="donation-title">Data Concepts Community Service.</h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="donation-aside text-white">
                                    <h2>Donation Matching</h2>
                                    <p style="margin-bottom: 60px">Data Concepts matches employee donations dollar for
                                        dollar—up to $2,500 per year. And we’ve developed a Volunteer Rewards Program to
                                        acknowledge the personal time that our employees dedicate to charity.</p>
                                    <h2>Environmental Sustainability</h2>
                                    <p>Data Concepts is committed to preserving and protecting the planet and its resources.
                                        We’re focused on reducing our carbon footprint and the impact we have on the
                                        environment, and inspiring our employees to support these efforts in all of our
                                        offices.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
            <section data-aos="fade-up" class="padded-top light-grey">
                <div class="community-container">
                    <div class="row">
                        <div class="column col-12">
                            <div class="content-wrapper community-wrapper">
                                <div class="community right">
                                    <img src="../../assets/image/community/bark-2.jpg?size=1000" alt="community service image"
                                        class="community-image">
                                    <div class="community-copy-wrapper">
                                        <h6 class="community-title">Bark</h6>
                                        <p class="community-copy">
                                            Data Concepts is proud to sponsor BARK, a volunteer rescue for dogs, by
                                            providing hosting for their website. If an employee adopts their next family
                                            member from Bark, we proudly cover the adoption fees and initial medical costs.
                                            To learn more about the work Bark is doing, visit:
                                            <a href="http://barkva.org/" target="_blank"
                                                rel="noopener noreferrer">http://barkva.org/</a>.
                                        </p>
                                    </div>
                                </div>
                                <div class="community">
                                    <img src="../../assets/image/community/cat-1.jpg?size=1000" alt="community service image"
                                        class="community-image">
                                    <div class="community-copy-wrapper">
                                        <h6 class="community-title">Care</h6>
                                        <p class="community-copy">
                                            Capital Area Rescue Effort comes to Data Concepts when they need help in
                                            covering the cost of surgery or other medical care for neglected or abused
                                            animals that they rescue. If a Data Concepts employee adopts their next family
                                            member form CARE, we proudly cover the adoption fees and initial medical costs.
                                            To learn more and see adoptable pets, visit
                                            <a href="https://www.carerva.com/" target="_blank"
                                                rel="noopener noreferrer">https://www.carerva.com/</a>.
                                        </p>
                                    </div>
                                </div>
                                <div class="community right">
                                    <img src="../../assets/image/community/smile-2.jpg?size=1000" alt="community service image"
                                        class="community-image">
                                    <div class="community-copy-wrapper">
                                        <h6 class="community-title">Smile Foundation</h6>
                                        <p class="community-copy">
                                            The globally-recognized Smile Foundation provides education, nutrition and
                                            medical care to people living in poverty who would otherwise face great
                                            challenges to obtaining them. Data Concepts is pleased to make renewing annual
                                            contributions to support their work. To learn more or to get involved, please
                                            visit
                                            <a href="https://www.smilefoundationindia.org/" target="_blank"
                                                rel="noopener noreferrer">https://www.smilefoundationindia.org/</a>.
                                        </p>
                                    </div>
                                </div>
                                <div class="community">
                                    <img src="../../assets/image/community/worldquest-2.jpg?size=1000"
                                        alt="community service image" class="community-image">
                                    <div class="community-copy-wrapper">
                                        <h6 class="community-title">WAC - World Quest</h6>
                                        <p class="community-copy">
                                            The World Affairs Council is dedicated to educating the community on foreign
                                            affairs and geopolitical shifts. Data Concepts is a Lead Sponsor of Academic
                                            WorldQuest - a high school competition focused on current international politics
                                            and history.
                                            <a href="http://www.richmondworldaffairs.org/" target="_blank"
                                                rel="noopener noreferrer">http://www.richmondworldaffairs.org/</a>.
                                        </p>
                                    </div>
                                </div>
                                <div class="community right">
                                    <img src="../../assets/image/community/feedmore-3.jpg?size=1000"
                                        alt="community service image" class="community-image">
                                    <div class="community-copy-wrapper">
                                        <h6 class="community-title">Feed More</h6>
                                        <p class="community-copy">
                                            Feed More serves hungry Central Virginians by providing nutritious meals via a
                                            variety of mechanisms. More than a food bank, Feed More makes food accessible to
                                            people of all ages and ability from brick and mortar location and from their
                                            mobile food pantry. Data Concepts partners with Feed More throughout the year.
                                            <a href="http://www.feedmore.org" target="_blank"
                                                rel="noopener noreferrer">www.feedmore.org</a>.
                                        </p>
                                    </div>
                                </div>
                                <div class="community">
                                    <img src="../../assets/image/community/model-un-1.jpg?size=1000"
                                        alt="community service image" class="community-image">
                                    <div class="community-copy-wrapper">
                                        <h6 class="community-title">Model UN</h6>
                                        <p class="community-copy">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                            Vitae, deserunt, officiis omnis a, nostrum necessitatibus aspernatur quos rerum
                                            voluptas soluta et nisi quisquam corporis pariatur aliquid at tenetur illo!
                                            Inventore?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</default-layout>
