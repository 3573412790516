import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modal-menu',
  templateUrl: './modal-menu.component.html',
  styleUrls: ['./modal-menu.component.sass']
})
export class ModalMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
