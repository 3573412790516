<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Big Data" name="Extracting Actionable Insights from Data"
                summary="Data mining is the extraction of actionable insights from data.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
            <insight-pamphlet>
                <insight-sub-section class="subsection subsection-padding" name="Data Mining Uses"
                    content="Leading organizations use data mining techniques to target profitable customers and likely prospects, reduce consumer fraud, identify loyal customers, improve customer service, and increase customer retention. Data mining often involves predictive analytics and the use of sophisticated tools to present large volumes of data within statistical and visual models such as decision trees and three-dimensional graphics.">
                </insight-sub-section>
                <insight-sub-section class="subsection" name="Assess Model Performance and Business Impacts"
                    content="Data mining is best executed as part of an integrated business intelligence (BI) effort. An organization’s BI strategy will identify questions appropriate for data mining, data marts, data warehouses, or analytical applications. Data Concepts experts can conduct a data mining Proof-of-Value early in the BI life cycle to help identify which data (and combinations of data) will provide new insights and have predictive value.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="A Proof-of-Value analysis helps organizations construct data warehouses or data marts that will support future data mining efforts. Such initiatives can reduce future costs and project life-cycles by as much as 75 percent by dramatically decreasing the time necessary for ongoing data collection and preparation.">
                </insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
