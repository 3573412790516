<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Data Security" name="Data Security in the BI & IoT World"
                summary="Facebook… user data harvested by Cambridge Analytica Equifax… customer data lost to hackers IoT data breach … hackers gained access to a casino’s data through an internet-connected aquarium thermometer.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
            <insight-pamphlet>
                <insight-sub-section class="subsection subsection-padding" name="The Danger of Unsecured Data"
                    content="Facebook… user data harvested by Cambridge Analytica Equifax… customer data lost to hackers through an IoT data breach … Hackers gained access to a casino’s data through an internet-connected aquarium thermometer. These examples are just some of the most recent and noteworthy data breaches in the news. But data security issues can occur on a far smaller scale and these security issues can ultimately lead to big data losses for organizations if not properly addressed.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    name="At Data Concepts we partner with organizations to develop data security solutions for both BI and IoT needs by designing, developing and implementing:"
                    content="- Robust data management and governance practices.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="- BI and IoT architecture solutions that meet the specific needs of your organization.">
                </insight-sub-section>
                <insight-sub-section class="subsection" 
                    content="- Solutions to address accessibility, usability, storage and data in motion needs.">
                </insight-sub-section>
                <insight-sub-section class="subsection" content="- Robust and secure IoT API solutions."></insight-sub-section>
                <insight-sub-section class="subsection" content="- BI and data analytical solutions with your data security needs in mind.">
                </insight-sub-section>
                <insight-sub-section class="subsection" content="- IoT data analytical strategies for securing data."></insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Today organizations are utilizing the power of Business Intelligence (BI) to meet both internal and customer’s needs more than ever. At the same time, organizations are rapidly developing and adopting the Internet of Things (IoT) strategies in order to be competitive in the marketplace. Both BI and IoT are seeing tremendous growth in almost every industry. According to Forbes, Cloud BI adoption levels doubled between 2016 and 2018. Forbes also estimates that global IoT market growth will go from $157B in 2016 to an estimated $457B by 2020.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="These BI and IoT solutions present data security challenges for every organization. Going above and beyond the cloud providers solutions for network, database, and firewall security, which are of critical importance, organizations must also address security concerns around accessibility, usability, storage and movement of data. Those data security concerns must be a top priority.">
                </insight-sub-section>
                <insight-sub-section class="subsection"
                    content="Furthermore, IoT solutions offer new sources of data which organizations must contend with. While the uses of this data can be invaluable, organizations must also address issues around security, privacy and accessibility of the data collected. Organizations must demonstrate that they can operate responsibly when using customer, employee, and company IoT-sourced data.">
                </insight-sub-section>
                <insight-sub-section class="subsection" content="Come talk to us about data security solutions for your BI and IoT needs."></insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
