<light-layout>
    <div class="__page-root">
        <div class="sections">
            <insight-header category="Data Governance" name="Data Governance: Processes & Metrics"
                summary="Here are some best practices to help avoid some of the frequently experienced challenges.">
                <div class="half-image insight-image tinted"></div>
            </insight-header>
            <insight-pamphlet>
                <insight-sub-section class="subsection" name="What is Data Governance?"
                    content="Data governance refers to the set of processes to ensure data meets precise standards and business rules as it is entered into a system. Data governance enables businesses to exert control over the management of data assets. This encompasses the people, process, and technology that is required to make data fit for its intended purpose. Data governance is important for different types of organizations and industries, but especially for those that have regulatory compliance, such as finance and insurance. To achieve compliance, organizations are required to have formal data management processes in place to govern their data throughout its lifecycle.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding"
                    content="While data governance is a key focus for many organizations, not all implementations return the anticipated results. Here are some best practices to help avoid some of the frequently experienced challenges.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="1) Thinking big picture, but start small"
                    content="Data governance is routed in people, process and technology. While it is important to keep all three factors in mind when planning and executing the strategy, remember to start at the beginning and build up to the full picture. Start with the people, follow with the process, and end with the technology—each component building on the former. Without the right people the process can become obsolete, and as can the technology without the first two pillars. Start by identifying and hiring the right people, then define a process, and finish by sourcing the technology to get the job done.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="2) Build a business case"
                    content="Executive buy-in is key when implementing a data governance practice, but buy-in alone is not enough to fully support the effort and ensure success. Identify the benefits and opportunities that data quality will bring to the organization. Show the improvements that will be made possible to the business, such as increased revenue, better customer experience, and efficiency. Most can agree that poor data quality and management is a problem, but knowing there is a problem is not enough. By identifying the shortfalls and the increased opportunity, you will have the solid foundation to drive the change.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="3) Metrics, metrics, metrics"
                    content="As with any change, it is critical to be able to measure progress and display success. Following executive sponsorship and the backing of a business case, each step of the way needs to be supported with data. Be sure to identify measurements from the start and track on a consistent basis. Not only will the metrics show the overall progress and success of the changes, but they will serve as markers and checkpoints along the way to ensure that the process is effective in practice (and not just in theory). Plans can look flawless on paper but once in the field, it is important to keep a close eye and allow for adjustments and improvements.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="4) Communicate frequently"
                    content="No matter what stage of the process your organization is at with your data governance program, it is essential to communicate early and often. The role of consistent and effective communication is critical in showing the impact of the program. Leverage your sponsor and champion to be the face and voice of the communication.">
                </insight-sub-section>
                <insight-sub-section class="subsection subsection-padding" name="5) Make it a practice—not a project "
                    content="Often when changes are made, a team is assembled to execute the project. With the implementation of a data governance program/strategy, it is important to present it as a practice, not a project. The difference being that a project has a start and end date. A practice is a fundamental change that is woven into the organization. So, while may seem natural to come up with a catchy project name and flashy road map, remember that you are implementing a practice not just starting and finishing a project.">
                </insight-sub-section>
            </insight-pamphlet>
        </div>
    </div>
</light-layout>
